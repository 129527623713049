<template>
	<div class="container-fluid add-form-list">
		<div class="row">
			<div class="col-sm-12">
				<div class="card">
					<div class="card-header d-flex justify-content-between">
						<div class="header-title">
							<h4 class="card-title">
								<div class="row align-items-center">
									<div class="col-5">商品入库(입고진행)</div>
									<div class="col-3">
										<b-form-input type="text" class="form-control" list="my-list-id" @keyup.enter="importtextchange($event)" placeholder="업체ID" v-model="업체명"></b-form-input>
										<datalist id="my-list-id">
											<option v-for="e,i in companyids" :key="i">{{ e.companyid }}</option>
										</datalist>
									</div>
									<!-- <div class="col-4"><input type="text" class="form-control" @keyup.enter="importtextchange($event)" placeholder="업체ID" v-model="업체명"></div> -->
									<div class="col-2"><button type="button" :class="importtext=='Connect' ? 'btn btn-primary mr-2' : 'btn btn-success mr-2'" @click="importcompanyinfo">{{importtext}}</button></div>
									<div v-if="ceoname.length > 0 && 업체명.length > 0" class="col-2"><small style="font-size:80%">{{ceoname}}<br>대표님</small></div>
								</div>
							</h4>
							<div class="col-md-12" v-if="showspinner">
								<div class="text-center">
									<img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">

						<tab-nav :tabs="true" id="myTab-1">
							<tab-nav-items :active="true" id="profile1-tab" ariaControls="profile1" role="tab" :ariaSelected="true" title="입고분류" />
							<tab-nav-items :active="false" id="importerror-tab" ariaControls="importerror" role="tab" :ariaSelected="false" title="입고오류" />
							<tab-nav-items :active="false" id="profile-tab" ariaControls="profile" role="tab" :ariaSelected="false" title="구매입고" />
							<tab-nav-items :active="false" id="home-tab" ariaControls="home" role="tab" :ariaSelected="false" title="재고등록/등록배치" />
							<tab-nav-items :active="false" id="change-tab" ariaControls="change" role="tab" :ariaSelected="false" title="재고배치/위치변경" />
							<tab-nav-items :active="false" id="contact-tab" ariaControls="contact" role="tab" :ariaSelected="false" title="출고처리" />
						</tab-nav>

						<tab-content id="myTabContent">
							<tab-content-item :active="true" id="profile1" aria-labelled-by="profile1-tab">
								<p>운송장번호 검색 및 박스 배송완료처리</p>
								<form @submit.prevent="submit">
									<div class="row">
										<div class="col-md-2">
											<div class="form-group">
												<label>운송장번호</label>
												<input type="text" class="form-control" placeholder="운송장번호" v-model="운송장번호1" @keyup.enter="searchimportboxwith($event)">
											</div>
										</div>
									</div>

									<div class="col-lg-12 mt-3" v-if="showimportboxlist">
										<div class="table-responsive">
											<table id="datatable" class="table data-table table-striped dataTable" >
												<thead>
													<tr class="ligth ligth-data">
														<th>
															<div class="checkbox d-inline-block">
																<input type="checkbox" class="checkbox-input" id="checkboxpall" @change="checkallimportbox" v-model="checkimportbox">
																<label for="checkboxpall" class="mb-0"></label>
															</div>
														</th>
														<th>입고상태</th>
														<th>운송장번호</th>
														<th>입고일시</th>
														<th>업체명</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(importbox,index) in importboxlists" :key="index" >
														<td>
															<div class="checkbox d-inline-block">
																<input type="checkbox" class="checkbox-input" id="checkboxp" v-model="importbox.checked">
																<label for="checkboxp" class="mb-0"></label>
															</div>
														</td>
														<td>
															<div :class="importboxclass(importbox)">
																{{importbox.입고상태}}
															</div>
														</td>
														<td>{{importbox.운송장번호}}</td>
														<td>{{importbox.입고일시}}</td>
														<td><input type="text" :class="importbox.업체명 == '직접입력' ? 'bg-danger text-dark form-control' : 'form-control'" v-model="importbox.업체명"></td>

													</tr>
												</tbody>
											</table>
										</div>
										<button class="btn btn-secondary" type="button" @click="doimportbox">배송완료처리</button>
									</div>

								</form>
							</tab-content-item>

							<tab-content-item :active="false" id="profile" aria-labelled-by="profile-tab">
								<p>구매입고할 운송장번호나 주문번호를 조회하세요.</p>
								<form @submit.prevent="submit">
									<div class="row">
										<div class="col-md-3" style="display: flex;">
											<div class="form-group mr-2">
												<input type="text" class="form-control" placeholder="운송장번호" v-model="운송장번호" @keyup.enter="searchpurchasewith($event)">
											</div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="주문번호" v-model="주문번호" @keyup.enter="searchpurchasewith($event)">
                                            </div>
										</div>
										<div class="col-md-1">
											<button type="button" class="btn btn-primary mr-2" @click="searchpurchaseref">검색</button>
										</div>
										<div class="col-md-2">
											<div class="form-group">
												<input type="text" class="form-control" placeholder="바코드" v-model="바코드출력" @keyup.enter="printbarcodewith($event)">
											</div>
										</div>
										<div class="col-md-1">
											<div class="form-group">
												<input type="text" class="form-control" placeholder="SKU ID" v-model="SKU출력" @keyup.enter="printSKUwith($event)">
											</div>
										</div>
										<div class="col-md-1">
											<div class="form-group">
												<input type="number" class="form-control" placeholder="수량" v-model="출력수량">
											</div>
										</div>
										<div class="col-md-1">
											<div class="form-group">
												<input type="number" class="form-control" placeholder="간격" v-model="바코드간격">
											</div>
										</div>
										<div class="col-md-3">
                                            <div style="display: flex" v-show="!isBarcodeModal">
                                                <button type="button" class="btn btn-success mr-2" @click="printbarcoderef">바코드출력</button>
                                                <button type="button" class="btn btn-success mr-2" @click="printbarcode(바코드출력, true)">수정</button>
                                                <button type="button" class="btn btn-success mr-2" @click="printbarcode(바코드출력, 'manual')">수동출력</button>
                                            </div>
										</div>
                                        <div style="position: absolute; right: 0px; bottom: 5px">
                                            <div>
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="enabledetail">
                                                    <label class="custom-control-label" for="customSwitch1">한글표시사항</label>
                                                </div>
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch4" v-model="printskuenable2">
                                                    <label class="custom-control-label" for="customSwitch4">SKU번호 표시</label>
                                                </div>
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="printkcenable2">
                                                    <label class="custom-control-label" for="customSwitch3">KC인증마크 표시</label>
                                                </div>
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitchfood2" v-model="printfoodenable2">
                                                    <label class="custom-control-label" for="customSwitchfood2">식품용</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="isBarcodeModal" style="position: absolute; right: 0px; top: 300px; width: 500px; z-index: 100">
                                            <b-card>
                                                <textarea style="height:300px;" class="form-control" placeholder="수정출력할 내용을 작성해 주세요" v-model="barcodeRevisedContent"></textarea>
                                                <button type="button" class="btn btn-success mt-2 mr-2" @click="editprint2('printMe08')">출력</button>
                                                <button type="button" class="btn btn-success mt-2" @click="isBarcodeModal = !isBarcodeModal">취소</button>
                                            </b-card>
                                        </div>
                                        <div v-if="isManualModal" style="position: absolute; right: 0px; top: 300px; width: 500px; z-index: 100">
                                            <b-card>
                                                <textarea style="height:300px;" class="form-control" placeholder="수정출력할 내용을 작성해 주세요" v-model="barcodeManualContent"></textarea>
                                                <button type="button" class="btn btn-success mt-2 mr-2" @click="editprint3('printMe07')">출력</button>
                                                <button type="button" class="btn btn-success mt-2" @click="isManualModal = !isManualModal">취소</button>
                                            </b-card>
                                        </div>
									</div>
                                    <div class="mb-4 ml-1 d-none" id="printMe07">
                                        <div>
                                            <div  class="row d-flex justify-content-center ml-1" :style="barcodegap(parseInt(출력수량))">
                                                <div class="col-12 text-center d-flex justify-content-center mb-0" style="margin-bottom:0px;margin-left:10px;margin-right:10px;color:black; display: flex; justify-content: space-between">
                                                    <p class="mb-0" style="font-size:90%;color:black;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                                    <p :style="printsku">{{editliststart[1]}}</p>
                                                </div>
                                                <div class="col-12 text-center d-flex justify-content-center mt-0 mb-0" style="margin-top:0px;margin-bottom:0px; width: 100%">
                                                    <barcode :value="barcode" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
                                                </div>
                                                <div class="col-12 mt-0 mb-0" style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:5px;">
                                                    <span :style="disablekr">{{editliststart[0]}}</span><br>
                                                    <div v-if="printfoodenable2" :style="'width:100%;margin-top:0px;margin-bottom:0px;' + 'font-size:'+폰트사이즈+'%'">
                                                        <span :style="enablekr">{{ editliststart[2] }}</span> <br />
                                                        <div style="font-size:90%;font-weight:bold; display: flex; justify-content: center;">
                                                            식품위생법에 의한 한글표시사항
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <span :style="enablekr" v-for="(edit, ix) in editliststart" :key="ix">
                                                      <template v-if="ix > 1 && !printfoodenable2">
                                                        {{ edit }}
                                                      </template>
                                                        <template v-else-if="ix === 3">
                                                            {{ edit }}
                                                        </template>
                                                        <br v-if="ix !== editlistend.length - 1 && !printfoodenable2">
                                                    </span>
                                                    <div style="display: flex;justify-content: space-between;">
                                                        <div>
                                                            <span :style="enablekr" v-for="edit2,ix2 in editlistend" :key="ix2">{{edit2}}</span><br>
                                                        </div>
                                                        <div style="height:60px;margin-right:10px;margin-top:10px;margin-left:10px;">
                                                            <!-- <div :style="printmark"> -->
                                                            <img v-if="printfoodenable2" style="height:100%" :src="require('../../../assets/images/small/ForFood.png')">
                                                            <img v-if="printkcenable2" style="height:100%" :src="require('../../../assets/images/small/KcMark.jpg')">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-4 ml-1 d-none" id="printMe8">
                                        <div v-for="index in parseInt(출력수량)" :key="index">
                                            <div  class="row d-flex justify-content-center ml-1" :style="barcodegap(index,parseInt(출력수량))">
                                                <div class="col-12 text-center d-flex justify-content-center mb-0" style="margin-bottom:0px;margin-left:10px;margin-right:10px;color:black; display: flex; justify-content: space-between">
                                                    <p class="mb-0" style="font-size:90%;color:black;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                                    <p :style="printsku">SKU : {{출력SKUID}}</p>
                                                </div>
                                                <div class="col-12 text-center d-flex justify-content-center mt-0 mb-0" style="margin-top:0px;margin-bottom:0px; width: 100%">
                                                    <barcode :value="출력바코드" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
                                                </div>
                                                <div class="col-12 mt-0 mb-0" style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:5px;">
                                                    <span :style="disablekr">{{출력상품명}}</span><br>
                                                    <span :style="enablekr">1. 상품명 : {{출력상품명}}</span><br>
                                                    <span :style="enablekr">2. 수입원/판매원 : {{한글표시사항['수입원/판매원']}}</span><br>
                                                    <div style="display: flex; justify-content: space-between;">
                                                        <div>
                                                            <span :style="enablekr">3. 제조원 : {{한글표시사항.제조원}}</span><br>
                                                            <span :style="enablekr">4. 제조국 : {{한글표시사항.제조국}}</span><br>
                                                            <span :style="enablekr">5. 내용량 : {{한글표시사항.내용량}}</span>
                                                        </div>
                                                        <div style="height:58px;margin-right:10px;margin-left:10px;">
                                                            <img v-if="printfoodenable2" style="height:100%" :src="require('../../../assets/images/small/ForFood.png')">
                                                        </div>
                                                    </div>
                                                    <span :style="enablekr">6. 원료명및성분명(재질) : {{한글표시사항['원료명및성분명(재질)']}}</span><br>
                                                    <div style="display: flex;justify-content: space-between;">
                                                        <div>
                                                            <span :style="enablekr">7. 상품유형 : {{한글표시사항.상품유형}}</span><br>
                                                            <span :style="enablekr">8. 사용시주의사항 : {{한글표시사항.사용시주의사항}}</span><br>
                                                            <span :style="enablekr">9. 사용기준 : {{한글표시사항.사용기준}}</span><br>
                                                        </div>
                                                        <div style="height:60px;margin-right:10px;margin-top:10px;margin-left:10px;">
                                                            <img v-if="printkcenable2" style="height:100%" :src="require('../../../assets/images/small/KcMark.jpg')">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-4 ml-1 d-none" id="printMe09">
                                        <div v-for="index in parseInt(출력수량)" :key="index">
                                            <div class="row d-flex justify-content-center ml-1" :style="barcodegap(index,parseInt(출력수량))">
                                                <div class="col-12 text-center d-flex justify-content-center mb-0" style="margin-bottom:0px;margin-left:10px;margin-right:10px;color:black; display: flex; justify-content: space-between">
                                                    <p class="mb-0" style="font-size:90%;color:black;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                                    <p :style="printsku">SKU : {{출력SKUID}}</p>
                                                </div>
                                                <div class="col-12 text-center d-flex justify-content-center mt-0 mb-0" style="margin-top:0px;margin-bottom:0px; width: 100%">
                                                    <barcode :value="출력바코드" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
                                                </div>
                                                <div class="col-12 mt-0 mb-0" style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:5px;">
                                                    <span :style="disablekr">{{출력상품명}}</span><br>
                                                    <span :style="enablekr">1. 상품명 : {{출력상품명}}</span><br>
                                                    <span :style="enablekr">2. 수입원/판매원 : {{한글표시사항['수입원/판매원']}}</span><br>
                                                    <div style="display: flex;justify-content: space-between;">
                                                        <div>
                                                            <span :style="enablekr">3. 제조원 : {{한글표시사항.제조원}}</span><br>
                                                            <span :style="enablekr">4. 제조국 : {{한글표시사항.제조국}}</span><br>
                                                            <span :style="enablekr">5. 내용량 : {{한글표시사항.내용량}}</span>
                                                        </div>
                                                        <div style="height:58px;margin-right:10px;margin-left:10px;">
                                                            <img v-if="printfoodenable2" style="height:100%" :src="require('../../../assets/images/small/ForFood.png')">
                                                        </div>
                                                    </div>
                                                    <span :style="enablekr">6. 원료명및성분명(재질) : {{한글표시사항['원료명및성분명(재질)']}}</span><br>
                                                    <div style="display: flex;justify-content: space-between;">
                                                        <div>
                                                            <span :style="enablekr">7. 상품유형 : {{한글표시사항.상품유형}}</span><br>
                                                            <span :style="enablekr">8. 사용시주의사항 : {{한글표시사항.사용시주의사항}}</span><br>
                                                            <span :style="enablekr">9. 사용기준 : {{한글표시사항.사용기준}}</span><br>
                                                        </div>
                                                        <div style="height:60px;margin-right:10px;margin-top:10px;margin-left:10px;">
                                                            <img v-if="printkcenable2" style="height:100%" :src="require('../../../assets/images/small/KcMark.jpg')">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-4 ml-1 d-none" id="printMe08">
                                        <div v-for="index in parseInt(출력수량)" :key="index">
                                            <div class="row d-flex justify-content-center ml-1" :style="barcodegap(index,parseInt(출력수량))">
                                                <div class="col-12 text-center d-flex justify-content-center mb-0" style="margin-bottom:0px;margin-left:10px;margin-right:10px;color:black; display: flex; justify-content: space-between">
                                                    <p class="mb-0" style="font-size:90%;color:black;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                                    <p :style="printsku">SKU : {{출력SKUID}}</p>
                                                </div>
                                                <div style="margin-bottom:0px; width: 100%">
                                                    <barcode :value="출력바코드" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
                                                </div>
                                                <div :style="'margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:3px;' + 'font-size:'+폰트사이즈+'%'">

                                                    <span :style="enablekr" v-for="edit,ix in editliststart" :key="ix">{{edit}}</span><br>
                                                    <div style="display: flex;justify-content: space-between;">
                                                        <div>
                                                            <span :style="enablekr" v-for="edit2,ix2 in editlistend" :key="ix2">{{edit2}}</span><br>
                                                        </div>
                                                        <div style="height:60px;margin-right:10px;margin-top:10px;margin-left:10px;">
                                                            <!-- <div :style="printmark"> -->
                                                            <img v-if="printfoodenable2" style="height:100%" :src="require('../../../assets/images/small/ForFood.png')">
                                                            <img v-if="printkcenable2" style="height:100%" :src="require('../../../assets/images/small/KcMark.jpg')">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
									<div class="col-lg-12 mt-3" v-if="showpurchaselist">
										<button class="btn btn-outline-success" type="button" @click="inputproduct">입고처리</button>
										<button class="btn btn-outline-primary ml-3" type="button" @click="print('printMe7')">전체입고수량 바코드출력</button>
										<button class="btn btn-outline-dark ml-3" type="button" @click="importqtytozero">입고수량초기화</button>
										<b-button class="ml-3" variant="outline-secondary" v-b-toggle.sidebar-backdrop>출고배치</b-button>

										<b-sidebar
											ref="sidebar1"
											id="sidebar-backdrop"
											title="출고배치"
											right
											shadow
											bg-variant="white"
											width="550px"
											sidebar-class="border-left border-right border-top border-bottom border-primary"
										>
											<div class="px-3 py-2">
												<div>
													<div class="form-group">
														박스라벨출력
														<div class="d-flex justify-content-between align-items-center">
															<input type="number" min="1" class="form-control" placeholder="시작번호" v-model="시작번호" @change="printboxnumber">
															<p>&nbsp;~&nbsp;</p>
															<input type="number" min="1" class="form-control" placeholder="끝번호" v-model="끝번호" @change="printboxnumber">
															<input type="number" min="0" class="form-control" placeholder="라벨간격" v-model="라벨간격">
															<span class="ml-2 text-center align-middle" @click="prints('printMe35')"><i class="ri-printer-line" style="font-size:200%"></i></span>

															<div class="d-none" id="printMe35">
																<div v-for="boxnum in printboxnums" :key="boxnum">
																	<div :style="'text-align:center;margin-bottom:'+ 라벨간격 + 'px;'">
																		<div class="row mb-0" style="text-align:center;font-size:200%">{{업체명}}</div>
																		<div class="row mb-0" style="text-align:center;font-size:200%">출고박스</div>
																		<div class="row mb-0" style="text-align:center;font-size:400%">{{boxnum}}</div>
																		<div class="row mb-0" style="text-align:center; width: 100%">
																			<barcode :value="업체명.slice(0,2)+'cn'+boxnum" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																		</div>
																	</div>
																</div>
															</div>

														</div>

													</div>
												</div>
												<div class="mt-5">
													<div class="d-flex justify-content-between align-items-bottom mb-1">
														<label>출고배치 박스번호</label>
														<!-- <button class="btn btn-dark" type="button" @click="removeboxnum">박스삭제</button> -->
													</div>
													<div class="input-group mb-4">
														<input type="number" class="form-control" :min="0" placeholder="박스번호" aria-label="" @change="exportboxchangeref" v-model="exportboxnum" style="font-size:200%;text-align:center;">
														<div class="input-group-append">
															<b-dropdown class="my-class" variant="primary" type="button" text="리스트">
																<b-dropdown-item @click="exportboxnumberchange(b)" v-for="b,j in exportboxnumbers" :key="j">{{b}}</b-dropdown-item>
															</b-dropdown>
														</div>
														<span class="ml-2 text-center align-middle" @click="print('printMe10')"><i class="ri-printer-line" style="font-size:200%"></i></span>
														<div class="d-none text-center" style="text-align:center;" id="printMe10">
															<div class="row mb-0" style="text-align:center;font-size:200%">{{업체명}}</div>
															<div class="row mb-0" style="text-align:center;font-size:200%">출고박스</div>
															<div class="row mb-0" style="text-align:center;font-size:400%">{{exportboxnum}}</div>
															<div class="row mb-0" style="text-align:center; width: 100%">
																<barcode :value="업체명.slice(0,2)+'cn'+exportboxnum" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
															</div>
														</div>
													</div>
													<button class="btn btn-dark" type="button" @click="saveexport()">저장</button>
													<div class="float-right">
														<div class="float-right"><h3>Total : {{totalqty1}} 개</h3></div>
													</div>
													<div class="table-responsive" style="font-size:80%;text-align:center;width:100%">
														<table id="datatable" class="table data-table table-striped dataTable" >
															<thead>
																<tr class="ligth ligth-data">
																	<th style="vertical-align: middle;">상품정보</th>
																	<th style="vertical-align: middle;">수량</th>
																	<th>삭제</th>
																</tr>
															</thead>
															<tbody>
																<tr v-for="exportboxlist,i2 in exportboxlists" :key="i2">
																	<td style="min-width:200px;text-align:left;">
																		<div class="d-flex align-items-center">
																			<img :src="exportboxlist.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
																			<div>
																				{{exportboxlist.상품명}}
																				<p class="mb-0">{{exportboxlist.바코드}}</p>
																				<p class="mb-0">SKU ID : {{exportboxlist.SKUID}}</p>
																			</div>
																		</div>
																	</td>
																	<td style="min-width:50px;overflow:hidden;text-overflow: ellipsis;"><b-form-input :min="0" type="number" v-model.number="exportboxlist.수량" @mousedown="savecurrentlevel(exportboxlist)" @change="changeqty(exportboxlist)" :style="exportstyle(i2)"/></td>
																	<td>
																		<a class="badge bg-warning" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deleteexportlist(exportboxlist)">
																			<i class="ri-delete-bin-line mr-0"></i>
																		</a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>

											</div>
										</b-sidebar>


																		<div class="mb-4 ml-1 d-none" id="printMe7">
																			<div v-for="(purchase,idx) in purchaselists" :key="idx" :style="barcodegap(idx+1,purchaselists.length)">
																				<div v-for="index in parseInt(purchase.입고수량/(purchase.판매구성수량 == '단품' ? 1 : purchase.판매구성수량 ? parseInt(purchase.판매구성수량) : 1))" :key="index">
																					<div  class="row text-center d-flex justify-content-center ml-1" :style="barcodegap(index,parseInt(purchase.입고수량/(purchase.판매구성수량 == '단품' ? 1 : purchase.판매구성수량 ? parseInt(purchase.판매구성수량) : 1)))">
																						<div class="col-12 text-center d-flex justify-content-center mb-0" style="margin-bottom:0px;margin-left:10px;margin-right:10px;color:black;">
																							<p class="mb-0" style="font-size:90%;color:black;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>SKU : {{purchase.SKUID}}</span></p>
																						</div>
																						<div class="col-12 text-center d-flex justify-content-center mt-0 mb-0" style="margin-top:0px;margin-bottom:0px; width: 100%">
																							<barcode :value="purchase.바코드.trim()" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																						</div>
																						<div class="col-12 mt-0 mb-0" style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:5px;">
																							<span :style="disablekr">{{purchase.상품명}}</span><br>
																							<span :style="enablekr">1. 상품명 : {{purchase.상품명}}</span><br>
																							<span :style="enablekr">2. 수입원/판매원 : {{purchase.한글표시사항['수입원/판매원']}}</span><br>
																							<span :style="enablekr">3. 제조원 : {{purchase.한글표시사항.제조원}}</span><br>
																							<span :style="enablekr">4. 제조국 : {{purchase.한글표시사항.제조국}}</span><br>
																							<span :style="enablekr">5. 내용량 : {{purchase.한글표시사항.내용량}}</span><br>
																							<span :style="enablekr">6. 원료명및성분명(재질) : {{purchase.한글표시사항['원료명및성분명(재질)']}}</span><br>
																							<span :style="enablekr">7. 상품유형 : {{purchase.한글표시사항.상품유형}}</span><br>
																							<span :style="enablekr">8. 사용시주의사항 : {{purchase.한글표시사항.사용시주의사항}}</span><br>
																							<span :style="enablekr">9. 사용기준 : {{purchase.한글표시사항.사용기준}}</span><br>

																						</div>
																					</div>
																				</div>
																			</div>
																		</div>


										<div class="table-responsive mt-2">
											<table id="datatable1" class="table data-table table-striped dataTable" >
												<thead>
													<tr class="ligth ligth-data">
														<th>
															<div class="checkbox d-inline-block">
																<input type="checkbox" class="checkbox-input" id="checkboxpall" @change="checkallbox1" v-model="checkboxall1">
																<label for="checkboxpall" class="mb-0"></label>
															</div>
														</th>
														<th>배송상태</th>
														<th>주문날짜</th>
														<th style="min-width:300px;">상품정보</th>
														<th>요청사항</th>
														<th>출고지/출고방법</th>
														<th>주문수량</th>
														<th>입고수량</th>
														<th>중국어옵션1</th>
														<th>중국어옵션2</th>
														<th>상품링크</th>
														<th style="max-width:50px;">주문번호</th>
														<th>운송장번호</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(purchase,index) in purchaselists" :key="index" >
														<td>
															<div class="checkbox d-inline-block">
																<input type="checkbox" class="checkbox-input" id="checkboxp" v-model="purchase.checked" @change="checkpurchase(purchase,index)">
																<label for="checkboxp" class="mb-0"></label>
															</div>
														</td>
														<td><a :href="logislink(purchase)" target="_blank"><div :class="logisclass(purchase)">{{purchase.배송상태}}</div></a></td>

														<td>{{getdate(purchase.주문날짜)}}</td>
														<td v-b-modal="modalID(index)" @click="importerrorset(purchase)">
															<div class="d-flex align-items-center">
																<img :src="purchase.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
																<div>
																	{{purchase.상품명}}
																	<p class="mb-0"><small>{{purchase.SKUID}}</small></p>
																	<p class="mb-0"><small>{{purchase.바코드.trim()}}</small></p>
																</div>
															</div>
														</td>

															<b-modal :id="'modal'+index" scrollable title="제품상세" ok-title="OK" cancel-title="Close" class="detail-modal"  @ok="handleOk2(purchase)" @cancel="handleOk2(purchase)">
																<div class="row">
																	<div class="col-md-6">
																		<img class="w-100 d-block" :src="purchase.등록이미지" style="border:1px solid silver; border-radius: 30px">
																	</div>
																	<div class="col-md-6">
																		<p style="font-size:120%">구매수량 : {{purchase.주문수량}}</p>
																		<p style="font-size:120%">판매구성 : {{purchase.판매구성수량}}</p>
																		<div class="mb-4 ml-1 d-none" id="printMe">
																			<div v-for="index in parseInt(purchase.입고수량/(purchase.판매구성수량 == '단품' ? 1 : purchase.판매구성수량 ? parseInt(purchase.판매구성수량) : 1))" :key="index">
																				<div  class="row text-center d-flex justify-content-center ml-1" :style="barcodegap(index,parseInt(purchase.입고수량/(purchase.판매구성수량 == '단품' ? 1 : purchase.판매구성수량 ? parseInt(purchase.판매구성수량) : 1)))">
																					<div class="col-12 text-center d-flex justify-content-center mb-0" style="margin-bottom:0px;margin-left:10px;margin-right:10px;color:black;">
																						<p class="mb-0" style="font-size:90%;color:black;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>SKU : {{purchase.SKUID}}</span></p>
																					</div>
																					<div class="col-12 text-center d-flex justify-content-center mt-0 mb-0" style="margin-top:0px;margin-bottom:0px; width: 100%">
																						<barcode :value="purchase.바코드.trim()" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																					</div>
																					<div class="col-12 mt-0 mb-0" style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:5px;">
																						<span :style="disablekr">{{purchase.상품명}}</span><br>
																						<span :style="enablekr">1. 상품명 : {{purchase.상품명}}</span><br>
																						<span :style="enablekr">2. 수입원/판매원 : {{purchase.한글표시사항['수입원/판매원']}}</span><br>
																						<span :style="enablekr">3. 제조원 : {{purchase.한글표시사항.제조원}}</span><br>
																						<span :style="enablekr">4. 제조국 : {{purchase.한글표시사항.제조국}}</span><br>
																						<span :style="enablekr">5. 내용량 : {{purchase.한글표시사항.내용량}}</span><br>
																						<span :style="enablekr">6. 원료명및성분명(재질) : {{purchase.한글표시사항['원료명및성분명(재질)']}}</span><br>
																						<span :style="enablekr">7. 상품유형 : {{purchase.한글표시사항.상품유형}}</span><br>
																						<span :style="enablekr">8. 사용시주의사항 : {{purchase.한글표시사항.사용시주의사항}}</span><br>
																						<span :style="enablekr">9. 사용기준 : {{purchase.한글표시사항.사용기준}}</span><br>

																					</div>
																				</div>
																			</div>
																		</div>

																		<div class="mb-4 ml-1 d-none" id="printMe01">
																			<div v-for="index in parseInt(출력수량)" :key="index">
																				<div :style="barcodegap2(index,parseInt(출력수량))">

																					<div style="display:flex;justify-content: space-between;width:90%;margin-bottom:0px;">
																						<p style="font-size:90%;">Made in China</p>
																						<p v-if="printskuenable" style="font-size:90%;">SKU : {{purchase.SKUID}}</p>
																					</div>
																					<div style="margin-top:-15px;margin-bottom:0px; width: 100%" v-if="printbarcodeenable">
																						<barcode :value="purchase.바코드.trim()" :format="'CODE128'" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																					</div>
																					<div v-if="printfoodenable" :style="'width:100%;margin-top:0px;margin-bottom:0px;' + 'font-size:'+폰트사이즈+'%'">
                                                                                        <span style="font-size:80%;color:black;display:block">{{purchase.상품명}}</span>
																						<div style="font-size:90%;font-weight:bold">
																							식품위생법에 의한 한글표시사항
																						</div>
																					</div>
																					<div :style="'margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:3px;' + 'font-size:'+폰트사이즈+'%'">
																						<span v-if="!printfoodenable" :style="disablekr">{{purchase.상품명}}</span><br>
																						<span :style="enablekr">· 상품명 : {{purchase.상품명}}</span><br>
																						<span :style="enablekr">· 수입원/판매원 : {{purchase.한글표시사항['수입원/판매원']}}</span><br>
																						<span :style="enablekr">· 제조원 : {{purchase.한글표시사항.제조원}}</span><br>
																						<span :style="enablekr">· 제조국 : {{purchase.한글표시사항.제조국}}</span><br>
																						<span :style="enablekr">· 내용량 : {{purchase.한글표시사항.내용량}}</span><br>
																						<span :style="enablekr">· 원료명및성분명(재질) : {{purchase.한글표시사항['원료명및성분명(재질)']}}</span><br>
																						<div style="display: flex;justify-content: space-between;">
																							<div>
																								<span :style="enablekr">· 상품유형 : {{purchase.한글표시사항.상품유형}}</span><br>
																								<span :style="enablekr">· 사용시주의사항 : {{purchase.한글표시사항.사용시주의사항}}</span><br>
																								<span :style="enablekr">· 사용기준 : {{purchase.한글표시사항.사용기준}}</span><br>
																							</div>
																							<div style="height:60px;margin-right:10px;margin-top:10px;margin-left:10px;">
																							<!-- <div :style="printmark"> -->
																								<img v-if="printfoodenable" style="height:100%" :src="require('../../../assets/images/small/ForFood.png')">
																								<img v-if="printkcenable" style="height:100%" :src="require('../../../assets/images/small/KcMark.jpg')">
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>

																		<div class="mt-2 ml-0 mb-2 row">
																			<button class="btn btn-primary" type="button" @click="print('printMe')">전체바코드출력</button>
																			<button class="ml-3 btn btn-success" type="button" v-b-toggle.my-collapse1>개별출력</button>
																		</div>
																		<div class="mt-2 ml-0">
																			<b-collapse id="my-collapse1" class="mt-2"  @shown="handleCollapseShown">
																				<b-card>
																					<div>
																						<div class="ml-0 w-50 d-flex align-items-center justify-content-start">
																							<div class="ml-0" style="width:30%">출력수량 : </div>
																							<input type="number" class="w-25 form-control" placeholder="출력수량" v-model="출력수량">
																						</div>
																					</div>
																					<div>
																						<div class="ml-0 w-50 mt-1 d-flex align-items-center justify-content-start">
																							<div class="ml-0" style="width:30%">바코드간격 : </div>
																							<input type="number" class="w-25 form-control" placeholder="바코드간격" v-model="바코드간격">
																						</div>
																					</div>
																					<div>
																						<div class="ml-0 w-50 mt-1 d-flex align-items-center justify-content-start">
																							<div class="ml-0" style="width:30%">폰트size(%) : </div>
																							<input type="number" class="w-25 form-control" placeholder="폰트사이즈" v-model="폰트사이즈">
																						</div>
																					</div>
																					<div>

																						<div class="ml-0 custom-control custom-switch custom-control-inline">
																							<input type="checkbox" class="custom-control-input" id="customSwitchsku" v-model="printskuenable">
																							<label class="custom-control-label" for="customSwitchsku">SKU번호</label>
																						</div>
																						<div class="ml-0 custom-control custom-switch custom-control-inline">
																							<input type="checkbox" class="custom-control-input" id="customSwitchkc" v-model="printkcenable">
																							<label class="custom-control-label" for="customSwitchkc">KC마크</label>
																						</div>
																						<div class="ml-0 custom-control custom-switch custom-control-inline">
																							<input type="checkbox" class="custom-control-input" id="customSwitchfood" v-model="printfoodenable">
																							<label class="custom-control-label" for="customSwitchfood">식품용</label>
																						</div>
																						<div class="ml-0 custom-control custom-switch custom-control-inline">
																							<input type="checkbox" class="custom-control-input" id="customSwitchbarcode" v-model="printbarcodeenable">
																							<label class="custom-control-label" for="customSwitchbarcode">바코드</label>
																						</div>
																					</div>
																					<button v-if="!openstatus" type="button" class="btn btn-danger mt-2" @click="opencollapse(purchase)" v-b-toggle.my-collapse>내용수정</button>
																					<button v-if="!openstatus" type="button" class="btn btn-success mt-2 ml-2" @click="editprint('printMe01', purchase)">출력</button>
																					<b-collapse id="my-collapse" class="mt-3">
																						<b-card>
																							<textarea style="height:300px;" class="form-control" placeholder="수정출력할 내용을 작성해 주세요" v-model="수정출력내용"></textarea>
																							<button type="button" class="btn btn-success mt-2 mr-2" @click="editprint('printMe02', purchase)">출력</button>
                                                                                            <button type="button" class="btn btn-success mt-2" v-b-toggle.my-collapse>취소</button>
																						</b-card>
																					</b-collapse>
																				</b-card>
																			</b-collapse>
																		</div>

																		<div class="mt-2 mb-2">

																		</div>



																		<div class="mb-4 ml-1 d-none" id="printMe02">
																			<div v-for="index in parseInt(출력수량)" :key="index">
																				<div :style="barcodegap2(index,parseInt(출력수량))">

																					<div style="display:flex;justify-content: space-between;width:90%;margin-bottom:-5%;">
																						<p style="font-size:90%;">Made in China</p>
																						<p v-if="printskuenable" style="font-size:90%;">SKU : {{purchase.SKUID}}</p>
																					</div>
																					<div style="margin-bottom:0px; width: 100%">
																						<barcode :value="purchase.바코드.trim()" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																					</div>
																					<div v-if="printfoodenable" :style="'width:100%;margin-top:0px;margin-bottom:0px;' + 'font-size:'+폰트사이즈+'%'">
                                                                                        <span style="font-size:80%;color:black;display:block">{{editliststart[0]}}</span>
																						<div style="font-size:90%;font-weight:bold">
																							식품위생법에 의한 한글표시사항
																						</div>
																					</div>
																					<div :style="'margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:3px;' + 'font-size:'+폰트사이즈+'%'">

<!--																						<span :style="enablekr" v-for="edit,ix in editliststart" :key="ix">{{edit}}</span><br>-->
                                                                                        <span :style="enablekr" v-for="edit,ix in editliststart" :key="ix">
                                                                                                    <template v-if="ix !== 0 && printfoodenable">{{ edit }}</template>
                                                                                        </span><br>
																						<div style="display: flex;justify-content: space-between;">
																							<div>
																								<span :style="enablekr" v-for="edit2,ix2 in editlistend" :key="ix2">{{edit2}}</span><br>
																							</div>
																							<div style="height:60px;margin-right:10px;margin-top:10px;margin-left:10px;">
																							<!-- <div :style="printmark"> -->
																								<img v-if="printfoodenable" style="height:100%" :src="require('../../../assets/images/small/ForFood.png')">
																								<img v-if="printkcenable" style="height:100%" :src="require('../../../assets/images/small/KcMark.jpg')">
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>



																		<p>상품명 : {{purchase.상품명}}</p>
																		<p>SKU ID : {{purchase.SKUID}}</p>
																		<p>바코드 : {{purchase.바코드.trim()}}</p>
																		<p>구매링크 : <a :href="purchase.상품링크">{{purchase.상품링크}}</a></p>
																		<p>옵션1_중국어 : {{purchase.중국어옵션1}}</p>
																		<p>옵션2_중국어 : {{purchase.중국어옵션2}}</p>

																		<p>[배송 요청사항]</p>
                                                                        <card style="color:blue;" v-if="purchase.요청매칭 && purchase.요청매칭.length > 0">
                                                                            <div class="ml-1 mt-1 mb-1 mr-1 row">
                                                                                <div class="col-5">
                                                                                    <span><b>[출고작업]</b></span><br>
                                                                                    <span>배치 : {{purchase.요청매칭[0].selectpo ? '발주배치' : '해당없음'}}</span><br>
                                                                                    <span>배치발주번호 : {{purchase.요청매칭[0].selectpo}}</span><br>
                                                                                    <span>출고유형 : {{purchase.요청매칭[0].요청내역.exportType.toUpperCase()}}</span><br>
																					<span>출고목적지 : {{purchase.요청매칭[0].요청내역.destination ? purchase.요청매칭[0].요청내역.destination : 'coupang'}}</span><br>
                                                                                    <br>
                                                                                    <span><b>[검수 및 포장 방법]</b></span><br>
																					<span>기본검수 : {{purchase.요청매칭[0].요청내역.default ? 'O' : ''}}</span><br>
																					<span>포장방법위임 : {{purchase.요청매칭[0].요청내역.auto ? 'O' : ''}}</span><br>
																					<span>재포장생략 : {{(purchase.요청매칭[0].요청내역.auto == false && !purchase.요청매칭[0].요청내역.LDPE && !purchase.요청매칭[0].요청내역.OPP) ? "O" : ""}}</span><br>

																					<span><b>[포장 방법 세부]</b></span><br>
																					<span>OPP봉투 : {{purchase.요청매칭[0].요청내역.OPP ? 'O' : ''}}</span><br>
																					<span>LDPE봉투 : {{purchase.요청매칭[0].요청내역.LDPE ? 'O' : ''}}</span><br>
                                                                                    <span>중국어마스킹 : {{purchase.요청매칭[0].요청내역.masking ? 'O' : ''}}</span><br>
                                                                                    <span>에어캡 : {{purchase.요청매칭[0].요청내역.airpacking ? 'O' : ''}}</span><br>
                                                                                    <br>
                                                                                </div>
                                                                                <div class="col-7">
                                                                                    <span><b>[바코드 라벨 및 원산지 작업]</b></span><br>
                                                                                    <span>바코드 : {{purchase.요청매칭[0].요청내역.barcode ? 'O' : ''}}</span><br>
																					<span>라벨봉제 : {{purchase.요청매칭[0].요청내역.sewing ? 'O' : ''}}</span><br>
                                                                                    <span>원산지스티커 : {{purchase.요청매칭[0].요청내역.sticker ? 'O' : ''}}</span><br>
                                                                                    <span>도장 : {{purchase.요청매칭[0].요청내역.stamp ? 'O' : ''}}</span><br>
                                                                                    <span>택총 : {{purchase.요청매칭[0].요청내역.tag ? 'O' : ''}}</span><br>
                                                                                    <br>
																					<span><b>[통관 정보]</b></span><br>
                                                                                    <span>신고영문명 : {{purchase.요청매칭[0].신고영문명 ? purchase.요청매칭[0].신고영문명 : ''}}</span><br>
                                                                                    <span>신고단가(USD) : {{purchase.요청매칭[0].신고가격 ? purchase.요청매칭[0].신고가격 : ''}}</span><br>
                                                                                    <span>HS-CODE : {{purchase.요청매칭[0].HSCODE ? purchase.요청매칭[0].HSCODE : ''}}</span><br>
                                                                                    <br>
                                                                                    <span><b>[기타 요청사항] - 물류</b></span><br>
                                                                                    <span>{{purchase.요청매칭[0].요청내역.reqcontent}}</span><br>
																					<span><b>[기타 요청사항] - 구매</b></span><br>
                                                                                    <span>{{purchase.요청매칭[0].요청내역?.reqcontentPurchase}}</span><br>
                                                                                </div>

                                                                            </div>

                                                                        </card>


																		<div class="row">
																			<div class="col-md-4">
																				<button :class="importerrorbutton == '입고오류' ? 'btn btn-secondary' : 'btn btn-danger'" type="button" @click="importerror(purchase)">{{importerrorbutton}}</button>
																			</div>
																			<div class="col-md-8" v-if="showimporterror">
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio15" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.입고오류유형" value="수량불일치">
																					<label class="custom-control-label" for="customRadio15">수량불일치</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio16" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.입고오류유형" value="파손">
																					<label class="custom-control-label" for="customRadio16">파손</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio17" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.입고오류유형" value="오배송">
																					<label class="custom-control-label" for="customRadio17">오배송</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio18" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.입고오류유형" value="기타불량">
																					<label class="custom-control-label" for="customRadio18">기타불량</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="text" class="form-control" placeholder="제품위치" @change="changeimporterror(purchase)" v-model="purchase.제품위치">
																				</div>
																			</div>
																			<div class="col-md-12 mt-2" v-if="showimporterror && showimporterror1">
																				<div class="form-group">
																					<label for="exampleFormControlTextarea1">실제입고수량</label>
																					<input :min="0" type="number" class="form-control" placeholder="실제입고수량" aria-label="" v-model="purchase.입고수량">
																				</div>
																			</div>
																			<div class="col-md-12 mt-2" v-if="showimporterror && showimporterror2">
																				<div class="form-group">
																					<label for="exampleFormControlTextarea1">입고오류내용</label>
																					<textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="purchase.입고오류내용"></textarea>
																				</div>
																			</div>
																			<div class="col-md-12 mt-2" v-if="showimporterror && showimporterror2">
																				<div class="form-group">
																					<label for="exampleFormControlTextarea1">입고오류이미지</label>
																					<input ref="imgupload" type="file" @change="uploaderrorimage" class="custom-file-input" id="inputGroupFile02">
																					<button @click="upload">upload</button>
																					<b-img :src="errorimgaddress" fluid alt="Fluid image"></b-img>
																					<button @click="cameraon">cameraon</button>
																						<div v-if="showcamera" style="position:relative;width:300px;height:300px;">
																							<v-easy-camera
																								v-model="picture"
																								ref="picpreview"
																								output="blob"
																								style="width: 300px; height: 300px; border: 2px dotted"
																							></v-easy-camera>
																						</div>
																				</div>
																			</div>





																		</div>



																	</div>
																</div>

															</b-modal>



														<td>{{purchase.요청사항}}</td>
														<td>{{purchase.출고지}}/{{purchase.출고방법}}</td>
														<td>{{purchase.주문수량}}</td>
														<td><input type="number" :min="0" class="form-control" :placeholder="purchase.입고수량" v-model="purchase.입고수량"></td>
														<td>{{purchase.중국어옵션1}}</td>
														<td>{{purchase.중국어옵션2}}</td>
														<td class="text-center"><a :href="purchase.상품링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
                                                        <td style="max-width:50px;">{{purchase.주문번호}}</td>
														<td>{{purchase.운송장번호}}</td>
														<td>
															<div class="d-flex align-items-center list-action">
																<a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="View"
																	href="javascript:void(0)" v-b-modal="modalID(index)"><i class="ri-eye-line mr-0"></i></a>
																<!-- <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
																	href="javascript:void(0)"><i class="ri-delete-bin-line mr-0"></i></a> -->
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>




									</div>

								</form>
							</tab-content-item>

							<tab-content-item :active="false" id="importerror" aria-labelled-by="importerror-tab">
								<p>입고오류내역 검색 및 입고처리</p>
								<form @submit.prevent="submit">
									<div class="row">
										<div class="col-md-2">
											<div class="form-group">
												<label>업체검색</label>
												<input type="text" class="form-control" placeholder="업체ID" v-model="업체검색" @keyup.enter="searchimporterrorwith($event)">
											</div>
										</div>
									</div>

									<div class="col-lg-12" v-if="showimporterrorlist">
										<div class="p-3">
											<div class="row">
												<b-button variant="outline-secondary" class="mb-1 mr-2" @click="showi">
												입고수량오류<b-badge variant="secondary" class="ml-2">{{입고수량오류}}</b-badge>
												</b-button>
												<b-button variant="outline-warning" class="mb-1 mr-2" @click="showj">
												입고상품오류<b-badge variant="warning" class="ml-2">{{입고상품오류}}</b-badge>
												</b-button>
												<b-button variant="outline-warning" class="mb-1 mr-2" @click="showl">
												오류입고처리<b-badge variant="warning" class="ml-2">{{오류입고처리}}</b-badge>
												</b-button>
												<b-button variant="outline-warning" class="mb-1 mr-2" @click="showm">
												오류반품처리<b-badge variant="warning" class="ml-2">{{오류반품처리}}</b-badge>
												</b-button>
												<b-button variant="outline-warning" class="mb-1 mr-2" @click="shown">
												오류폐기처리<b-badge variant="warning" class="ml-2">{{오류폐기처리}}</b-badge>
												</b-button>
												<b-button variant="outline-warning" class="mb-1 mr-2" @click="showo">
												오류입고대기<b-badge variant="warning" class="ml-2">{{오류입고대기}}</b-badge>
												</b-button>
												<b-button variant="outline-warning" class="mb-1 mr-2" @click="showp">
												반품폐기완료<b-badge variant="warning" class="ml-2">{{반품폐기완료}}</b-badge>
												</b-button>
											</div>
										</div>
									</div>

									<div class="col-lg-12 mt-3" v-if="showimporterrorlist">
										<div class="table-responsive">
											<table id="datatable" class="table data-table table-striped dataTable" >
												<thead>
													<tr class="ligth ligth-data">
														<th>
															<div class="checkbox d-inline-block">
																<input type="checkbox" class="checkbox-input" id="checkboxpall" @change="checkallimporterror" v-model="checkallimporterror1">
																<label for="checkboxpall" class="mb-0"></label>
															</div>
														</th>
														<th>업체ID</th>
														<th>제품위치</th>
														<th>배송상태</th>
														<th>처리방법</th>
														<th style="min-width:300px;">상품정보</th>
														<th>주문수량</th>
														<th>입고수량</th>
														<th style="max-width:50px;">주문번호</th>
														<th>운송장번호</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(purchase,index) in importerrorlists" :key="index" >
														<td>
															<div class="checkbox d-inline-block">
																<input type="checkbox" class="checkbox-input" id="checkboxp" v-model="purchase.checked">
																<label for="checkboxp" class="mb-0"></label>
															</div>
														</td>
														<td>{{purchase.업체ID}}</td>
														<td>{{purchase.제품위치}}</td>
														<td><a :href="logislink(purchase)" target="_blank"><div :class="logisclass(purchase)">{{purchase.배송상태}}</div></a></td>
														<td>{{purchase.처리방법}}</td>
														<td v-b-modal="modalID(index)" @click="importerrorset(purchase,index)">
															<div class="d-flex align-items-center">
																<img :src="purchase.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
																<div>
																	{{purchase.상품명}}
																	<p class="mb-0"><small>{{purchase.SKUID}}</small></p>
																	<p class="mb-0"><small>{{purchase.바코드.trim()}}</small></p>
																</div>
															</div>
														</td>

															<b-modal :id="'modal'+index" scrollable title="제품상세" ok-title="OK" cancel-title="Close" class="detail-modal"  @ok="refreshpage">
																<div class="row">
																	<div class="col-md-6">
																		<img class="w-100 d-block" :src="purchase.등록이미지" style="border:1px solid silver; border-radius: 30px">
																	</div>
																	<div class="col-md-6">
																		<p style="font-size:120%">구매수량 : {{purchase.주문수량}}</p>
																		<p style="font-size:120%">판매구성 : {{purchase.판매구성수량}}</p>
																		<div class="mb-4 ml-1 d-none" id="printMe9">
																			<div v-for="index in parseInt(purchase.입고수량/(purchase.판매구성수량 == '단품' ? 1 : purchase.판매구성수량 ? parseInt(purchase.판매구성수량) : 1))" :key="index">
																				<div  class="row text-center d-flex justify-content-center ml-1" :style="barcodegap(index,parseInt(purchase.입고수량/(purchase.판매구성수량 == '단품' ? 1 : purchase.판매구성수량 ? parseInt(purchase.판매구성수량) : 1)))">
																					<div class="col-12 text-center d-flex justify-content-center mb-0" style="margin-bottom:0px;margin-left:10px;margin-right:10px;color:black;">
																						<p class="mb-0" style="font-size:90%;color:black;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>SKU : {{purchase.SKUID}}</span></p>
																					</div>
																					<div class="col-12 text-center d-flex justify-content-center mt-0 mb-0" style="margin-top:0px;margin-bottom:0px; width: 100%">
																						<barcode :value="purchase.바코드.trim()" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																					</div>
																					<div class="col-12 mt-0 mb-0" style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:5px;">
																						<span :style="disablekr">{{purchase.상품명}}</span><br>
																						<span :style="enablekr">1. 상품명 : {{purchase.상품명}}</span><br>
																						<span :style="enablekr">2. 수입원/판매원 : {{purchase.한글표시사항 ? purchase.한글표시사항['수입원/판매원'] : ''}}</span><br>
																						<span :style="enablekr">3. 제조원 : {{purchase.한글표시사항 ? purchase.한글표시사항.제조원 : ''}}</span><br>
																						<span :style="enablekr">4. 제조국 : {{purchase.한글표시사항 ? purchase.한글표시사항.제조국 : ''}}</span><br>
																						<span :style="enablekr">5. 내용량 : {{purchase.한글표시사항 ? purchase.한글표시사항.내용량 : ''}}</span><br>
																						<span :style="enablekr">6. 원료명및성분명(재질) : {{purchase.한글표시사항 ? purchase.한글표시사항['원료명및성분명(재질)'] : ''}}</span><br>
																						<span :style="enablekr">7. 상품유형 : {{purchase.한글표시사항 ? purchase.한글표시사항.상품유형 : ''}}</span><br>
																						<span :style="enablekr">8. 사용시주의사항 : {{purchase.한글표시사항 ? purchase.한글표시사항.사용시주의사항 : ''}}</span><br>
																						<span :style="enablekr">9. 사용기준 : {{purchase.한글표시사항 ? purchase.한글표시사항.사용기준 : ''}}</span><br>

																						</div>
																				</div>
																			</div>
																		</div>
																		<div class="mt-2 mb-3 row">
																			<div class="col-3"><button class="btn btn-primary" type="button" @click="print('printMe9')">바코드출력</button></div>
																		</div>
																		<p>상품명 : {{purchase.상품명}}</p>
																		<p>SKU ID : {{purchase.SKUID}}</p>
																		<p>바코드 : {{purchase.바코드.trim()}}</p>
																		<p>구매링크 : <a :href="purchase.상품링크">{{purchase.상품링크}}</a></p>
																		<p>옵션1_중국어 : {{purchase.중국어옵션1}}</p>
																		<p>옵션2_중국어 : {{purchase.중국어옵션2}}</p>
																		<div class="row">
																			<div class="col-md-4">
																				<button :class="importerrorbutton == '입고오류' ? 'btn btn-secondary' : 'btn btn-danger'" type="button" @click="importerror(purchase)">{{importerrorbutton}}</button>
																			</div>
																			<div class="col-md-8" v-if="showimporterror">
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio15" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.입고오류유형" value="수량불일치">
																					<label class="custom-control-label" for="customRadio15">수량불일치</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio16" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.입고오류유형" value="파손">
																					<label class="custom-control-label" for="customRadio16">파손</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio17" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.입고오류유형" value="오배송">
																					<label class="custom-control-label" for="customRadio17">오배송</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio18" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.입고오류유형" value="기타불량">
																					<label class="custom-control-label" for="customRadio18">기타불량</label>
																				</div>
																			</div>
																			<div class="col-md-12 mt-2" v-if="showimporterror && showimporterror1">
																				<div class="form-group">
																					<label for="exampleFormControlTextarea1">실제입고수량</label>
																					<input :min="0" type="number" class="form-control" placeholder="실제입고수량" aria-label="" v-model="purchase.입고수량">
																				</div>
																			</div>
																			<div class="col-md-12 mt-2" v-if="showimporterror && showimporterror2">
																				<div class="form-group">
																					<label for="exampleFormControlTextarea1">입고오류내용</label>
																					<textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="purchase.입고오류내용"></textarea>
																				</div>
																			</div>

																			<div class="col-md-12 mt-2" v-if="purchase.처리방법">
																				<p>처리방법 : {{purchase.처리방법}}</p>
																			</div>
																			<div class="col-md-12 mt-2" v-if="purchase.처리방법 && purchase.처리방법 == '반품'">
																				<div class="form-group">
																					<label for="exampleFormControlTextarea1">반품정보</label>
                                                                                    <input type="text" class="form-control" placeholder="반품수취인" v-model="purchase.반품수취인">
                                                                                    <input type="text" class="form-control" placeholder="반품연락처" v-model="purchase.반품연락처">
																					<textarea class="form-control" placeholder="반품주소" id="exampleFormControlTextarea1" rows="3" v-model="purchase.반품주소"></textarea>
                                                                                    <input type="text" class="form-control" placeholder="반품운송장번호" v-model="purchase.반품운송장번호">
																				</div>
																			</div>
																			<div class="col-md-12 mt-2" v-if="purchase.처리방법 && purchase.처리방법 == '입고대기'">
																				<div class="form-group">
																					<label for="exampleFormControlTextarea1">입고대기 사유</label>
																					<textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="purchase.입고대기사유"></textarea>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</b-modal>




														<td>{{purchase.주문수량}}</td>
														<td><input type="number" :min="0" class="form-control" :placeholder="purchase.입고수량" v-model="purchase.입고수량"></td>
														<td style="max-width:50px;">{{purchase.주문번호}}</td>
														<td>{{purchase.운송장번호}}</td>
														<td>
															<div class="d-flex align-items-center list-action">
																<a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="View"
																	href="javascript:void(0)" v-b-modal="modalID(index)" @click="importerrorset(purchase,index)"><i class="ri-eye-line mr-0"></i></a>
																<a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
																	href="javascript:void(0)"><i class="ri-delete-bin-line mr-0"></i></a>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<button class="btn btn-success mr-3" type="button" @click="doimport">입고처리</button>
										<button class="btn btn-secondary mr-3" type="button" @click="doreturn">반품/폐기처리</button>

									</div>


								</form>
							</tab-content-item>

							<tab-content-item :active="false" id="home" aria-labelled-by="home-tab">
								<p>바코드 작업이 완료된 실재고를 등록해 주세요</p>
								<form @submit.prevent="submit">
									<div class="row">
										<div class="col-md-3">
											<div class="form-group">
												<label>Barcode 조회</label>
												<input ref="findbarcode" type="text" class="form-control" placeholder="Barcode 조회" @keyup.enter="findbarcodeinfo($event)" v-model="barcodevalue">
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label>SKU ID 조회</label>
												<input type="text" class="form-control" placeholder="SKU ID 조회" @keyup.enter="findskuinfo($event)" v-model="skuidvalue">
											</div>
										</div>

										<div class="col-md-12">

											<div class="custom-control custom-radio custom-control-inline">
												<input type="radio" id="customRadio8" name="customRadio-1" class="custom-control-input" v-model="selected" value="reg" @change="showbox">
												<label class="custom-control-label" for="customRadio8" v-b-tooltip.top="'발주서와 관계없이 재고로 등록'">재고 등록</label>
											</div>
											<div class="custom-control custom-radio custom-control-inline">
												<input type="radio" id="customRadio7" name="customRadio-1" class="custom-control-input" v-model="selected" value="default" @change="showbox">
												<label class="custom-control-label" for="customRadio7" v-b-tooltip.top="'발주서를 선택한 후 배치 등록'">발주 배치</label>
											</div>
											<div class="custom-control custom-radio custom-control-inline">
												<input type="radio" id="customRadio77" name="customRadio-1" class="custom-control-input" v-model="selected" value="export" @change="showbox">
												<label class="custom-control-label" for="customRadio77" v-b-tooltip.top="'발주서 선택 없이 출고 배치 등록'">출고 배치</label>
											</div>

										</div>

										<div class="col-md-9 mt-3" v-if="showfindlist">
											<div class="row">
												<div class="col-9">
													<card style="padding:10px;">
														<div class="d-flex align-items-center" style="margin:auto;padding:0px">
															<img :src="findlists[0].발주세부내역.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
															<div>
																{{findlists[0].발주세부내역.상품명}}
																<p class="mb-0">바코드 : {{findlists[0].발주세부내역.바코드}}</p>
																<p class="mb-0">SKU ID : {{findlists[0].발주세부내역.SKUID}}</p>
															</div>
														</div>
													</card>
												</div>
												<div class="col-3 text-center">
													<card style="padding:10px;">
															<p class="mb-0">신규배치박스번호</p>
															<p class="mb-0" style="font-size:200%">{{신규박스번호}}</p>
													</card>
												</div>
											</div>
										</div>
										<div class="table-responsive col-md-12" style="text-align:center;vertical-align:middle;" v-if="showfindlist">
											<table id="datatable" class="table data-table table-striped dataTable">
											<thead style="font-size:80%;">
												<tr class="ligth ligth-data">


													<th style="font-size:115%;vertical-align: middle;">발주센터</th>
													<th style="font-size:115%;vertical-align: middle;">입고예정일</th>
													<th style="font-size:115%;vertical-align: middle;">필요수량</th>
													<th style="font-size:115%;vertical-align: middle;">추가배치수량</th>
													<th style="font-size:115%;vertical-align: middle;">배치박스</th>
													<th style="font-size:115%;vertical-align: middle;">현재배치수량</th>
													<th style="font-size:115%;vertical-align: middle;">발주번호</th>

												</tr>
											</thead>
											<tbody style="font-size:100%;">
												<tr v-for="a,i1 in findlists" :key="i1">

													<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{a.발주센터}}<span class="ml-2" @click="print('printMe2' + i1)"><i class="ri-printer-line" style="font-size:200%"></i></span>
														<div class="d-none text-center" style="text-align:center;" :id="'printMe2'+i1">
															<div class="row mb-0" style="text-align:center;font-size:200%">{{업체명}}</div>
															<div class="row mb-0" style="text-align:center;font-size:200%">{{a.발주센터}}</div>
															<div class="row mb-0" style="text-align:center;font-size:200%">{{a.입고예정일}}</div>
															<div class="row mb-0" style="text-align:center;font-size:400%">{{a.배치박스번호}}</div>
															<div class="row mb-0" style="text-align:center; width: 100%">
																<barcode :value="업체명.slice(0,2)+'cn'+a.입고예정일.replaceAll('-','').slice(4)+a.배치박스번호" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
															</div>
														</div>
													</td>

													<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{a.입고예정일}}</td>
													<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{a.발주세부내역.필요수량}}</td>
													<td style="max-width:50px;overflow:hidden;text-overflow: ellipsis;">
														<input style="font-size:200%;text-align:center" :min="0" :max="a.발주세부내역.필요수량" type="number" class="form-control" placeholder="배치수량" aria-label="" v-model="a.추가배치수량">
													</td>
													<td style="max-width:100px;overflow:hidden;text-overflow: ellipsis;">

														<div style="display: table; margin-left: auto; margin-right: auto; display: inline-block;">
														<div class="input-group position-relative">
															<input style="font-size:200%;text-align:center" :min="0" type="number" class="form-control h-100" placeholder="박스번호" aria-label="" v-model="a.배치박스번호">
															<span v-if="a.newbox" class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-secondary p-2">new</span>

														</div>
														<div class="input-group mt-2">
															<input style="text-align:center" :min="0" type="text" class="form-control" placeholder="박스위치" aria-label="" v-model="a.배치파렛트번호">
															<div class="input-group-append">
															</div>
														</div>
														</div>

													</td>
													<td style="max-width:50px;overflow:hidden;text-overflow: ellipsis;">{{a.발주세부내역.배치수량}}</td>
													<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{a.발주번호}}</td>

												</tr>
											</tbody>
											</table>
										</div>
										<div class="col-md-12" v-if="showfindlist">
											<button class="btn btn-primary" type="button" @click="savearrange()">배치 저장</button>
										</div>
									</div>


									<div class="row mt-3" v-if="showstockbox">
										<div class="col-md-3">
											<div class="input-group mb-4">
												<input type="number" class="form-control" :min="0" placeholder="박스번호" aria-label="" @change="boxchangeref" v-model="stockboxnum" style="font-size:200%;text-align:center;">
												<div class="input-group-append">
												<b-dropdown class="my-class" variant="primary" type="button" text="리스트">
													<b-dropdown-item @click="boxnumberchange(b)" v-for="b,j in stockboxnumbers" :key="j">{{b}}</b-dropdown-item>
												</b-dropdown>
												</div>
												<span class="ml-2 text-center align-middle" @click="print('printMe3')"><i class="ri-printer-line" style="font-size:200%"></i></span>
												<div class="d-none text-center" style="text-align:center;" id="printMe3">
													<div class="row mb-0" style="text-align:center;font-size:200%">{{업체명}}</div>
													<div class="row mb-0" style="text-align:center;font-size:200%">재고</div>
													<div class="row mb-0" style="text-align:center;font-size:400%">{{stockboxnum}}</div>
													<div class="row mb-0" style="text-align:center; width: 100%">
														<barcode :value="업체명.slice(0,2)+'cn'+'s'+stockboxnum" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="input-group mb-4">
												<input type="text" class="form-control" placeholder="박스위치" aria-label="" @keyup.enter="stocklocation($event)" v-model="stockpalletnum"  style="text-align:center;">
												<div class="input-group-append">
												<button class="btn btn-secondary" type="button" @click="locationchange">지정</button>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="custom-control custom-switch custom-control-inline">
												<input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checkpo">
												<label class="custom-control-label" for="customSwitch2">발주내역 확인등록</label>
											</div>
										</div>

										<div class="col-md-12">
											<div class="form-group">
												<input type="text" ref="barcoderegstock" class="form-control" placeholder="바코드(스캐너)로 등록" @keyup.enter="barcoderegstock($event)" v-model="regstock">
											</div>
										</div>
										<div class="col-md-6">
											<button class="btn btn-primary" type="button" @click="savestock()">재고박스 업데이트</button>
										</div>
										<div class="col-md-6 float-right">
											<div class="float-right"><h3>Total : {{totalqty}} 개</h3></div>
										</div>
										<div class="table-responsive col-md-12" style="font-size:80%;text-align:center;width:100%">
											<table id="datatable" class="table data-table table-striped dataTable" >
												<thead>
													<tr class="ligth ligth-data">

														<th style="font-size:115%;vertical-align: middle;">상품정보</th>
														<th style="font-size:115%;vertical-align: middle;">수량</th>
														<th>삭제</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="stockboxlist,i2 in stockboxlists" :key="i2">
														<td style="min-width:300px;text-align:left;">
															<div class="d-flex align-items-center">
																<img :src="stockboxlist.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
																<div>
																	{{stockboxlist.상품명}}
																	<p class="mb-0">바코드 : {{stockboxlist.바코드}}</p>
																	<p class="mb-0">SKU ID : {{stockboxlist.SKUID}}</p>
																</div>
															</div>
														</td>
														<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;"><b-form-input :min="0" type="number" v-model.number="stockboxlist.수량" @change="stockchange = true" :style="stockstyle(i2)"/></td>
														<td>
															<a class="badge bg-warning" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deletelist(stockboxlist)">
																<i class="ri-delete-bin-line mr-0"></i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>

									</div>

									<div class="row mt-3" v-if="showexportbox">
										<div class="col-md-3">
											<div class="input-group mb-4">
												<input type="number" class="form-control" :min="0" placeholder="박스번호" aria-label="" @change="exportboxchangeref" v-model="exportboxnum" style="font-size:200%;text-align:center;">
												<div class="input-group-append">
													<b-dropdown class="my-class" variant="primary" type="button" text="리스트">
														<b-dropdown-item @click="exportboxnumberchange(b)" v-for="b,j in exportboxnumbers" :key="j">{{b}}</b-dropdown-item>
													</b-dropdown>
												</div>
												<span class="ml-2 text-center align-middle" @click="print('printMe10')"><i class="ri-printer-line" style="font-size:200%"></i></span>
												<div class="d-none text-center" style="text-align:center;" id="printMe10">
													<div class="row mb-0" style="text-align:center;font-size:200%">{{업체명}}</div>
													<div class="row mb-0" style="text-align:center;font-size:200%">출고박스</div>
													<div class="row mb-0" style="text-align:center;font-size:400%">{{exportboxnum}}</div>
													<div class="row mb-0" style="text-align:center; width: 100%">
														<barcode :value="업체명.slice(0,2)+'cn'+exportboxnum" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="input-group mb-4">
												<input type="text" class="form-control" placeholder="박스위치" aria-label="" @keyup.enter="exportlocation($event)" v-model="exportpalletnumber"  style="text-align:center;">
												<div class="input-group-append">
												<button class="btn btn-secondary" type="button" @click="exportlocationchange">지정</button>
												</div>
											</div>
										</div>
										<div class="col-md-6">

										</div>
										<!-- <div class="col-md-3">
											<div class="custom-control custom-switch custom-control-inline">
												<input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checkpo">
												<label class="custom-control-label" for="customSwitch2">발주내역 확인등록</label>
											</div>
										</div>	 -->

										<div class="col-md-6">
											<div class="form-group">
												<input type="text" ref="barcoderegexport" class="form-control" placeholder="바코드(스캐너)로 등록" @keyup.enter="barcoderegexport($event)" v-model="regexport">
											</div>
										</div>
										<div class="col-md-2">
											<b-dropdown class="my-class h-75 w-100" variant="outline-primary" type="button" text="구매내역 선택">
												<b-dropdown-item @click="selectreqpurchase(purchase)" v-for="purchase,i in reqpurchases" :key="i">
													<div class="d-flex align-items-center">
														<img :src="purchase.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
														<div>
															{{purchase.상품명}}
															<p class="mb-0"><small>진행현황 : {{purchase.진행현황}}</small></p>
															<p class="mb-0"><small>옵션1 : {{purchase.옵션1_중국어}}</small></p>
															<p class="mb-0"><small>옵션2 : {{purchase.옵션2_중국어}}</small></p>
														</div>
													</div>
												</b-dropdown-item>
											</b-dropdown>
										</div>


										<div class="col-md-6">
											<button class="btn btn-primary" type="button" @click="saveexport()">출고박스 업데이트</button>
										</div>
										<div class="col-md-6 float-right">
											<div class="float-right"><h3>Total : {{totalqty}} 개</h3></div>
										</div>
										<div class="table-responsive col-md-12" style="font-size:80%;text-align:center;width:100%">
											<table id="datatable" class="table data-table table-striped dataTable" >
												<thead>
													<tr class="ligth ligth-data">

														<th style="font-size:115%;vertical-align: middle;">상품정보</th>
														<th style="font-size:115%;vertical-align: middle;">수량</th>
														<th>삭제</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="exportboxlist,i2 in exportboxlists" :key="i2">
														<td style="min-width:300px;text-align:left;">
															<div class="d-flex align-items-center">
																<img :src="exportboxlist.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
																<div>
																	{{exportboxlist.상품명}}
																	<p class="mb-0">바코드 : {{exportboxlist.바코드}}</p>
																	<p class="mb-0">SKU ID : {{exportboxlist.SKUID}}</p>
																</div>
															</div>
														</td>
														<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;"><b-form-input :min="0" type="number" v-model.number="exportboxlist.수량" @change="exportchange = true" :style="exportstyle(i2)"/></td>
														<td>
															<a class="badge bg-warning" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deleteexportlist(exportboxlist)">
																<i class="ri-delete-bin-line mr-0"></i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>

									</div>


								</form>
							</tab-content-item>





							<tab-content-item :active="false" id="change" aria-labelled-by="change-tab">
								<p>이미 등록된 재고의 박스/수량/위치를 변경하거나, 발주 배치를 진행해 주세요.</p>
								<b-card-group deck>
									<b-card>
										<div class="row">
											<div class="col-md-3">
												<input type="text" class="form-control" placeholder="발주배치박스번호" v-model="cabox">
											</div>
											<div class="col-md-3">
												<input type="text" class="form-control" placeholder="재고박스번호" v-model="cbox">
											</div>
											<div class="col-md-3">
												<input type="text" class="form-control" placeholder="Barcode" v-model="cbar">
											</div>
											<div class="col-md-3">
												<input type="text" class="form-control" placeholder="SKU ID" v-model="csku">
											</div>
											<div class="col-md-12 mt-2">
												<div class="custom-control custom-radio custom-control-inline h-100">
													<input type="radio" id="customRadio12" name="customRadio-2" class="custom-control-input" v-model="selected2" value="stocktotal">
													<label class="custom-control-label" for="customRadio12" v-b-tooltip.top="'전체재고'">전체재고</label>
												</div>
												<div class="custom-control custom-radio custom-control-inline h-100">
													<input type="radio" id="customRadio11" name="customRadio-2" class="custom-control-input" v-model="selected2" value="notastock">
													<label class="custom-control-label" for="customRadio11" v-b-tooltip.top="'발주가 있으나 미배치된 재고'">배치필요</label>
												</div>
												<div class="custom-control custom-radio custom-control-inline h-100">
													<input type="radio" id="customRadio13" name="customRadio-2" class="custom-control-input" v-model="selected2" value="stock">
													<label class="custom-control-label" for="customRadio13" v-b-tooltip.top="'일반재고'">재고</label>
												</div>
												<div class="custom-control custom-radio custom-control-inline h-100">
													<input type="radio" id="customRadio14" name="customRadio-2" class="custom-control-input" v-model="selected2" value="astock">
													<label class="custom-control-label" for="customRadio14" v-b-tooltip.top="'발주 배치된 재고'">배치재고</label>
												</div>

												<button type="button" class="btn btn-primary h-100" @click="search">검색</button>
											</div>




											<div class="col-md-12 mt-4" v-if="showlist" style="font-size:80%;">
												<div class="table-responsive rounded mb-3">
													<tableList>
														<template v-slot:header>
															<tr class="ligth ligth-data">
																<th>
																	<div class="checkbox d-inline-block">
																		<input type="checkbox" class="checkbox-input" id="checkbox1" @change="checkallbox" v-model="checkbox1">
																		<label for="checkbox1" class="mb-0"></label>
																	</div>
																</th>
																<th>구분</th>
																<th>박스</th>
																<th>등록일시</th>
																<th>상품정보</th>
																<th>박스바코드</th>
																<th>박스위치</th>
																<th>수량</th>
																<th>배치필요수량</th>
																<th>발주정보</th>
															</tr>
														</template>
														<template v-slot:body>
															<tr v-for="(stocklist,index) in stocklists" :key="index">
																<td>
																	<div class="checkbox d-inline-block">
																		<input type="checkbox" class="checkbox-input" id="checkbox2" @change="checkboxlistfunc" v-model="checkboxlist[index]">
																		<label for="checkbox2" class="mb-0"></label>
																	</div>
																</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)"><div v-bind:class="badgecolor(stocklist,stocklist.stockinfo)">{{changestockinfo(stocklist,stocklist.stockinfo)}}</div></td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{changeboxinfo(stocklist.stockinfo,stocklist.재고박스번호,stocklist.배치박스번호)}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{stocklist.입고일시}}</td>
																<td v-b-modal="modalID1(index)" style="min-width:200px;" @click="modaldata(stocklist)">
																	<div class="d-flex align-items-center">
																			<img :src="stocklist.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
																			<div>
																				{{stocklist.상품명}}
																				<p class="mb-0" style="font-size:120%"><small>{{stocklist.바코드}}</small></p>
																				<p class="mb-0" style="font-size:120%"><small>{{stocklist.SKUID}}</small></p>
																			</div>
																	</div>
																</td>
																<td>{{stocklist.박스바코드}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{changepalletinfo(stocklist.stockinfo,stocklist.재고파렛트번호,stocklist.배치파렛트번호)}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{stocklist.수량}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{stocklist.배치필요수량 ? stocklist.배치필요수량 : 0}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">
																	<div class="d-flex align-items-center">
																		<div v-if="stocklist.배치발주번호.length > 0">
																			<p class="mb-0" style="font-size:120%"><small>{{stocklist.배치발주센터}}</small></p>
																			<p class="mb-0" style="font-size:120%"><small>{{stocklist.배치입고예정일}}</small></p>
																			<p class="mb-0" style="font-size:120%"><small>{{stocklist.배치발주번호}}</small></p>
																		</div>
																		<div v-else>
																			<p class="mb-0" style="font-size:120%;color:red;"><small>{{stocklist.배치필요내역[0].배치필요발주센터}}</small></p>
																			<p class="mb-0" style="font-size:120%;color:red;"><small>{{stocklist.배치필요내역[0].배치필요입고예정일}}</small></p>
																			<p class="mb-0" style="font-size:120%;color:red;"><small>{{stocklist.배치필요내역[0].배치필요발주번호}}</small></p>
																		</div>
																	</div>
																</td>


																		<b-modal ref="bvModal" :id="'modal1'+index" scrollable title="배치필요내역" ok-title="저장" cancel-title="취소" class="detail-modal" @ok="handleOk" @cancel="cancel">
																			<div class="table-responsive">
																				<table id="datatable" class="table data-table table-striped dataTable" >
																				<thead>
																					<tr class="ligth" style="font-size:80%;">
																						<th>
																							<div class="checkbox d-inline-block">
																								<input type="checkbox" class="checkbox-input" id="modalcheck1" @change="checkall" v-model="modalcheck1">
																								<label for="modalcheck1" class="mb-0"></label>
																							</div>
																						</th>
																						<th>상품정보</th>
																						<th>발주번호</th>
																						<th>발주센터</th>
																						<th>입고예정일</th>
																						<th>박스번호</th>
																						<th>박스위치</th>
																					</tr>
																				</thead>
																				<tbody>
																					<tr v-for="c,i3 in stockdetail" :key="i3" role="row" style="font-size:80%;">
																						<td>
																							<div class="checkbox d-inline-block">
																								<input type="checkbox" class="checkbox-input" id="modalcheck2" @change="check" v-model="c.checked">
																								<label for="modalcheck2" class="mb-0"></label>
																							</div>
																						</td>
																						<td style="min-width:200px;">
																							<div class="d-flex align-items-center">
																									<img :src="c.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
																									<div>
																										{{c.상품명}}
																										<p class="mb-0" style="font-size:120%"><small>{{c.바코드}}</small></p>
																										<p class="mb-0" style="font-size:120%"><small>{{c.SKUID}}</small></p>
																									</div>
																							</div>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치발주번호}}</p>
																							<p v-else style="color:red;">{{c.배치필요발주번호}}</p>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치발주센터}}</p>
																							<p v-else style="color:red;">{{c.배치필요발주센터}}</p>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치입고예정일}}</p>
																							<p v-else style="color:red;">{{c.배치필요입고예정일}}</p>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치박스번호}}</p>
																							<p v-else>{{c.재고박스번호}}</p>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치파렛트번호}}</p>
																							<p v-else>{{c.재고파렛트번호}}</p>
																						</td>
																					</tr>
																				</tbody>
																				</table>
																			</div>
																			<div class="mt-4" v-if="showchecklist">
																					<div class="row">
																						<div class="col-md-6 mb-2">
																							<div class="custom-control custom-radio custom-control-inline h-100">
																								<input type="radio" id="customRadio13" name="customRadio-3" class="custom-control-input" v-model="selected3" value="arrangepo" @change="check">
																								<label class="custom-control-label" for="customRadio13" v-b-tooltip.top="'발주내역에 배치'">발주배치 등록/변경</label>
																							</div>
																							<div class="custom-control custom-radio custom-control-inline h-100">
																								<input type="radio" id="customRadio14" name="customRadio-3" class="custom-control-input" v-model="selected3" value="locationchange">
																								<label class="custom-control-label" for="customRadio14" v-b-tooltip.top="'박스변경/위치변경'">재고 변경</label>
																							</div>
																						</div>
																						<div class="col-md-6 mb-2 float-right">
																							<div class="custom-control custom-control-inline h-100 float-right">
																								<button class="btn btn-secondary float-right" type="button" @click="deleteitem">선택삭제</button>
																							</div>
																						</div>

																						<div class="col-md-12 mb-2" v-if="selected3=='arrangepo'">
																							<div class="table-responsive" style="text-align:center;vertical-align:middle;">
																								<table id="datatable" class="table data-table table-striped dataTable">
																								<thead style="font-size:80%;">
																									<tr class="ligth ligth-data">


																										<th style="font-size:115%;vertical-align: middle;">발주센터</th>
																										<th style="font-size:115%;vertical-align: middle;">입고예정일</th>
																										<th style="font-size:115%;vertical-align: middle;">필요수량</th>
																										<th style="font-size:115%;vertical-align: middle;">발주배치</th>
																										<th style="font-size:115%;vertical-align: middle;">배치박스</th>
																										<th style="font-size:115%;vertical-align: middle;">현재배치수량</th>
																										<th style="font-size:115%;vertical-align: middle;">발주번호</th>

																									</tr>
																								</thead>
																								<tbody style="font-size:100%;">
																									<tr v-for="(d,idx) in dfindlists" :key="idx">

																										<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{d.발주센터}}<span class="ml-2" @click="print('printMe4' + idx)"><i class="ri-printer-line" style="font-size:200%"></i></span>
																											<div class="d-none text-center" style="text-align:center;" :id="'printMe4'+idx">
																												<div class="row mb-0" style="text-align:center;font-size:200%">{{업체명}}</div>
																												<div class="row mb-0" style="text-align:center;font-size:200%">{{d.발주센터}}</div>
																												<div class="row mb-0" style="text-align:center;font-size:200%">{{d.입고예정일}}</div>
																												<div class="row mb-0" style="text-align:center;font-size:400%">{{d.배치박스번호}}</div>
																												<div class="row mb-0" style="text-align:center; width: 100%">
																												<barcode :value="업체명.slice(0,2)+'cn'+d.입고예정일.replaceAll('-','').slice(4)+d.배치박스번호" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																												</div>
																											</div>
																										</td>

																										<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{d.입고예정일}}</td>
																										<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{d.발주세부내역.필요수량}}</td>
																										<td style="max-width:50px;overflow:hidden;text-overflow: ellipsis;">
																											<button v-if="d.select.length > 0" v-bind:class="buttoncolor(d.select)" type="button" @click="selectarrange(d,idx)">{{d.select}}</button>
																										</td>
																										<td style="max-width:100px;overflow:hidden;text-overflow: ellipsis;">
																											<div style="display: table; margin-left: auto; margin-right: auto; display: inline-block;">
																											<div class="input-group position-relative">
																												<input style="font-size:200%;text-align:center" :min="0" type="number" class="form-control h-100" placeholder="박스번호" aria-label="" @keyup.enter="changebox($event,d)" v-model="d.배치박스번호">
																												<span v-if="d.newbox" class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-secondary p-2">new</span>
																											</div>
																											<div class="input-group mt-2">
																												<input style="text-align:center" :min="0" type="text" class="form-control" placeholder="박스위치" aria-label="" @keyup.enter="changelocation($event,d)" v-model="d.배치파렛트번호">
																												<div class="input-group-append">
																												</div>
																											</div>
																											</div>

																										</td>
																										<td style="max-width:50px;overflow:hidden;text-overflow: ellipsis;">{{d.발주세부내역.배치수량}}</td>
																										<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{d.발주번호}}</td>

																									</tr>
																								</tbody>
																								</table>
																							</div>
																						</div>



																						<div class="col-md-12 mb-2" v-else>
																							<div class="row mt-3">
																								<div class="col-md-3">
																									<div class="input-group mb-4">
																										<input type="number" class="form-control" placeholder="박스번호" :min="0" aria-label="" @change="boxchangeref" v-model="stockboxnum" style="text-align:center;">
																										<div class="input-group-append">
																										<b-dropdown class="my-class" variant="primary" type="button" text="리스트">
																											<b-dropdown-item @click="boxnumberchange(e)" v-for="e,j2 in stockboxnumbers" :key="j2">{{e}}</b-dropdown-item>
																										</b-dropdown>
																										</div>
																										<span class="ml-2 text-center align-middle" @click="print('printMe5')"><i class="ri-printer-line" style="font-size:200%"></i></span>
																										<div class="d-none text-center" style="text-align:center;" id="printMe5">
																											<div class="row mb-0" style="text-align:center;font-size:200%">{{업체명}}</div>
																											<div class="row mb-0" style="text-align:center;font-size:200%">재고</div>
																											<div class="row mb-0" style="text-align:center;font-size:400%">{{stockboxnum}}</div>
																											<div class="row mb-0" style="text-align:center; width: 100%">
																												<barcode :value="업체명.slice(0,2)+'cn'+'s'+stockboxnum" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																											</div>

																										</div>
																									</div>
																								</div>
																								<div class="col-md-3">
																									<div class="input-group mb-4">
																										<input type="text" class="form-control" placeholder="박스위치" aria-label="" v-model="stockpalletnum"  style="text-align:center;">
																									</div>
																								</div>

																								<div class="col-md-12">
																									<button class="btn btn-primary" type="button" @click="apply">적용</button>
																								</div>
																							</div>
																						</div>


																					</div>
																				</div>
																		</b-modal>

															</tr>
														</template>
													</tableList>



												</div>
												<button type="button" class="btn btn-primary mr-2 mb-4" @click="download">다운로드</button>
												<button type="button" class="btn btn-secondary mr-2 mb-4" @click="deletestock">삭제</button>
											</div>
										</div>
									</b-card>
								</b-card-group>


							</tab-content-item>






							<tab-content-item :active="false" id="contact" aria-labelled-by="contact-tab">

								<form @submit.prevent="submit">
									<div class="row mt-3">
										<div class="col-md-3">
											<div class="input-group mb-4">
												<input type="number" class="form-control" placeholder="출고파렛트번호" aria-label="" @change="palletchange('exp'+exportpalletnum)" v-model="exportpalletnum" style="font-size:200%;text-align:center;">
												<div class="input-group-append">
													<b-dropdown class="my-class" variant="primary" type="button" text="리스트">
														<b-dropdown-item @click="palletchange(b)" v-for="b,j in palletnumbers" :key="j">{{b}}</b-dropdown-item>
													</b-dropdown>
												</div>
												<span class="ml-2 text-center align-middle" @click="print('printMe6')"><i class="ri-printer-line" style="font-size:200%"></i></span>
												<div class="d-none text-center" style="text-align:center;" id="printMe6">
													<div class="row mb-0" style="text-align:center;font-size:200%">{{업체명}}</div>
													<div class="row mb-0" style="text-align:center;font-size:400%">EXP{{exportpalletnum}}</div>
													<div class="row mb-0" style="text-align:center; width: 100%">
														<barcode :value="업체명.slice(0,2)+'cnex'+exportpalletnum" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<input type="text" ref="barcoderegstock" class="form-control" placeholder="박스바코드 스캔" @keyup.enter="boxscan($event)" v-model="scanbox">
											</div>
										</div>
										<div class="col-md-12" v-if="showexportlist">
											<div class="table-responsive rounded mb-3">
												<tableList>
													<template v-slot:header >
														<tr class="ligth ligth-data">
															<th>
																<div class="checkbox d-inline-block">
																	<input type="checkbox" class="checkbox-input" id="checkexboxpall" @change="checkexall" v-model="checkexallbox">
																	<label for="checkexboxpall" class="mb-0"></label>
																</div>
															</th>
															<th>박스번호</th>
															<th>박스바코드</th>
															<th>박스내수량</th>
															<th>발주센터</th>
															<th>입고예정일</th>
															<th>출고파렛트</th>
															<th>Action</th>
														</tr>
													</template>
													<template v-slot:body >
														<tr v-for="(list,index) in exportlists" :key="index" >
															<td>
																<div class="checkbox d-inline-block">
																	<input type="checkbox" class="checkbox-input" id="checkboxp" v-model="list.checked">
																	<label for="checkboxp" class="mb-0"></label>
																</div>
															</td>
															<td>{{list.박스번호}}</td>
															<td>{{list.박스바코드}}</td>
															<td>{{list.박스내수량}}</td>
															<td>{{list.발주센터}}</td>
															<td>{{list.입고예정일}}</td>
															<td>{{exportpalletnum}}</td>
															<td>
																<div class="d-flex align-items-center list-action">
																	<a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
																		href="javascript:void(0)" @click="deletebox(index)"><i class="ri-delete-bin-line mr-0"></i></a>
																</div>
															</td>
														</tr>
													</template>
												</tableList>
											</div>
										</div>
										<div class="col-md-2" v-if="showexportlist">
											<button class="btn btn-primary" type="button" @click="savepallet">저장</button>
											<button class="btn btn-secondary ml-3" type="button" @click="exportpallet">출고처리</button>
										</div>
										<div class="col-md-10" v-if="showexportlist">
											<div class="form-group row">
												<div class="col-2 text-right">Gross Weight(kg)</div>
												<div class="col-2">
													<input type="number" ref="barcoderegstock" class="form-control" placeholder="Gross Weight" v-model="gw">
												</div>
												<div class="col-1 text-right">CBM(m3)</div>
												<div class="col-2">
													<input type="number" ref="barcoderegstock" class="form-control" placeholder="CBM" v-model="cbm">
												</div>
												<div class="col-md-5 mb-2">
													<div class="custom-control custom-radio custom-control-inline h-100">
														<input type="radio" id="customRadio137" name="customRadio-7" class="custom-control-input" v-model="exporttype" value="BOX">
														<label class="custom-control-label" for="customRadio137" v-b-tooltip.top="'박스출고'">박스출고</label>
													</div>
													<div class="custom-control custom-radio custom-control-inline h-100">
														<input type="radio" id="customRadio147" name="customRadio-7" class="custom-control-input" v-model="exporttype" value="PLT">
														<label class="custom-control-label" for="customRadio147" v-b-tooltip.top="'파렛트출고'">파렛트출고</label>
													</div>
												</div>
											</div>
										</div>
									</div>

								</form>
							</tab-content-item>




						</tab-content>





					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required 	} from 'vuelidate/lib/validators';
import axios from 'axios';
import { mapState } from 'vuex';
import * as XLSX from 'xlsx';
import VueBarcode from 'vue-barcode';
import AWS from 'aws-sdk';
import Camera from 'easy-vue-camera';
// import { core } from '../../../config/pluginInit'

export default {
    name:'logisaddimport',
      mixins: [validationMixin],
    validations: {
      customer: { required },
      referenceno: { required },
      },

	mounted(){
		if(this.$store.state.productdb.length > 0){
			this.업체명 = this.$store.state.productdb[0].companyid;
			this.importtext = 'Connected';
		}
		if(this.업체명 != '' && this.importtext == 'Connected'){
			this.openstocklist()
			if(this.$store.state.stock.length > 0){
				this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
				this.업체명 = this.$store.state.stock[0].companyid;
				this.importtext = 'Connected';
				this.palletnumbersupdate()
			}
			this.exportpalletnum = 1
			this.palletchange('exp'+this.exportpalletnum)
		}
		this.importcompanyids()
	},
    data(){
        return{
			폰트사이즈:100,
			openstatus:false,
			editliststart:[],
			editlistend:[],
            manualListStart:[],
            manualListEnd:[],
			수정출력내용:'',

			시작번호:'',
			끝번호:'',
			printboxnums:[],
			라벨간격:'',

			companyids: [],
			enabledetail: true,
			한글표시사항: {},

			clicksavearrange: false,

			errorimgaddress : '',
			albumBucketName : 'elasticbeanstalk-ap-northeast-2-160290560268',
			bucketRegion : 'ap-northeast-2',
			IdentityPoolId : 'ap-northeast-2:19db1347-4000-4e66-973a-e2077f8cad1d',
			errorimg : '',
			picture: '',
			showcamera: false,
            barcode: '',

			scanbox:'',
			exporttype:'PLT',

			입고수량오류:0,
			입고상품오류:0,
			오류입고처리:0,
			오류반품처리:0,
			오류폐기처리:0,
			오류입고대기:0,
			반품폐기완료:0,

			업체검색:'',
			showimporterrorlist : false,
			checkallimporterror1 : false,
			importerrorlists : [],
			importerrorlistsinit : [],

			신규박스번호:'',
			바코드출력:'',
			SKU출력:'',
			출력수량:1,
			출력바코드:'',
			출력SKUID:'',
			출력상품명:'',

            isBarcodeModal: false,
            isManualModal : false,
            barcodeRevisedContent : '',
            barcodeManualContent: '',

			바코드간격:100,
			운송장번호1:'',
			showimportboxlist:false,
			checkimportbox:false,
			importboxlists:[],

			ceoname:'',

			showimporterror:false,
			showimporterror1:false,
			showimporterror2:false,
			importerrorbutton:'입고오류',

			importstock:[],
			showspinner:false,

			output: null,
			selected: 'default',
			selected2: 'stocktotal',
			selected3: 'arrangepo',
			checkpo: false,

			showexportbox:false,
			exportboxnum: '',
			exportstyleon:false,
			exportchange: false,
			exportpalletnumber: '',
			exportboxlists: [],
			exportboxlistsinit: [],
			exportarr: [],
			exportboxnumbers:[],
			regexport:'',
			reqpurchases : [],
			등록상품명: '',

			findlists: [],
			dfindlists: [],
			boxlists: [],
			stockboxlists: [],
			stockarr: [],

			운송장번호:'',
			업체명:'',
            주문번호: '',

			//배치박스(boxlist 정보)

			showfindlist: false,
			showarrangebox: false,
			showstockbox: false,
			stockboxnumbers: [],
			stockchange: false,

			barcoderegist : '',
			regstock : '',
			totalqty: 0,


			skuidvalue: '',
			barcodevalue: '',
			stockboxnumstring: '',
			stockboxnum: '',
			stockpalletnum: '',
			bcvalue: '',
			bctag: 'svg',
			bcoptions: {
				lineColor: '#ff7069',
				fontSize: 50,
				font: 'Courier',
				width: 3,
				height: 60,
				marginBottom: 60,
				format: 'MSI',
				background: '#ccffff'
			},

			cbar: '',
			csku: '',
			cbox: '',
			cabox: '',

			//출고처리
			exportpalletnum: 1,
			palletnumbers:[],
			showexportlist: false,
			checkexallbox:false,
			exportlists: [],
			exportlistsinit: [],
			gw:0,
			cbm:0,

			stocklists:[],
			showlist:false,
			lochange:false,
			stockstyleon: false,

			modalcheck1: false,
			modalcheck: [],
			stockdetail: [],
			stockdetailinit: {},
			stockdetailinitarr: [],
			selectbutton: "선택제품배치",
			changedcnt: 0,
			showchecklist: false,

			checkbox1: false,
			checkboxlist: [],
			checkboxall1: false,

			keyofarr: [],

			delitems:[],

			uploadstatus: 'File upload',
			showpurchaselist:false,
			purchaselists:[],
			purchaselistsinit:[],

			importtext:'Connect',
			currentindex:0,

			currentlevel:0,
			intervalvalue:0,
            printskuenable2: true,
            printkcenable2: false,
            printfoodenable2: false,
			printkcenable:false,
			printfoodenable:false,
			printskuenable:true,
			printbarcodeenable:true,

            option1:[
                  {value: 1, text: 'Test Biller'},
                  ],
                   option2:[
                  {value: 1, text: 'Completed'},
                  {value: 2, text: 'Pending'},
                  ],
                   option3:[
                  {value: 1, text: 'No Text'},
                  {value: 2, text: 'GST @5%'},
                  {value: 2, text: 'VAT @10%'},
                  ],
                   option4:[
                  {value: 1, text: 'Pending'},
                  {value: 2, text: 'Due'},
                  {value: 2, text: 'Paid'},
                  ],
                   customToolbar: [["bold",  "underline","italic","image","code"]]
        }
    },
	components: {
		'barcode': VueBarcode,
		'v-easy-camera' : Camera,
	},
    methods: {
		printmark(){
            if(this.printkcenable || this.printfoodenable){
                return 'height:30px;'
            } else {
                return 'display:none;'
            }
        },
		changecompanyid(e){
			this.ceoname = e.name;
		},
		savecurrentlevel(exportboxlist){
			console.log(exportboxlist.수량)
			this.currentlevel = exportboxlist.수량;
			console.log(this.currentlevel)
		},
		changeqty(exportboxlist){
			console.log(exportboxlist.수량, this.currentlevel)
			this.intervalvalue = exportboxlist.수량 - this.currentlevel
			// console.log(exportboxlist)
			this.totalqty = this.exportboxlists?.reduce((pv,cv)=>{return pv + cv.수량},0) ? this.exportboxlists?.reduce((pv,cv)=>{return pv + cv.수량},0) : 0;
			if(this.purchaselists[exportboxlist.purchaseindex].등록이미지 == exportboxlist.IMGURL
				&& this.purchaselists[exportboxlist.purchaseindex].SKUID == exportboxlist.SKUID
				&& this.purchaselists[exportboxlist.purchaseindex].바코드 == exportboxlist.바코드
				&& this.purchaselists[exportboxlist.purchaseindex].상품명 == exportboxlist.상품명
			){
				// console.log(this.intervalvalue)
				this.purchaselists[exportboxlist.purchaseindex].입고수량 += this.intervalvalue;
			}
		},
		importqtytozero(){
			this.showpurchaselist = false;
			this.purchaselists.forEach(e => {
				e.입고수량 = 0;
			})
			setTimeout(()=>{
				this.showpurchaselist = true;
			},1)
		},
		checkpurchase(purchase,index){
			if(purchase.checked && this.$refs.sidebar1.isOpen){
				this.exportboxlists.unshift({
					IMGURL:purchase.등록이미지,
					SKUID:purchase.SKUID,
					바코드:purchase.바코드.trim(),
					상품명:purchase.상품명,
					수량:purchase.주문수량-purchase.입고수량,
					purchaseindex:index
				})
				this.changeqty(this.exportboxlists[0])
			}
		},
		removeboxnum(){
			if(confirm(this.exportboxnum + '번 박스를 삭제하시겠습니까?')){
				// this.exportboxlists
				console.log('')
			}
		},
		async prints(id) {
			if(!this.시작번호 || !this.끝번호 || this.끝번호 < this.시작번호 || this.printboxnums.length == 0){
				alert('시작번호와 끝번호를 확인해 주세요')
				return
			}
			this.printboxnums.forEach(e => {
				if(!this.exportboxnumbers.includes(e)){
					this.exportboxnumbers.push(e);
				}
			})

			// Pass the element id here
			var options = {
				name:'_blank',
				autoClose: true,
			}
			await this.$htmlToPaper(id,options);
		},
		printboxnumber(){
			this.printboxnums = [];
			if(this.시작번호 && this.끝번호 && this.끝번호 >= this.시작번호){
				for(var i=0;i<this.끝번호-this.시작번호+1;i++){
					this.printboxnums.push((i+1))
				}
				console.log(this.printboxnums)
			}
		},
		importcompanyids(){
			axios.post('/api/getcompanyids',{})
            .then( result => {
				if (result.data.요청결과 == 'success'){
					result.data.result.forEach(e => {
						if(e.auth.showimport || e.auth.showgrowth){
                            if(!this.companyids.find(ele => ele.companyid == e.companyid)){
                                this.companyids.push({
									companyid : e.companyid,
									name : e.name
								});
                            }
                        }
					})
				} else {
					alert(result.data.사유)
				}
            }).catch((e)=>{
                alert('companyid 가져오기 실패 (1413)')
                console.log(e);
                return true
            })
		},
		cameraon(){
			this.showcamera = true;
		},
		cameraAction(opt) {
			if (opt === "start") {
				this.$refs.picpreview.start();
			} else if (opt === "snap") {
				this.$refs.picpreview.snap();

				setTimeout(() => {
				this.processFile(this.picture);
				}, 2000);
			} else if (opt === "stop") {
				this.$refs.picpreview.stop();
			} else if (opt === "close") {
				this.$refs.picpreview.close();
			}
		},
		processFile(blob) {
			// read the files
			var reader = new FileReader();

			if (blob instanceof Blob) {
				reader.readAsArrayBuffer(blob);

				reader.onload = function (event) {
				// blob stuff
				var blob = new Blob([event.target.result]); // create blob...
				window.URL = window.URL || window.webkitURL;
				var blobURL = window.URL.createObjectURL(blob); // and get it's URL

				console.log(blob);

				// helper Image object
				var image = new Image();
				image.src = blobURL;
				image.onload = function () {
					// have to wait till it's loaded
					var canvas = document.createElement("canvas");
					var width = image.width;
					var height = image.height;

					// calculate the width and height, constraining the proportions
					if (width > height) {
					if (width > 100) {
						//height *= max_width / width;
						height = Math.round((height *= 100 / width));
						width = 100;
					}
					} else {
					if (height > 100) {
						//width *= max_height / height;
						width = Math.round((width *= 100 / height));
						height = 100;
					}
					}

					// resize the canvas and draw the image data into it
					canvas.width = width;
					canvas.height = height;
					var ctx = canvas.getContext("2d");
					ctx.drawImage(image, 0, 0, width, height);

					canvas.toBlob(function (blob) {
					const newImg = document.createElement("img");
					const url = URL.createObjectURL(blob);

					console.log(blob);
					//send this blob to API

					newImg.onload = function () {
						// no longer need to read the blob so it's revoked
						URL.revokeObjectURL(url);
					};

					newImg.src = url;
					document.body.appendChild(newImg);
					});
				};
				};
			} else {
				console.log(this.picture);
			}
		},
		upload(){
			AWS.config.update({
			region: this.bucketRegion,
			credentials: new AWS.CognitoIdentityCredentials({
				IdentityPoolId: this.IdentityPoolId
			})
			});

			var s3 = new AWS.S3({
			apiVersion: "2006-03-01",
			params: { Bucket: this.albumBucketName }
			});

			let photoKey = this.업체명 + "_사업자등록증.jpg";
			s3.upload({
				Key: photoKey,
				Body: this.errorimg,
				ACL: 'public-read'
			},(err,data) => {
				if(err){
					console.log(err)
					return alert('There was an error uploading your photo: ', err.message);
				}
				alert('Successfully uploaded photo.');
				console.log(data)
				this.errorimgaddress = data.location
			})
		},
		uploaderrorimage(){
			this.errorimg = this.$refs.imgupload[0].files[0]
			console.log(this.errorimg)
		},
		barcodegap(index,total){
			if(index < total){
				return 'border:0px solid silver;width:300px;text-align: center;margin-bottom:' + this.바코드간격 + 'px'
			} else {
				return 'border:0px solid silver;width:300px;text-align: center;'
			}
		},
		barcodegap2(index,total){
			if(index < total){
				return 'display:flex;flex-direction: column;align-items:center;border:0px solid silver;width:270px;text-align: center;margin-bottom:' + this.바코드간격 + 'px'
			} else {
				return 'display:flex;flex-direction: column;align-items:center;border:0px solid silver;width:270px;text-align: center;'
			}
		},
		equal(k1,k2){
			JSON.stringify(k1) === JSON.stringify(k2)
		},
		async refreshpage(bvModalEvent){
             bvModalEvent.preventDefault()
            this.showimporterrorlist = false;
            var purchase = this.importerrorlists[this.currentindex]
            console.log(purchase)
            if(!this.equal(this.importerrorlistsinit[this.currentindex],purchase)){
                console.log(purchase)
				await this.updatepurchase(purchase)
				this.$nextTick(() => {
					this.showimporterrorlist = true;
					this.$bvModal.hide('modal' + this.currentindex)
				})
            } else {
                console.log(purchase)
                this.showimporterrorlist = true
            }
        },
		async updatepurchase(purchase){
            console.log(purchase)
            var arr = [];
            arr.push(purchase);
            axios.post('/api/logisaddimport/updatepurchase',arr)
            .then( result => {
            if (result.data.요청결과 == '입고처리 완료') {
                this.$store.commit('purchaseupdate', result.data.purchase)
                console.log(result)
                return true
            } else {
                alert('업데이트 실패(579)')
                return true
            }
            }).catch((e)=>{
                alert('업데이트 실패(582)')
                console.log(e);
                return true

            })
		},
		showi(){
            this.showimporterrorlist=false;
            setTimeout(() => {
            this.importerrorlists = this.importerrorlistsinit.filter(e => e.배송상태 === '입고수량오류')
            this.showimporterrorlist = true;
            }, 1);
        },
        showj(){
            this.showimporterrorlist=false;
            setTimeout(() => {
            this.importerrorlists = this.importerrorlistsinit.filter(e => e.배송상태 === '입고상품오류')
            this.showimporterrorlist = true;
            }, 1);
        },
        showl(){
            this.showimporterrorlist=false;
            setTimeout(() => {
            this.importerrorlists = this.importerrorlistsinit.filter(e => e.배송상태 === '오류입고처리')
            this.showimporterrorlist = true;
            }, 1);
        },
        showm(){
            this.showimporterrorlist=false;
            setTimeout(() => {
            this.importerrorlists = this.importerrorlistsinit.filter(e => e.배송상태 === '오류반품처리')
            this.showimporterrorlist = true;
            }, 1);
        },
        shown(){
            this.showimporterrorlist=false;
            setTimeout(() => {
            this.importerrorlists = this.importerrorlistsinit.filter(e => e.배송상태 === '오류폐기처리')
            this.showimporterrorlist = true;
            }, 1);
        },
        showo(){
            this.showimporterrorlist=false;
            setTimeout(() => {
            this.importerrorlists = this.importerrorlistsinit.filter(e => e.배송상태 === '오류입고대기')
            this.showimporterrorlist = true;
            }, 1);
        },
        showp(){
            this.showimporterrorlist=false;
            setTimeout(() => {
            this.importerrorlists = this.importerrorlistsinit.filter(e => e.배송상태 === '반품폐기완료')
            this.showimporterrorlist = true;
            }, 1);
        },
		printbarcoderef(){
			if(this.바코드출력 != ''){
				this.printbarcode(this.바코드출력)
				// this.바코드출력 = ''
			} else {
				if(this.SKU출력 != ''){
					var barcode = this.$store.state.productdb.find(e => e["SKU ID"] == this.SKU출력).바코드
					this.printbarcode(barcode);
					// this.SKU출력 = ''
				}
			}
		},
		printbarcodewith(event){
			if(event.keyCode === 13){
				this.printbarcode(event.target.value)
				// this.바코드출력 = ''
			}
		},
		printSKUwith(event){
			if(event.keyCode === 13){
				var barcode = this.$store.state.productdb.find(e => e["SKU ID"] == event.target.value).바코드
				this.printbarcode(barcode);
				// this.SKU출력 = ''
			}
		},
		printbarcode(barcode, update){
            if (update === 'manual') {
                this.isManualModal = !this.isManualModal
                this.barcodeManualContent =
                    '바코드명 : ' + '\n' +
                    'SKU : ' + '\n' +
                    '1. 상품명 : ' + '\n' +
                    '2. 수입원/판매원 : ' + '\n'+
                    '3. 제조원 : ' + '\n'+
                    '4. 제조국 : ' + '\n'+
                    '5. 내용량 : ' + '\n'+
                    '6. 원료명및성분명(재질) : ' + '\n'+
                    '7. 상품유형 : ' + '\n'+
                    '8. 사용시주의사항 : ' + '\n'+
                    '9. 사용기준 : ' + '\n'
                return false
            }
            if(this.$store.state.productdb.length == 0 || !this.$store.state.productdb.find(e => e.바코드 == barcode)){
				axios.post('/api/logisaddimport/importbarcodeinfo',{barcode:barcode.trim()})
				.then((res) => {
					this.출력SKUID = res.data.res[0]["SKU ID"]
					this.출력바코드 = barcode;
					this.출력상품명 = res.data.res[0]['상품명'];
					this.한글표시사항 = {
						'수입원/판매원':res.data.res[0]['수입원/판매원'],
						제조원:res.data.res[0].제조원,
						제조국:res.data.res[0].제조국,
						내용량:res.data.res[0].내용량,
						'원료명및성분명(재질)':res.data.res[0]['원료명및성분명(재질)'],
						상품유형:res.data.res[0].상품유형,
						사용시주의사항:res.data.res[0].사용시주의사항,
						사용기준:res.data.res[0].사용기준,
					}
                    if (update) {
                        this.barcodeRevisedContent =
                            '1. 상품명 : ' + this.출력상품명 + '\n' +
                            '2. 수입원/판매원 : ' + this.한글표시사항['수입원/판매원'] + '\n' +
                            '3. 제조원 : ' + this.한글표시사항.제조원 + '\n' +
                            '4. 제조국 : ' + this.한글표시사항.제조국 + '\n' +
                            '5. 내용량 : ' + this.한글표시사항.내용량 + '\n' +
                            '6. 원료명및성분명(재질) : ' + this.한글표시사항['원료명및성분명(재질)'] + '\n' +
                            '7. 상품유형 : ' + this.한글표시사항.상품유형 + '\n' +
                            '8. 사용시주의사항 : ' + this.한글표시사항.사용시주의사항 + '\n' +
                            '9. 사용기준 : ' + this.한글표시사항.사용기준
                        this.isBarcodeModal = !this.isBarcodeModal
                    } else {
                        setTimeout(() => {
                            this.print('printMe8');
                        },10)
                    }
				})
				.catch((e) => {alert("importbarcode 실패(1465)");console.log(e);})

				// alert('상품DB에 존재하지 않은 바코드입니다. 정확한 업체ID을 Connect한 후 다시 시도해주세요')
				// return
			} else {
				var ob = this.$store.state.productdb.find(e => e.바코드 == barcode)
				this.출력SKUID = ob['SKU ID'];
				this.출력바코드 = barcode;
				this.출력상품명 = ob['상품명'];
				this.한글표시사항 = {
					'수입원/판매원':ob['수입원/판매원'],
					제조원:ob.제조원,
					제조국:ob.제조국,
					내용량:ob.내용량,
					'원료명및성분명(재질)':ob['원료명및성분명(재질)'],
					상품유형:ob.상품유형,
					사용시주의사항:ob.사용시주의사항,
					사용기준:ob.사용기준,
				}
                if (update === true) {
                    this.barcodeRevisedContent =
                        '1. 상품명 : ' + this.출력상품명 + '\n' +
                        '2. 수입원/판매원 : ' + this.한글표시사항['수입원/판매원'] + '\n' +
                        '3. 제조원 : ' + this.한글표시사항.제조원 + '\n' +
                        '4. 제조국 : ' + this.한글표시사항.제조국 + '\n' +
                        '5. 내용량 : ' + this.한글표시사항.내용량 + '\n' +
                        '6. 원료명및성분명(재질) : ' + this.한글표시사항['원료명및성분명(재질)'] + '\n' +
                        '7. 상품유형 : ' + this.한글표시사항.상품유형 + '\n' +
                        '8. 사용시주의사항 : ' + this.한글표시사항.사용시주의사항 + '\n' +
                        '9. 사용기준 : ' + this.한글표시사항.사용기준
                    this.isBarcodeModal = !this.isBarcodeModal
                } else {
                    setTimeout(() => {
                        this.print('printMe8');
                    },10)
                }
			}

		},
		importboxclass(importbox){
            if(importbox.입고상태 == '배송완료'){
                return 'badge badge-success';
            }
            if(importbox.입고상태 == '신규'){
                return 'badge badge-secondary';
            }
        },
		searchimportboxwith(event){
			if(event.keyCode === 13){
				this.searchimportbox()
			}
		},
		searchimportbox(){
			this.showimportboxlist = false;
			if(this.importboxlists.length > 0){
				if(this.importboxlists.filter(e => e.운송장번호.includes(this.운송장번호1)).length > 0){
					this.importboxlists = this.importboxlists.filter(e => e.운송장번호.includes(this.운송장번호1)).concat(this.importboxlists.filter(e => !e.운송장번호.includes(this.운송장번호1)))
					setTimeout(() => {
						this.showimportboxlist = true;
					},10)
					return
				}
			}
			axios.post('/api/logisaddimport/importboxlist',{
				// companyid : this.업체명,
				운송장번호:this.운송장번호1
			})
			.then((res) => {
				if(res.data.요청결과 != "success"){
					alert("import실패(1098)");
					console.log(res)
					return
				}
				if(res.data.importboxlists.length > 0){
					if(this.운송장번호1 == ''){
						this.importboxlists = res.data.importboxlists;
						this.importboxlists.forEach(e => {
							e.checked = false;
						})
					} else {
						this.운송장번호1 = '';
						this.importboxlists = res.data.importboxlists.concat(this.importboxlists)
						this.importboxlists.forEach(e => {
							e.checked = false;
						})
					}
					this.showimportboxlist = true;
					return
				}
				axios.post('/api/logisaddimport/importpurchase',{
					// companyid : this.업체명,
					운송장번호:this.운송장번호1
				})
				.then((res) => {
					if(!res.data.res){
						alert("import실패(1114)");
						console.log(res)
						return
					}
					if(res.data.res.length == 0){
						if(confirm("No Data!! 박스를 추가하시겠습니까?") == true){
							var obj1 = {}
							var arr1 = [];
							obj1 = {
								companyid:this.$store.state.user.companyid,
								checked:false,
								입고상태 : '신규',
								업체명 : '직접입력',
								입고일시 : this.now(),
								운송장번호 : this.운송장번호1,
								nodata : true,
							}
							arr1.push(obj1)

							this.importboxlists = arr1.concat(this.importboxlists);
							this.showimportboxlist = true;
							this.운송장번호1='';
							return
						} else {
							this.운송장번호1='';
							this.showimportboxlist = true;
							return
						}
					} else {
						var obj = {}
						var arr = [];
						res.data.res.forEach(e => {
							if(arr.length == 0 || arr.filter(ele => ele.업체명 == e.companyid && ele.운송장번호 == e.운송장번호).length == 0){
								obj = {
									companyid:this.$store.state.user.companyid,
									checked:false,
									입고상태 : '신규',
									업체명 : e.companyid,
									입고일시 : this.now(),
									운송장번호 : e.운송장번호,
									nodata : false,
								}
								arr.push(obj)
							}
						})
						this.운송장번호1='';
						this.importboxlists = arr.concat(this.importboxlists);
						this.showimportboxlist = true;
					}

				})
				.catch((e) => {alert("import실패(1143)");console.log(e)})

			})
			.catch((e) => {alert("import실패(1146)");console.log(e)})


		},
		checkallimporterror(){
			if(this.checkallimporterror1){
				this.importerrorlists.forEach(e => {e.checked = true})
			} else {
				this.importerrorlists.forEach(e => {e.checked = false})
			}
		},
		checkallimportbox(){
			if(this.checkimportbox){
				this.importboxlists.forEach(e => {e.checked = true})
			} else {
				this.importboxlists.forEach(e => {e.checked = false})
			}
		},
		deleteimportbox(num){
			axios.post('/api/logisaddimport/deleteimportbox',num)
			.then((res) => {
				if(res.data.요청결과 == "삭제완료"){
					console.log(res.data.result)
				} else {
					console.log(res.data)
				}
			})
			.catch((e) => {alert("업데이트 실패");console.log(e);})
		},
		doimportbox(){
			var arr = [];
			if(this.importboxlists.filter(e => e.checked && e.입고상태 == '신규').length == 0){
				alert('입고상태가 신규인 상품을 선택해 주세요')
				return
			}
			this.importboxlists.filter(e => e.checked && e.입고상태 == '신규').forEach(e => {
				arr.push(e);
			});
			arr.forEach(e => e.입고상태 = '배송완료')
			axios.post('/api/logisaddimport/doimportbox',arr)
			.then((res) => {
				// console.log(res)
				if(res.data.요청결과 == "입고처리 완료"){
					this.$store.commit('purchaseupdate',res.data.purchase)
					this.importboxlists.filter(e => e.checked && e.입고상태 == '신규').forEach(e => {
						e.입고상태 = '배송완료';
					});
					alert("입고처리 완료")

				} else {
					alert("입고처리 실패(1143)")
					console.log(res)
				}
			})
			.catch((e) => {alert("입고처리 실패(1147)");console.log(e)})
		},
		deleteindexfromarray(array,index){
			return array.slice(0,index).concat(array.slice(index+1))
		},
		deletebox(index){
			this.exportlists = this.deleteindexfromarray(this.exportlists,index)
		},
		checkexall(){
			if(this.checkexallbox){
				this.exportlists.forEach(e => {e.checked = true})
			} else {
				this.exportlists.forEach(e => {e.checked = false})
			}
		},
		palletnumbersupdate(){
			this.importstock.forEach(e => {
				if(e.배치파렛트번호 && this.palletnumbers.filter(ele => ele == e.배치파렛트번호).length == 0){
					this.palletnumbers.push(e.배치파렛트번호)
				}
			})
			if(this.palletnumbers.length == 0){
				this.exportpalletnum = 1
			} else {
				this.exportpalletnum = this.palletnumbers[0];
			}
		},
		exportpallet(){
			if(!this.업체명 || this.importtext != 'Connected'){
				alert('업체ID를 먼저 Connect해주세요')
				return
			}
			if(this.exportlists.length == 0){
				alert('출고처리할 항목이 없습니다.')
				return
			}
			if(this.gw == 0 || this.cbm == 0){
				alert('출고처리 전에 Gross Weight와 CBM을 모두 입력해 주세요')
				return
			}
			if(confirm("출고처리 후에는 박스위치 등의 변경이 불가합니다. 최종 출고처리 하시겠습니까?") == false){
				return
			}
			var arr = [];
			var addarr = [];
			var now = this.now()
			this.exportlists.forEach(e => {
				addarr = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(ele => ele.site == 'cn' && ele.배치박스번호 == e.박스번호 && ele.배치발주센터 == e.발주센터 && ele.배치입고예정일 == e.입고예정일) : [];
				arr = arr.concat(addarr)
			})
			var ar = [];
			arr.forEach(e => {
				ar.push(this.deepClone(e))
			})
			ar.forEach(e => {
				e.배치파렛트번호 = 'exp'+this.exportpalletnum;
				e.선적출고일시 = now;
				e.site = 'cn2kr'
			})
			console.log(ar)
			console.log(this.exportlists)
			if(ar.length == 0){
				alert('출고처리할 박스가 없습니다.')
				return
			}
			setTimeout(() => {
				axios.post('/api/addstock/addinvoice',{ // --> invoice 기능 추가
					companyid : this.업체명,
					boxes : this.exportlists,
					pallet : 'exp' + this.exportpalletnum,
					gw : this.gw,
					cbm : this.cbm,
					type : this.exporttype,
					선적출고일시 : now,
					invoice : 0,
				})
				.then((res) => {
					if(!res.data){
						alert("출고실패(1739)");
						console.log(res)
						return
					}
					this.$store.commit('invoiceupdate',res.data.invoice);
					axios.post('/api/addstock/updatestock',ar)
						.then((res1) => {
							if(!res1.data){
								alert("저장실패(1022)");
								console.log(res1)
								return
							}
							this.$store.commit('stockupdate',res1.data.stock);
							this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
							this.palletchange('exp'+this.exportpalletnum);
							alert(res.data.요청결과);
							// alert(res.data.요청결과);
							// console.log(res.data.stock)
						})
						.catch((e) => {alert("저장실패(1031)");console.log(e)})

				})
				.catch((e) => {alert("출고실패(server error)");console.log(e)})

			}, 10);
		},
		savepallet(){
			if(this.exportlists.length == 0){
				alert('파렛트 내에 저장할 박스가 없습니다.')
				return
			}

			//삭제리스트
			var delarr = [];
			this.exportlistsinit.forEach(e => {
				if(!this.exportlists.find(ele => ele.박스바코드 == e.박스바코드)){
					delarr = delarr.concat(this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(element => element.site == 'cn' && element.배치박스번호 == parseInt(e.박스번호) && element.배치발주센터 == e.발주센터 && element.배치입고예정일 == e.입고예정일) : [])
					console.log(this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(element => element.site == 'cn' && element.배치박스번호 == parseInt(e.박스번호) && element.배치발주센터 == e.발주센터 && element.배치입고예정일 == e.입고예정일) : [])
					console.log(delarr)
				}
			})
			delarr.forEach(e => {
				e.배치파렛트번호 = '';
			})

			//추가리스트
			var addarr = [];
			this.exportlists.forEach(e => {
				if(!this.exportlistsinit.find(ele => ele.박스바코드 == e.박스바코드)){
					addarr = addarr.concat(this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(element => element.site == 'cn' && element.배치박스번호 == parseInt(e.박스번호) && element.배치발주센터 == e.발주센터 && element.배치입고예정일 == e.입고예정일) : [])
					console.log(this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(element => element.site == 'cn' && element.배치박스번호 == parseInt(e.박스번호) && element.배치발주센터 == e.발주센터 && element.배치입고예정일 == e.입고예정일) : [])
					console.log(addarr)
				}
			})
			addarr.forEach(e => {
				e.배치파렛트번호 = 'exp'+this.exportpalletnum;
			})


			var ar = [];

			ar = ar.concat(delarr)
			ar = ar.concat(addarr)
			console.log(delarr)
			console.log(addarr)
			console.log(ar)
			console.log(this.exportlistsinit)
			console.log(this.exportlists)
			if(ar.length == 0){
				alert('변경사항이 없습니다.')
				return
			}
			setTimeout(() => {
				axios.post('/api/addstock/updatestock',ar)
				.then((res) => {
					console.log(res)
					if(!res.data){
						alert("저장실패(1022)");
						console.log(res)
						return
					}
					this.$store.commit('stockupdate',res.data.stock);
					console.log(res.data.stock.length)
					this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
					this.palletchange('exp'+this.exportpalletnum);
					console.log(this.importstock.length)
					alert(res.data.요청결과);
					// console.log(res.data.stock)

				})
				.catch((e) => {alert("저장실패(1031)");console.log(e)})
			}, 50);

		},
		palletchange(exportpalletnum){
			this.showexportlist = false;
			this.exportlists = [];
			this.exportlistsinit = [];
			if(this.$store.state.stock.length > 0){
				var obj = {};
				this.$store.state.stock.filter(e => e.site == 'cn' && e.stockinfo == 'Astock' && e.배치파렛트번호 == exportpalletnum).forEach(e => {
					if(this.exportlists.filter(ele => parseInt(ele.박스번호) == e.배치박스번호 && ele.발주센터 == e.배치발주센터 && ele.입고예정일 == e.배치입고예정일).length == 0){
						obj = {
							checked:false,
							박스번호 : e.배치박스번호,
							박스바코드 : this.업체명.slice(0,2)+'cn'+e.배치입고예정일.replaceAll("-","").slice(4)+e.배치박스번호,
							박스내수량 : this.$store.state.stock.filter(ele => ele.site == 'cn' && ele.배치박스번호 == e.배치박스번호 && ele.배치발주센터 == e.배치발주센터 && ele.배치입고예정일 == e.배치입고예정일).length,
							발주센터 : e.배치발주센터,
							입고예정일 : e.배치입고예정일
						}
						this.exportlists.push(obj)
						this.exportlistsinit.push(this.deepClone(obj))
					}
				})
			}
			this.exportlists.sort(function(a,b){
				if (a.박스번호 < b.박스번호){
					return -1;
				}
				if (a.박스번호 > b.박스번호){
					return 1;
				}
				return 0;
			})
			setTimeout(() => {
				console.log(this.exportlists)
				this.showexportlist = true;
				this.showlist = false;
			}, 10);
		},
		boxscan(event){
			this.showexportlist = false;
			if(event.keyCode === 13){
				if(this.exportlists.find(e => e.박스바코드 == event.target.value)){
					alert('이미 등록된 박스입니다.')
					this.scanbox = '';
					this.showexportlist = true;
					this.showlist = false;
					return
				}
				this.exportbox(event.target.value)
			}
		},
		exportbox(boxbarcode){
			var obj = {}
			var vendor = this.업체명;
			if(this.$store.state.stock[0] && vendor != this.$store.state.stock[0].companyid){
				alert('업체명이 일치하지 않습니다.')
				return
			}

			if(boxbarcode.split('cn')[1].length < 5){
				var boxnum = boxbarcode.split('cn')[1];
				// console.log(vendor)
				// console.log(boxnum)
				// console.log(this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn' && e.배치입고예정일.includes(date.slice(0,2) + '-' + date.slice(2,2)) && e.배치박스번호 == boxnum) : [])
				obj = {
					checked:false,
					박스번호 : parseInt(boxnum),
					박스바코드 : boxbarcode,
					박스내수량 : this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn' && e.배치박스번호 == boxnum).length : 0,
					발주센터 : this.$store.state.stock.length > 0 ? this.$store.state.stock.find(e => e.site == 'cn' && e.배치박스번호 == boxnum).배치발주센터 : '',
					입고예정일 : this.$store.state.stock.length > 0 ? this.$store.state.stock.find(e => e.site == 'cn' && e.배치박스번호 == boxnum).배치입고예정일 : '',
				}
				this.exportlists.push(obj)
				console.log(this.exportlists)
				this.scanbox = '';
				setTimeout(() => {
					this.showexportlist = true;
					this.showlist = false;
				},1)
				return
			}

			boxnum = boxbarcode.split('cn')[1].slice(4);
			var date = boxbarcode.split('cn')[1].slice(0,4);
			// console.log(vendor)
			// console.log(boxnum)
			// console.log(this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn' && e.배치입고예정일.includes(date.slice(0,2) + '-' + date.slice(2,2)) && e.배치박스번호 == boxnum) : [])
			obj = {
				checked:false,
				박스번호 : parseInt(boxnum),
				박스바코드 : boxbarcode,
				박스내수량 : this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn' && e.배치입고예정일.includes(date.slice(0,2) + '-' + date.slice(2,2)) && e.배치박스번호 == boxnum).length : 0,
				발주센터 : this.$store.state.stock.length > 0 ? this.$store.state.stock.find(e => e.site == 'cn' && e.배치입고예정일.includes(date.slice(0,2) + '-' + date.slice(2,2)) && e.배치박스번호 == boxnum).배치발주센터 : '',
				입고예정일 : this.$store.state.stock.length > 0 ? this.$store.state.stock.find(e => e.site == 'cn' && e.배치입고예정일.includes(date.slice(0,2) + '-' + date.slice(2,2)) && e.배치박스번호 == boxnum).배치입고예정일 : '',
			}
			this.exportlists.push(obj)
			console.log(this.exportlists)
			this.scanbox = '';
			setTimeout(() => {
				this.showexportlist = true;
				this.showlist = false;
			},1)

		},
		modalID1(index) {
            // console.log(index);
            return 'modal1' + index
        },
		importerrorset(purchase,index){
			this.currentindex = index;
			if(purchase.입고오류유형 == '수량불일치'){
				this.importerrorbutton = '입고오류취소';
				this.showimporterror = true;
				this.showimporterror1 = true;
				this.showimporterror2 = false;
			} else {
				this.importerrorbutton = '입고오류취소';
				this.showimporterror = true;
				this.showimporterror1 = false;
				this.showimporterror2 = true;
			}

		},
		handleOk2(purchase){
			this.showpurchaselist = false;
			this.showimporterror = false;
			this.importerrorbutton = '입고오류';
			if(purchase.입고오류유형 == '수량불일치' && purchase.입고수량 == purchase.주문수량){
				alert('입고수량이 주문수량과 동일한 경우 입고오류를 취소해 주세요')
			}
			setTimeout(() => {
				this.showpurchaselist = true;
			},10)
		},
		changeimporterror(purchase){
			if(purchase.입고오류유형 == '수량불일치'){
				this.showimporterror1 = true;
				this.showimporterror2 = false;
				console.log(purchase.입고수량)
				purchase.배송상태 = '입고수량오류'
				purchase.입고오류내용 = '';
			} else {
				purchase.입고수량 = purchase.주문수량;
				this.showimporterror1 = false;
				this.showimporterror2 = true;
				purchase.배송상태 = '입고상품오류'
			}
		},
		importerror(purchase){
			if(this.importerrorbutton == '입고오류'){
				this.showimporterror = true;
				this.importerrorbutton = '입고오류취소'
			} else {
				this.showimporterror = false;
				this.importerrorbutton = '입고오류';
				purchase.입고오류유형 = '';
				purchase.배송상태 = '입고완료';
				purchase.입고수량 = purchase.주문수량;
				purchase.입고오류내용 = '';
			}
		},
		inputproduct(){
			if(this.purchaselists.filter(e => e.checked).length > 0){
				var arr = [];
				if(this.purchaselists.find(e => e.주문수량 !== e.입고수량 && !e.배송상태.includes('오류'))){
					alert(this.purchaselists.find(e => e.주문수량 !== e.입고수량 && !e.배송상태.includes('오류')).상품명 + '주문의 주문수량과 입고수량이 일치하지 않습니다. 입고수량이 일치하지 않는 경우 입고수량 오류처리를 먼저 진행해 주세요')
					return
				}
				this.purchaselists.filter(e => e.checked).forEach(e => {
					if(!e.배송상태.includes('오류')){
						e.배송상태 = '입고완료';
					}
					e.입고날짜 = this.now();
					arr.push(e)
				})
				axios.post('/api/logisaddimport/updatepurchase',arr)
				.then( result => {
				if (result.data.요청결과 == '입고처리 완료') {
					this.$store.commit('purchaseupdate', result.data.purchase)
					if(arr.length > 0 && arr.filter(e => e.배송상태 == '입고완료').length == arr.length && arr.length == this.purchaselists.length){
						this.deleteimportbox(arr[0].운송장번호)
					}
					alert(result.data.요청결과)
					return
				} else {
					alert('입고처리 실패')
				}
				}).catch((e)=>{
					alert('입고처리 실패(968)')
					console.log(e);
				})
			}

		},
		doimport(){
			if(this.importerrorlists.filter(e => e.checked).length > 0){
				var arr = [];
				this.importerrorlists.filter(e => e.checked).forEach(e => {
					e.배송상태 = '입고완료';
					arr.push(e)
				})
				axios.post('/api/logisaddimport/updatepurchase',arr)
				.then( result => {
				if (result.data.요청결과 == '입고처리 완료') {
					this.$store.commit('purchaseupdate', result.data.purchase)
					if(arr.length > 0 && arr.filter(e => e.배송상태 == '입고완료').length == arr.length && arr.length == this.purchaselists.length){
						this.deleteimportbox(arr[0].운송장번호)
					}
					alert(result.data.요청결과)
				} else {
					alert('입고처리 실패')
				}
				}).catch((e)=>{
					alert('입고처리 실패(968)')
					console.log(e);
				})
			}
		},
		doreturn(){
			if(this.importerrorlists.filter(e => e.checked).length > 0){
				this.showimporterrorlist = false;
				var arr = [];
				this.importerrorlists.filter(e => e.checked).forEach(e => {
					if(e.배송상태.includes('반품') || e.배송상태.includes('폐기')){
						e.배송상태 = '반품폐기완료';
					}
					arr.push(e)
				})
				axios.post('/api/logisaddimport/updatepurchase',arr)
				.then( result => {
				if (result.data.요청결과 == '입고처리 완료') {
					this.$store.commit('purchaseupdate', result.data.purchase)
					this.showimporterrorlist = true;
				} else {
					alert('입고처리 실패(1917)')
				}
				}).catch((e)=>{
					alert('입고처리 실패(1920)')
					console.log(e)
				})
			}
		},
		importtextclass(){
			if(this.importtext == "Connect"){
				return "btn btn-primary mr-2"
			} else {
				return "btn btn-success mr-2"
			}
		},
		importtextchange(event){
			this.importtext = 'Connect';
			if(event.keyCode === 13 && this.업체명 != ''){
				this.importcompanyinfo()
			}
		},
		reqpurchasechange(event){
			if(event.keyCode === 13 && this.업체명 != ''){
				this.importreqpurchases()
			}
		},
		async importreqpurchases(){
			if(this.importtext == 'Connected'){
				if(this.업체명){
					axios.post('/api/reqpurchase/getlist',{
						companyid : this.업체명
					})
					.then( result => {
						if (result.data.요청결과 == 'ok') {
							if(result.data.reqpurchase.length > 0){
								this.reqpurchaselists = result.data.reqpurchase
								// var obj = {}
								result.data.reqpurchase.filter(e => {
									// if(e.진행현황 == '입고완료'){
										this.reqpurchases.push(e)
									// }
								})
								console.log(result.data.reqpurchase)
								console.log(this.reqpurchases)
							} else {
								alert('업체 구매대행요청내역이 없습니다.')
							}

						} else {
							alert('업체 구매대행요청내역 Data Import 실패')
						}
					}).catch((e)=>{
						console.log(e);
						return false
					})
				} else {
					alert('업체ID를 기입해 주세요')
				}
			} else {
				alert('업체ID를 connect해주세요')
				var obj = {
					polist : [],
					productdb : [],
					purchase : [],
					returns : [],
					exportlist : [],
					stock : [],
				}
				this.$store.commit('dbupdate',obj);
				this.importstock = [];
				this.importtext = 'Connect';
				this.업체명 = '';
				this.showpurchaselist = false;
				this.showlist = false;
				this.showfindlist = false;
				this.showarrangebox = false;
				this.showstockbox = false;
				this.showexportbox = false;
				this.showspinner = false;
				this.showimporterror = false;
				this.showchecklist = false;
				return false
			}
		},
		async importcompanyinfo(){
      if(this.$store.state.purchase.length > 0){
        this.$store.commit('initDb')
      }
			if(this.importtext == 'Connect'){
				if(this.업체명){
					if(this.companyids.find(e => e.companyid == this.업체명)){
						this.ceoname = this.companyids.find(e => e.companyid == this.업체명).name;
						this.showspinner = true;
						return axios.post('/api/logisaddimport/importcompanyinfo',{
							companyid : this.업체명
						})
						.then( result => {
							if (result.data.요청결과 == 'success') {
								this.$store.commit('dbupdate', result.data.dbs)
								this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
								this.showspinner = false;
								this.importtext = 'Connected';
								this.reqpurchases = this.$store.state.reqpurchase;
								// this.importreqpurchases();
								this.openstocklist();
								this.palletnumbersupdate();
								return true
							} else {
								alert('업체 Data Import 실패')
								return false
							}
						}).catch((e)=>{
							console.log(e);
							return false
						})
					} else {
						alert('존재하지 않은 업체ID 입니다.')
					}
				} else {
					alert('업체ID를 기입해 주세요')
				}
			} else {
				// var obj = {
				// 	polist : [],
				// 	productdb : [],
				// 	purchase : [],
				// 	returns : [],
				// 	exportlist : [],
				// 	stock : [],
				// }
				// this.$store.commit('dbupdate',obj);
				this.importstock = [];
				this.importtext = 'Connect';
				this.업체명 = '';
				this.showpurchaselist = false;
				this.showlist = false;
				this.showfindlist = false;
				this.showarrangebox = false;
				this.showstockbox = false;
				this.showspinner = false;
				this.showimporterror = false;
				this.showchecklist = false;
				return false
			}
		},
		async print(id) {
			// Pass the element id here
			var options = {
				name:'_blank',
				autoClose: true,
			}
			await this.$htmlToPaper(id,options);
		},
        logislink(purchase){
            var url = "https://trade.1688.com/order/new_step_order_detail.htm?orderId=" + purchase.주문번호 + "&tracelog=20120313bscentertologisticsbuyer&#logisticsTabTitle"
            return url
        },
        logisclass(purchase){
            //발송전 : light
            //발송지연 : warning
            //발송완료 : primary
            //배송지연예정 : danger
            //배송지연 : secondary
            //구매확정 : success
            //입고지연 : warning
            if(purchase.배송상태 == '배송전'){
                return 'badge badge-light';
            }
            if(purchase.배송상태 == '배송지연'){
                return 'badge badge-warning';
            }
            if(purchase.배송상태 == '발송완료'){
                return 'badge badge-primary';
            }
            if(purchase.배송상태 == '배송지연예정'){
                return 'badge badge-danger';
            }
            if(purchase.배송상태 == '배송지연'){
                return 'badge badge-secondary';
            }
            if(purchase.배송상태 == '구매확정'){
                return 'badge badge-success';
            }
            if(purchase.배송상태 == '입고지연'){
                return 'badge badge-warning';
            }
			if(purchase.배송상태 == '입고완료'){
                return 'badge badge-success';
            }
        },
		searchimporterrorwith(event){
			if(event.keyCode === 13){
				this.searchimporterror()
			}
		},
		searchimporterror(){
			this.showimporterrorlist = false;
			// this.업체명 = '';
			// this.importtextchange()
			this.importerrorlists = [];
			axios.post('/api/logisaddimport/importerrorlist',{
				companyid : this.업체검색,
			})
			.then((res) => {
				if(!res.data.res){
					alert("import실패(1835)");
					console.log(res)
					return
				}
				if(res.data.res.length == 0){
					alert('입고오류 리스트가 없습니다.')
				} else {
					console.log(res.data.res)
					this.importerrorlists = res.data.res;
					this.importerrorlists.forEach(e => {
						var a = this.$store.state.productdb.find(ele => ele['SKU ID'] == e.SKUID)
						e.checked = false;
						e.업체ID = e.companyid;
						if(!e.처리방법){
							e.처리방법 = '미확인';
						}
						e.한글표시사항 = {
							'수입원/판매원':a['수입원/판매원'],
							제조원:a.제조원,
							제조국:a.제조국,
							내용량:a.내용량,
							'원료명및성분명(재질)':a['원료명및성분명(재질)'],
							상품유형:a.상품유형,
							사용시주의사항:a.사용시주의사항,
							사용기준:a.사용기준,
						}
						this.importerrorlistsinit.push(this.deepClone(e))
					})

					this.badgeupdate(this.importerrorlists)
					console.log(this.importerrorlists)
					this.checkallimporterror1 = false;
					this.showimporterrorlist = true;
				}

			})
			.catch((e) => {alert("import실패(1862)");console.log(e)})
		},
		badgeupdate(arr){
            this.입고수량오류 = arr.filter(e => e.배송상태 == '입고수량오류').length;
            this.입고상품오류 = arr.filter(e => e.배송상태 == '입고상품오류').length;
            this.오류입고처리 = arr.filter(e => e.배송상태 == '오류입고처리').length;
            this.오류반품처리 = arr.filter(e => e.배송상태 == '오류반품처리').length;
            this.오류폐기처리 = arr.filter(e => e.배송상태 == '오류폐기처리').length;
            this.오류입고대기 = arr.filter(e => e.배송상태 == '오류입고대기').length;
			this.반품폐기완료 = arr.filter(e => e.배송상태 == '반품폐기완료').length;
        },
		searchpurchasewith(event){
			if(event.keyCode === 13){
				this.searchpurchaseref()
			}
		},
		searchpurchaseref(){
			if(this.운송장번호 == '' && this.주문번호 == ''){
                alert('운송장번호나 주문번호를 입력해 주세요')
			} else {
				this.searchpurchase()
			}
		},
		async searchpurchase(){
			this.showpurchaselist = false;
			// this.업체명 = '';
			// this.importtextchange()
			this.purchaselists = [];
			this.purchaselistsinit = [];

			axios.post('/api/logisaddimport/importpurchase',{
				// companyid : this.업체명,
				운송장번호:this.운송장번호,
                주문번호: this.주문번호
			})
			.then( async (res) => {
				if(!res.data.res){
					alert("import실패");
					console.log(res)
					return
				}
				if(res.data.res.length == 0){
					alert('No data')
				} else {
					if(this.업체명 != res.data.res[0].companyid || this.importtext == 'Connect'){
						this.업체명 = res.data.res[0].companyid;
						this.importtext = 'Connect'
						var importstatus = await this.importcompanyinfo();
					} else {
						importstatus = true;
					}
					if(importstatus){
						var errorlist = []
						res.data.res.forEach(e => {
							if(e.SKUID && this.$store.state.productdb && this.$store.state.productdb.find(ele => ele['SKU ID'] == e.SKUID)){
								a = this.$store.state.productdb.find(ele => ele['SKU ID'] == e.SKUID)
								var 요청사항 = '';
								if(e.요청매칭){
									if(e.요청매칭[0].요청내역.barcode){
										요청사항 += '바코드,'
									}
									if(e.요청매칭[0].요청내역.auto){
										요청사항 += '포장방법위임,'
									}
									if(e.요청매칭[0].요청내역.opp){
										요청사항 += 'OPP포장,'
									}
									if(e.요청매칭[0].요청내역.LDPE){
										요청사항 += 'LDPE포장,'
									}
									if(!e.요청매칭[0].요청내역.LDPE && !e.요청매칭[0].요청내역.opp && e.요청매칭[0].요청내역.auto == false){
										요청사항 += '재포장생략,'
									}
									// if(e.요청매칭[0].요청내역.한글표시사항){
									// 	요청사항 += '한글표시,'
									// }
									if(e.요청매칭[0].요청내역.airpacking){
										요청사항 += '에어캡,'
									}
									if(e.요청매칭[0].요청내역.masking){
										요청사항 += '마스킹,'
									}
									if(e.요청매칭[0].요청내역.sticker){
										요청사항 += '원산지,'
									}
									if(e.요청매칭[0].요청내역.stamp){
										요청사항 += '도장,'
									}
									if(e.요청매칭[0].요청내역.tag){
										요청사항 += '택총,'
									}


									if(e.selectpo){
										var 발주내용 = e.selectpo + "/" + this.$store.state.polist.find(ele => ele.발주번호 == e.selectpo).발주센터 + '/' + this.$store.state.polist.find(ele => ele.발주번호 == e.selectpo).입고예정일
										요청사항 += '발주배치(' + 발주내용 + '),'
										if(e.구매요청수량 > this.$store.state.polist.find(ele => ele.발주번호 == e.selectpo).발주세부내역.find(f => f.SKUID.toString() == e.SKUID.toString()).발주수량){
											if(e.재고동봉){
												요청사항 += '나머지수량같이출고,'
											} else {
												요청사항 += '나머지수량재고보관,'
											}
										}
									}
									if(요청사항.length > 1){
										요청사항 = 요청사항.slice(0,요청사항.length-1)
									}
								}
								if(e.요청매칭 && e.요청매칭[0].요청내역.한글표시사항.제품명){
									e.상품명 = e.요청매칭[0].요청내역.한글표시사항.제품명;
								} else {
									e.상품명 = a.상품명;
								}
								e.판매구성수량 = a.판매구성수량;
								e.요청사항 = 요청사항;
								e.출고지 = e.요청매칭[0].요청내역.destination ? e.요청매칭[0].요청내역.destination : "",
								e.출고방법 = e.요청매칭[0].요청내역.destination ? e.요청매칭[0].요청내역.exportType : "",
								e.한글표시사항 = e.요청매칭[0].한글표시사항
								this.purchaselists.push(this.deepClone(e))
								this.purchaselistsinit.push(this.deepClone(e))
							} else {
								요청사항 = '';
								if(e.요청매칭){
									if(e.요청매칭[0].요청내역.barcode){
										요청사항 += '바코드,'
									}
									if(e.요청매칭[0].요청내역.auto){
										요청사항 += '포장방법위임,'
									}
									if(e.요청매칭[0].요청내역.opp){
										요청사항 += 'OPP포장,'
									}
									if(e.요청매칭[0].요청내역.LDPE){
										요청사항 += 'LDPE포장,'
									}
									if(!e.요청매칭[0].요청내역.LDPE && !e.요청매칭[0].요청내역.opp && e.요청매칭[0].요청내역.auto == false){
										요청사항 += '재포장생략,'
									}
									// if(e.요청매칭[0].요청내역.한글표시사항){
									// 	요청사항 += '한글표시,'
									// }
									if(e.요청매칭[0].요청내역.airpacking){
										요청사항 += '에어캡,'
									}
									if(e.요청매칭[0].요청내역.masking){
										요청사항 += '마스킹,'
									}
									if(e.요청매칭[0].요청내역.sticker){
										요청사항 += '원산지,'
									}
									if(e.요청매칭[0].요청내역.stamp){
										요청사항 += '도장,'
									}
									if(e.요청매칭[0].요청내역.tag){
										요청사항 += '택총,'
									}
									if(요청사항.length > 1){
										요청사항 = 요청사항.slice(0,요청사항.length-1)
									}
								}
								if(e.요청매칭 && e.요청매칭[0].요청내역.한글표시사항.제품명){
									e.상품명 = e.요청매칭[0].요청내역.한글표시사항.제품명;
								}
								if(!e.바코드){
									e.바코드 = e.요청매칭[0].바코드
								}
								if(!e.상품명){
									if(e.요청매칭 && e.요청매칭[0].요청내역.한글표시사항.제품명){
										e.상품명 = e.요청매칭[0].요청내역.한글표시사항.제품명;
									} else {
										e.상품명 = e.요청매칭[0].상품명;
									}
								}
								e.판매구성수량 = parseInt(e.요청매칭[0].판매구성수량);
								e.요청사항 = 요청사항;
								e.출고지 = e.요청매칭[0].요청내역.destination ? e.요청매칭[0].요청내역.destination : "",
								e.출고방법 = e.요청매칭[0].요청내역.destination ? e.요청매칭[0].요청내역.exportType : "",
								e.한글표시사항 = e.요청매칭[0].한글표시사항;

								this.purchaselists.push(this.deepClone(e))
								this.purchaselistsinit.push(this.deepClone(e))
							}
						})
						var a = {}
						this.purchaselists.forEach(e => {
							e.checked = false;
							if(!e.입고수량){
								e.입고수량 = e.주문수량;
							}
							if(!e.입고오류유형){
								e.입고오류유형 = '';
							}
							if(!e.입고오류내용){
								e.입고오류내용 = '';
							}
							if(!e.입고오류이미지){
								e.입고오류이미지 = '';
							}
						})
						this.purchaselists.sort(function(a,b){
							if (a.상품명 < b.상품명){
								return 1;
							}
							if (a.상품명 > b.상품명){
								return -1;
							}
							return 0;
						})
						console.log(this.purchaselists)
						if(errorlist.length > 0){
							alert('동일 운송장번호에 '+errorlist[0].companyid+'의 상품 '+errorlist.length+'종이 포함되어 있습니다.')
							console.log(errorlist)
						}
						this.checkboxall1 = false;
						this.showpurchaselist = true
					} else {
						alert("업체 connect 실패(2169)")
					}

				}
				// core.initDataTableOne('#datatable1');
			})
			.catch((e) => {alert("import실패(726)");console.log(e)})
		},
		stockstyle(i2){
			if(this.stockstyleon && i2 == 0){
				return "font-size:200%;text-align:center;background:#fcc2b1;color:red;font-weight:bold"
			} else {
				return "font-size:200%;text-align:center;"
			}
		},
		exportstyle(i2){
			if(this.exoirtstyleon && i2 == 0){
				return "font-size:200%;text-align:center;background:#fcc2b1;color:red;font-weight:bold"
			} else {
				return "font-size:200%;text-align:center;"
			}
		},
		changebox(event,b){
			//b: dfindlist element
			if(event.keyCode === 13){
				this.stockdetail.forEach(e => {
					if(e.checked){
						e.배치박스번호 = b.배치박스번호;
					}
				})
			}
		},
		changelocation(event,b){
			//b: dfindlist element
			if(event.keyCode === 13){
				this.stockdetail.forEach(e => {
					if(e.checked){
						e.배치파렛트번호 = b.배치파렛트번호;
					}
				})
			}
		},
		deletestockdb(arr){
			axios.post('/api/deletestock',arr)
			.then((res) => {
				if(res.data.요청결과 == "삭제완료"){
					alert(res.data.요청결과)
				} else {
					alert("삭제실패")
					console.log(res.data)
				}
			})
			.catch((e) => {alert("업데이트 실패");console.log(e);})
		},
		deletestock(){
			if(this.checkboxlist.filter(e => e == true).length == 0){
				alert("삭제할 제품을 선택해 주세요")
				return
			}
			if(confirm("선택하신 재고를 삭제하시겠습니까?") == true){
				this.showlist = false;
				var arr = [];
				var ar = [];
				var dellist = [];
				this.checkboxlist.forEach((e,i) => {
					if(!e){
						arr.push(this.stocklists[i])
					} else {
						dellist.push(this.stocklists[i])
					}
				})
				this.stocklists = arr;
				for(var i = 0;i<this.checkboxlist.length;i++){
					this.checkboxlist[i] = false;
				}
				setTimeout(() => {
					dellist.forEach(e => {
						e.배치필요내역.forEach(ele => {
							ar.push(ele)
						})
					})
					// console.log(ar)
					// console.log(this.checkboxlist.length, this.stocklists.length, dellist)
					this.deletestockdb(ar)
					this.$store.commit('stockdelete',ar);
					this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];

					this.showlist = true;
					this.showexportlist = false;
				}, 1);

			} else {
				return false
			}

		},
		checkboxlistfunc(){
			this.checkbox1 = false;
		},
		checkboxlistfunc1(){
			this.checkboxall1 = false;
		},
		checkallbox(){
			if(this.checkbox1){
				for(var i = 0;i<this.checkboxlist.length;i++){
					this.checkboxlist[i] = true;
				}
			} else {
				for(i = 0;i<this.checkboxlist.length;i++){
					this.checkboxlist[i] = false;
				}
			}
			// console.log(this.checkboxlist)
		},
		checkallbox1(){
			if(this.checkboxall1){
				this.purchaselists.forEach(e => {
					e.checked = true;
				})
			} else {
				this.purchaselists.forEach(e => {
					e.checked = false;
				})
			}
			// console.log(this.checkboxlist)
		},
		buttoncolor(selectbutton){
			if(selectbutton == '선택제품배치'){
				return "btn btn-success"
			} else {
				return "btn btn-secondary"
			}
		},
		modaldata(stocklist){
			this.stockdetail = [];
			this.stockdetailinit = {};
			this.stockdetailinitarr = [];
			var changedpo = false;
			for (var i=0;i<stocklist.배치필요내역.length;i++){
				if(stocklist.배치발주번호){
					changedpo = true;
				}
				var e = {
					companyid:stocklist.companyid,
					_id: stocklist.배치필요내역[i]._id,
					stockinfo:stocklist.stockinfo,
					등록이미지:stocklist.등록이미지,
					바코드:stocklist.바코드,
					상품명:stocklist.상품명,
					SKUID:stocklist.SKUID,

					배치박스번호:stocklist.배치박스번호,
					배치발주번호:stocklist.배치발주번호,
					배치발주센터:stocklist.배치발주센터,
					배치일시:stocklist.배치일시,
					배치입고예정일:stocklist.배치입고예정일,
					배치파렛트번호:stocklist.배치파렛트번호,
					박스바코드:stocklist.박스바코드,

					재고박스번호:stocklist.재고박스번호,
					재고파렛트번호:stocklist.재고파렛트번호,

					배치필요발주번호:stocklist.배치필요내역[i].배치필요발주번호,
					배치필요발주센터:stocklist.배치필요내역[i].배치필요발주센터,
					배치필요입고예정일:stocklist.배치필요내역[i].배치필요입고예정일,

					checked:false,
					changedpo:changedpo,
					changedstock:false
				}
				this.stockdetail.push(this.deepClone(e))
				this.stockdetailinitarr.push(this.deepClone(e))
				if(this.stockdetailinit.stockinfo === undefined){
					this.stockdetailinit = this.deepClone(e);
				}
				// console.log(this.stockdetail)
			}

			if(this.stockdetail[0].배치필요발주번호 || this.stockdetail[0].배치발주번호){
				this.dfindlistinit(this.stockdetail[0].바코드);
			}

			setTimeout(() => {
				this.$nextTick(() => {
					if(this.stockdetailinit.재고박스번호>0){
						this.stockboxnum = parseInt(this.stockdetailinit.재고박스번호);
						this.stockpalletnum = this.stockdetailinit.재고파렛트번호;
					}
				})
			}, 10);

		},
		dfindlistinit(barcode){

			var result = this.openfindlist(barcode)
			// console.log(result)
			if(result.res){
				this.dfindlists = result.findlists;
				this.dfindlists.forEach(ele => {
					ele.select = '선택제품배치';
				})
			} else {
				if(!result.res){
					this.dfindlists = [];
				}
			}
		},
		toggle(a){
			a.checked = !a.checked;
		},
		cancel(){
			this.$nextTick(() => {
				this.stockdetail = [];
				this.stockdetailinit = {};
				this.stockdetailinitarr = [];
				this.showchecklist = false;
				this.modalcheck1 = false;
				// this.$bvModal.hide('modal');
			})
		},
		handleOk() {
			this.handleSubmit();
		},
		handleSubmit() {
			var stt = false;
			this.stockdetail.forEach(e => {
				if(e.재고박스번호 == 0 && e.배치박스번호 == 0){
					stt = true;
				}
			})
			if(stt){
				alert('배치취소한 제품은 재고변경에서 박스번호를 지정해 주세요')
			} else {
				var obj = {};
				var arr = [];

				if(this.delitems.length > 0){
					axios.post('/api/deletestock',this.delitems)
					.then((res) => {
						if(!res.data.result){
							alert("삭제실패");
							console.log(res)
							return
						}
						this.$store.commit('stockdelete',this.delitems);
						this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
						this.delitems = [];
						this.search();
						// console.log(res)
					})
					.catch((e) => {alert("삭제실패");console.log(e)})
				}



				this.stockdetail.forEach((e,i) => {
					console.log(this.objequal(this.stockdetail[i], this.stockdetailinitarr[i]))
					console.log(this.stockdetail[i])
					console.log(this.stockdetailinitarr[i])
					if(!this.objequal(this.stockdetail[i], this.stockdetailinitarr[i])){
						obj = {
							companyid : e.companyid,
							_id : e._id,
							재고박스번호: parseInt(e.재고박스번호),
							재고파렛트번호: e.재고파렛트번호,
							배치일시: e.배치일시,
							배치발주번호: e.배치발주번호,
							배치박스번호: parseInt(e.배치박스번호),
							배치파렛트번호: e.배치파렛트번호,
							배치발주센터: e.배치발주센터,
							배치입고예정일: e.배치입고예정일,
							stockinfo: e.stockinfo,
							site:'cn'
						}
						arr.push(obj);
					}
				})
				console.log(arr)
				if(arr.length > 0){
					axios.post('/api/addstock/updatestock',arr)
					.then((res) => {
						if(!res.data){
							alert("등록실패");
							console.log(res)
							return
						}
						alert(res.data.요청결과);
						// console.log(res.data.stock)
						this.$store.commit('stockupdate',res.data.stock);
						// console.log(res.data.stock.length)
						// console.log(this.$store.state.stock.filter(e => e.site == 'cn'))
						this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : []
						this.search();
					})
					.catch((e) => {alert("등록실패");console.log(e)})
				}


				this.cancel();

			}

		},
		apply(){
			if(this.stockdetailinit.stockinfo == "Astock"){
				this.stockdetail.forEach(e => {
					if(e.checked){
						e.stockinfo = "stock";

						e.배치발주번호 = "";
						e.배치발주센터 = "";
						e.배치입고예정일 = "";
						e.배치일시 = "";
						e.배치박스번호 = 0;
						e.배치파렛트번호 = "";

						e.재고박스번호 = parseInt(this.stockboxnum);
						e.재고파렛트번호 = this.stockpalletnum;

						e.배치필요발주번호 = this.stockdetailinit.배치발주번호;
						e.배치필요발주센터 = this.stockdetailinit.배치발주센터;
						e.배치필요입고예정일 = this.stockdetailinit.배치입고예정일;

						e.changedstock = true;
						e.changedpo = false;
					}
				})
			} else {
				this.stockdetail.forEach(e => {
					if(e.checked){
						if(e.stockinfo != 'stock' || e.재고박스번호 != this.stockboxnum || e.재고파렛트번호 != this.stockpalletnum){
							e.stockinfo = 'stock';

							e.배치발주번호 = '';
							e.배치발주센터 = '';
							e.배치입고예정일 = '';
							e.배치일시 = '';
							e.배치박스번호 = 0;
							e.배치파렛트번호 = '';

							e.재고박스번호 = parseInt(this.stockboxnum);
							e.재고파렛트번호 = this.stockpalletnum;

							e.배치필요발주번호 = this.stockdetailinit.배치필요발주번호;
							e.배치필요발주센터 = this.stockdetailinit.배치필요발주센터;
							e.배치필요입고예정일 = this.stockdetailinit.배치필요입고예정일;

							e.changedstock = true;
							e.changedpo = false;
						}
					}
				})
			}
		},
		selectarrange(a,i){
			// console.log(a)
			// console.log(this.stockdetail)
			// console.log(this.stockdetailinit)
			this.checkcnt = this.stockdetail.filter(e => e.checked).length;

			if(a.select == '선택제품배치'){
				if(this.stockdetail.filter(e => e.checked && e.stockinfo == 'Astock').length > 0){
					alert('이미 발주 배치된 제품이 포함되어 있습니다.')
				} else {
					//a : dfindlist element
					var now = this.now()
					if(a.발주세부내역.필요수량 >= this.checkcnt){
						a.발주세부내역.배치수량 = a.발주세부내역.배치수량 + this.checkcnt;
						a.발주세부내역.필요수량 = a.발주세부내역.필요수량 - this.checkcnt;

						this.stockdetail.forEach(e => {
							if(e.checked){
								e.stockinfo = 'Astock';

								e.배치발주번호 = a.발주번호;
								e.배치발주센터 = a.발주센터;
								e.배치입고예정일 = a.입고예정일;
								e.배치일시 = now;
								e.배치박스번호 = parseInt(a.배치박스번호);
								e.배치파렛트번호 = a.배치파렛트번호;

								e.재고박스번호 = 0;
								e.재고파렛트번호 = '';

								e.배치필요발주번호 = '';
								e.배치필요발주센터 = '';
								e.배치필요입고예정일 = '';

								e.changedpo = true;
								e.changedstock = false;
							}
						})



						this.dfindlists.forEach((e,index) => {
							if(i == index){
								e.select = '배치취소';
							} else {
								e.select = '';
							}
						})

					} else {
						if(confirm('해당 발주서의 필요수량보다 많습니다. 필요수량만큼 배치하시겠습니까?')){

							this.modalcheck1 = false;
							var cnt = 0
							this.stockdetail.forEach(e => {
								if(cnt < a.발주세부내역.필요수량){
									if(e.checked){
										e.checked = true;
										e.stockinfo = 'Astock';

										e.배치발주번호 = a.발주번호;
										e.배치발주센터 = a.발주센터;
										e.배치입고예정일 = a.입고예정일;
										e.배치일시 = now;
										e.배치박스번호 = parseInt(a.배치박스번호);
										e.배치파렛트번호 = a.배치파렛트번호;

										e.재고박스번호 = 0;
										e.재고파렛트번호 = '';

										e.배치필요발주번호 = '';
										e.배치필요발주센터 = '';
										e.배치필요입고예정일 = '';

										e.changedpo = true;
										e.changedstock = false;
									}
								} else {
									e.checked = false;
								}
								cnt++
							})
							this.dfindlists.forEach((e,index) => {
								if(i == index){
									e.select = '배치취소';
								} else {
									e.select = '';
								}
							})
							a.발주세부내역.배치수량 = a.발주세부내역.필요수량;
							a.발주세부내역.필요수량 = 0;
						}
					}
				}


			} else {
				if(a.select == '배치취소'){
					if(a.발주세부내역.배치수량 >= this.checkcnt){
						a.발주세부내역.배치수량 = a.발주세부내역.배치수량 - this.checkcnt;
						a.발주세부내역.필요수량 = a.발주세부내역.필요수량 + this.checkcnt;
						if(this.stockdetailinit.stockinfo == "Astock"){
							this.stockdetail.forEach(e => {
								if(e.checked){
									e.stockinfo = "stock";

									e.배치발주번호 = "";
									e.배치발주센터 = "";
									e.배치입고예정일 = "";
									e.배치일시 = "";
									e.배치박스번호 = 0;
									e.배치파렛트번호 = "";

									e.재고박스번호 = 0;
									e.재고파렛트번호 = "";

									e.배치필요발주번호 = this.stockdetailinit.배치발주번호;
									e.배치필요발주센터 = this.stockdetailinit.배치발주센터;
									e.배치필요입고예정일 = this.stockdetailinit.배치입고예정일;

									e.changedpo = false;
									e.changedstock = false;
								}
							})
						} else {
							this.stockdetail.forEach(e => {
								if(e.checked){
									e.stockinfo = this.stockdetailinit.stockinfo;

									e.배치발주번호 = this.stockdetailinit.배치발주번호;
									e.배치발주센터 = this.stockdetailinit.배치발주센터;
									e.배치입고예정일 = this.stockdetailinit.배치입고예정일;
									e.배치일시 = this.stockdetailinit.배치일시;
									e.배치박스번호 = parseInt(this.stockdetailinit.배치박스번호);
									e.배치파렛트번호 = this.stockdetailinit.배치파렛트번호;

									e.재고박스번호 = parseInt(this.stockdetailinit.재고박스번호);
									e.재고파렛트번호 = this.stockdetailinit.재고파렛트번호;

									e.배치필요발주번호 = this.stockdetailinit.배치필요발주번호;
									e.배치필요발주센터 = this.stockdetailinit.배치필요발주센터;
									e.배치필요입고예정일 = this.stockdetailinit.배치필요입고예정일;

									e.changedpo = false;
									e.changedstock = false;
								}
							})
						}

						this.dfindlists.forEach(e => {
							e.select = '선택제품배치'
						})
					} else {
						alert('배치취소할 제품 수량을 확인해주세요.')
					}
				}
			}
		},
		checkall(){
			this.showchecklist = false;
			if(this.modalcheck1){
				this.stockdetail.forEach(e => {
					e.checked = true;
				})
				this.check()
			} else {
				this.stockdetail.forEach(e => {
					e.checked = false;
				})
			}
		},
		check(){
			// console.log(this.stockdetail)
			this.showchecklist = false;
			if(this.selected3 == 'arrangepo'){
				if(this.stockdetail.filter(e => e.checked).length > 0){
					if(this.stockdetail.filter(e => e.checked && e.changedpo && e.배치발주번호 ==  this.stockdetail.filter(ele => ele.checked)[0].배치발주번호).length == this.stockdetail.filter(e => e.checked).length){
						this.dfindlistinit(this.stockdetail[0].바코드);
						setTimeout(() => {
							this.stockdetail.forEach(e => {
								this.dfindlists.forEach(ele => {
									if(ele.발주번호 == e.배치발주번호 && this.stockdetailinit.stockinfo == "stock"){
										ele.발주세부내역.배치수량++;
										ele.발주세부내역.필요수량--;
									}
									if(ele.발주번호 == this.stockdetail.filter(element => element.checked)[0].배치발주번호){
										ele.select = '배치취소';
									} else {
										ele.select = '';
									}
								})
							})
						}, 5);
					} else {
						if(this.stockdetail.filter(e => e.checked && e.changedpo).length < this.stockdetail.filter(e => e.checked).length && this.stockdetail.filter(e => e.checked && e.changedpo).length > 0){
							alert("동일 상태의 상품만 동시 선택이 가능합니다.a")
							setTimeout(() => {
								this.stockdetail.forEach(e => {
									e.checked = false;
								})
								this.modalcheck1 = false;
							}, 10);

						} else {
							if(this.stockdetail.filter(e => e.changedpo).length > 0){
								if(this.stockdetail.filter(e => e.checked && e.배치발주번호 == this.stockdetail.filter(ele => ele.checked)[0].배치발주번호).length < this.stockdetail.filter(e => e.checked).length){
									alert("동일 상태의 상품만 동시 선택이 가능합니다.b")
									setTimeout(() => {
										this.stockdetail.forEach(e => {
											e.checked = false;
										})
										this.modalcheck1 = false;
									}, 10);
								} else {
									//dfindlist 배치수량/필요수량 조정
									this.dfindlistinit(this.stockdetail[0].바코드);
									this.stockdetail.forEach(e => {
										if(e.changedpo){
											setTimeout(() => {
												this.dfindlists.forEach(ele => {
													if(ele.발주번호 == e.배치발주번호 && this.stockdetailinit.stockinfo == "stock"){
														ele.발주세부내역.배치수량++;
														ele.발주세부내역.필요수량--;
													}
												})
											}, 5);
										}
									})
								}

							} else {
								this.dfindlistinit(this.stockdetail[0].바코드);
								this.dfindlists = this.dfindlists.filter(e => e.발주세부내역.필요수량 > 0)
							}
						}
					}
				}
				setTimeout(() => {
					if(this.stockdetail.filter(e => e.checked).length == 0){
						this.showchecklist = false;
					} else {
						if(this.dfindlists.length > 0){
							this.showchecklist = true;
						} else {
							alert('배치할 발주내역이 없습니다.')
							this.selected3 = 'locationchange';
							this.showchecklist = true;
						}
					}
				}, 10);
			} else {
				if(this.stockdetail.filter(e => e.checked).length > 0){
					this.showchecklist = true;
				} else {
					this.showchecklist = false;
				}
			}
			// console.log(this.dfindlists)
		},
		search(){
			if(this.importtext == 'Connect'){
				alert('업체ID를 입력 후 Connect를 해주세요')
				return
			}
			if(this.importstock.length == 0){
				console.log(this.업체명)
				console.log(this.importtext)
				console.log(this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn').length : 0)
				alert('등록된 재고가 없습니다.')
				return
			}
			this.showfindlist = false;
			this.showstockbox = false;
			this.checkboxlist = [];
			this.openstocklist();
			if(this.selected2 == 'notastock'){
				this.notastocks()
			}
			if(this.selected2 == 'stock'){
				this.stocks()
			}
			if(this.selected2 == 'astock'){
				this.astocks()
			}
			if(this.selected2 == 'stocktotal'){
				this.stocktotals()
			}
		},
		notastocks(){
			this.showlist = false;
			this.checkboxlist = [];
			this.stocklists = [];
			this.parsestock();
			var arr = [];
			setTimeout(() => {
				if(this.stocklists.length > 0){
					this.stocklists.forEach(e => {
						if(e.stockinfo == "stock") {
							if(e.배치필요내역[0].배치필요발주번호 != ''){
								arr.push(e)
							}
						}
					})
					this.stocklists = arr;
					for(var i = 0;i<this.stocklists.length;i++){
						this.checkboxlist.push(false)
					}
					this.showlist = true;
					this.showexportlist = false;
				}
			}, 50);
		},
		stocks(){
			this.showlist = false;
			this.checkboxlist = [];
			this.stocklists = [];
			this.parsestock();
			var arr = [];
			setTimeout(() => {
				if(this.stocklists.length > 0){
					this.stocklists.forEach(e => {
						if(e.stockinfo == "stock") {
							if(e.배치필요내역[0].배치필요발주번호 == ''){
								arr.push(e)
							}
						}
					})
					this.stocklists = arr;
					for(var i = 0;i<this.stocklists.length;i++){
						this.checkboxlist.push(false)
					}
					this.showlist = true;
					this.showexportlist = false;
				}
			}, 50);
		},
		astocks(){
			this.showlist = false;
			this.checkboxlist = [];
			this.stocklists = [];
			this.parsestock();
			var arr = [];
			setTimeout(() => {
				if(this.stocklists.length > 0){
					this.stocklists.forEach(e => {
						if(e.stockinfo == "Astock") {
							arr.push(e)
						}
					})
					this.stocklists = arr;
					for(var i = 0;i<this.stocklists.length;i++){
						this.checkboxlist.push(false)
					}
					this.showlist = true;
					this.showexportlist = false;
				}
			}, 50);
		},
		stocktotals(){
			this.showlist = false;
			this.checkboxlist = [];
			this.stocklists = [];
			this.parsestock()
			setTimeout(() => {
				if(this.stocklists.length > 0){
					for(var i = 0;i<this.stocklists.length;i++){
						this.checkboxlist.push(false)
					}
					// console.log(this.stocklists)
					// console.log(this.checkboxlist)
					this.showlist = true;
					this.showexportlist = false;
				}
			}, 50);
		},
		parsestock(){
			// console.log(this.importstock)
			this.stocklists = [];
			var arr = [];
			var obj = {};
			this.importstock.forEach(e => {
				arr.push(this.deepClone(e))
			})
			if(this.cbox.length > 0){
				arr = [];
				this.importstock.filter(e => e.재고박스번호 == this.cbox).forEach(e => {
					arr.push(this.deepClone(e))
				})
			}
			if(this.cabox.length > 0){
				arr = [];
				this.importstock.filter(e => e.배치박스번호 == this.cabox).forEach(e => {
					arr.push(this.deepClone(e))
				})
			}
			if(this.cbar.length > 0){
				arr = [];
				this.importstock.filter(e => e.바코드 == this.cbar).forEach(e => {
					arr.push(this.deepClone(e))
				})
			}
			if(this.csku.length > 0){
				arr = [];
				this.importstock.filter(e => e.SKUID == this.csku).forEach(e => {
					arr.push(this.deepClone(e))
				})
			}
			if(arr.length == 0){
				return false;
			}
			arr.forEach(e => {
				obj = this.deepClone(e);
				var inc = false;
				this.stocklists.forEach(ele => {
					if(this.objequal(ele,obj)){
						ele.수량++;
						ele.배치필요내역.push({
							_id:e._id,
							배치필요발주번호:'',
							배치필요입고예정일:'',
							배치필요발주센터:'',
							상품명:e.상품명,
							바코드:e.바코드,
							SKUID:e.SKUID
						});
						inc = true;
					}
				})
				if(!inc){
					obj.배치필요내역 = [];
					obj.배치필요내역.push({
						_id:e._id,
						배치필요발주번호:'',
						배치필요입고예정일:'',
						배치필요발주센터:'',
						상품명:e.상품명,
						바코드:e.바코드,
						SKUID:e.SKUID
					})
					obj.수량 = 1;
					this.stocklists.push(obj);
				}

			})
			this.stocklists.sort(function(a,b){
				if (a.배치박스번호 < b.배치박스번호){
					return 1;
				}
				if (a.배치박스번호 > b.배치박스번호){
					return -1;
				}
				return 0;
			})
			this.stocklists.sort(function(a,b){
				if (a.재고박스번호 < b.재고박스번호){
					return 1;
				}
				if (a.재고박스번호 > b.재고박스번호){
					return -1;
				}
				return 0;
			})
			this.stocklists.sort(function(a,b){
				if (a.배치박스번호 < b.배치박스번호){
					return 1;
				}
				if (a.배치박스번호 > b.배치박스번호){
					return -1;
				}
				return 0;
			})

			this.stocklists.sort(function(a,b){
				if (a.바코드 < b.바코드){
					return 1;
				}
				if (a.바코드 > b.바코드){
					return -1;
				}
				return 0;
			})

			this.stocklists.sort(function(a,b){
				if (a.stockinfo < b.stockinfo){
					return -1;
				}
				if (a.stockinfo > b.stockinfo){
					return 1;
				}
				return 0;
			})
			// console.log(this.stocklists)
			var r = [];
			this.stocklists.forEach(e => {
				e.등록이미지 = this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).등록이미지;
				// e.check = false;

				if(e.stockinfo == "Astock"){
					e.배치수량 = e.수량;
					e.박스바코드 = this.업체명.slice(0,2)+'cn'+e.배치입고예정일.replaceAll("-","").slice(4)+e.배치박스번호;
				} else {
					e.배치수량 = 0;
					e.박스바코드 = this.업체명.slice(0,2)+'cn'+'s'+e.재고박스번호;
				}

				if(e.stockinfo == 'stock'){
					if(r.length == 0 || !r.find(ele => ele.발주세부내역.바코드 == e.바코드)){
						var result = this.openfindlist(e.바코드)
						if(result.res){
							r = r.concat(result.findlists.filter(e => e.발주세부내역.필요수량 > 0))
						}
					}
				}
			})

			// var cnt = 0;
			// r.forEach(e => {
			// 	this.stocklists.filter(ele => ele.바코드 == e.발주세부내역.바코드 && ele.stockinfo == 'stock').some(ele => {
			// 		if(e.발주세부내역.필요수량 > cnt){
			// 			ele.배치필요내역.filter(element => element.배치필요발주번호 == '').some(f => {
			// 				f.배치필요발주번호 = e.발주번호;
			// 				f.배치필요입고예정일 = e.입고예정일;
			// 				f.배치필요발주센터 = e.발주센터;
			// 				return true;
			// 			})
			// 			cnt++
			// 		} else {
			// 			cnt = 0;
			// 			return true
			// 		}
			// 	})
			// })

			var cnt = 0;
			var remain = 0;
			r.forEach(e => {
				remain = e.발주세부내역.필요수량;
				this.stocklists.filter(ele => ele.stockinfo == 'stock' && e.발주세부내역.바코드 == ele.바코드).forEach(ele => {
					ele.배치필요내역.filter(element => element.배치필요발주번호 == '').some(element => {
						if(remain > 0){
							element.배치필요발주번호 = e.발주번호;
							element.배치필요입고예정일 = e.입고예정일;
							element.배치필요발주센터 = e.발주센터;
							remain--
							cnt++
						} else {
							return true
						}
					})
					if(ele.배치필요수량){
						ele.배치필요수량 += cnt;
						cnt = 0;
					} else {
						ele.배치필요수량 = cnt;
						cnt = 0;
					}
				})
			})





			// var po = [];
			// this.$store.state.polist.forEach(e => {
			// 	po.push(this.deepClone(e))
			// });
			// //발주센터로 먼저 정렬
			// po.sort(function(a,b){
			// 	if (a.발주센터 < b.발주센터){
			// 		return -1;
			// 	}
			// 	if (a.발주센터 > b.발주센터){
			// 		return 1;
			// 	}
			// 	return 0;
			// })
			// //입고예정일순으로 최종 정렬
			// po.sort(function(a,b){
			// 	return new Date(b.입고예정일) - new Date(a.입고예정일);
			// })

			// po.forEach(e => {
			// 	if(!e.발주상태.includes("거래명세서") && !e.발주상태.includes("발주취소")) {
			// 		e.발주세부내역.forEach(ele => {
			// 			if(this.$store.state.exportlist.length > 0){
			// 				if(this.$store.state.exportlist.filter(element => element.발주번호 == e.발주번호 && element.바코드 == ele.바코드).length > 0){
			// 					return
			// 				}
			// 			}
			// 			ele.필요수량 = parseInt(ele.확정수량);

			// 			if (this.stocklists.length > 0){
			// 				this.stocklists.filter(element => element.바코드 == ele.바코드 && element.배치발주번호 == e.발주번호).forEach(element => {
			// 					ele.필요수량 -= element.수량;
			// 					element.배치수량 = element.수량;
			// 				})
			// 				this.stocklists.filter(element => element.바코드 == ele.바코드 && element.stockinfo == 'stock').forEach(element => {
			// 					if(ele.바코드 == 'R010320100036'){
			// 						// console.log(e)
			// 						console.log(ele)
			// 						console.log(element)
			// 					}

			// 					if(ele.필요수량 > 0){
			// 						if(ele.바코드 == 'R010320100036'){
			// 							// console.log(e)
			// 							console.log(ele.필요수량)
			// 							console.log(e.발주번호)
			// 						}
			// 						if(ele.필요수량 > element.수량 - element.배치수량){
			// 							element.배치필요내역.some(f => {
			// 								f.배치필요발주번호 = e.발주번호;
			// 								f.배치필요입고예정일 = e.입고예정일;
			// 								f.배치필요발주센터 = e.발주센터;
			// 								element.배치수량++
			// 								element.필요수량--
			// 								if(element.수량 - element.배치수량 == 0){
			// 									return true
			// 								}
			// 							})
			// 						} else {
			// 							element.배치필요내역.some(f => {
			// 								f.배치필요발주번호 = e.발주번호;
			// 								f.배치필요입고예정일 = e.입고예정일;
			// 								f.배치필요발주센터 = e.발주센터;
			// 								element.배치수량++
			// 								ele.필요수량--
			// 								if(ele.필요수량 == 0){
			// 									return true
			// 								}
			// 							})
			// 						}
			// 					}
			// 				})
			// 			}
			// 		})
			// 	}
			// })
			// console.log(this.stocklists)
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		showbox(){
			if (this.selected == 'default') {
				this.$refs.findbarcode.focus();
				if (this.boxlists.length > 0) {
					this.showarrangebox = true;
					this.showfindlist = false;
					this.showstockbox = false;
					this.showexportbox = false;
				} else {
					this.showarrangebox = false;
					this.showfindlist = false;
					this.showstockbox = false;
					this.showexportbox = false;
				}
			}
			if (this.selected == 'reg'){
				setTimeout(() => {
					this.$refs.barcoderegstock.focus();
				}, 10);
				this.showstockbox = true;
				this.showfindlist = false;
				this.showexportbox = false;
				setTimeout(() => {
					this.boxchange();
					this.showarrangebox = false;
					this.showfindlist = false;
				}, 1);
			}
			if (this.selected == 'export'){
				setTimeout(() => {
					this.$refs.barcoderegexport.focus();
				}, 10);
				this.showexportbox = true;
				this.showfindlist = false;
				this.showstockbox = false;
				setTimeout(() => {
					this.exportboxchange();
					this.showarrangebox = false;
					this.showfindlist = false;
					this.showstockbox = false;
				}, 1);
			}
		},
		objequal(object1, object2) {
			var keys1 = ['바코드','재고박스번호','재고파렛트번호','배치발주번호','배치박스번호','배치파렛트번호','stockinfo']
			var result = true;
			for (let key of keys1) {
				if (object1[key] !== object2[key]) {
				result = false;
				}
			}
			return result;
		},
		findbarcodeinfo(event){
			this.selected = 'default';
			if(event.keyCode === 13){
				// console.log(event.target.value);
				this.showlist = false;
				var result = this.openfindlist(event.target.value);
				console.log(result)
				if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length ){
					this.findlists = result.findlists.filter(a => a.발주세부내역.필요수량 > 0);
					this.showfindlist = true;
					this.showstockbox = false;
				} else {
					// if(!result.res){
						alert("해당 바코드의 추가 발주내역이 없습니다.")
						this.findlists = [];
						this.showfindlist = false;
						this.showstockbox = false;
						this.selected = 'reg'
						this.showbox()
					// }
				}
			}
		},
		findskuinfo(event){
			//작성필요
			this.selected = 'default';
			if(event.keyCode === 13){
				// console.log(event.target.value)
				this.showlist = false;
				var barcode = this.productdb.find(e => e["SKU ID"] == event.target.value).바코드;
				var result = this.openfindlist(barcode);
				if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length ){
					this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
					this.showfindlist = true;
					this.showstockbox = false;
				} else {
					// if(!result.res){
						alert("해당 SKU의 추가 발주내역이 없습니다.")
						this.findlists = [];
						this.showfindlist = false;
						this.showstockbox = false;
						this.selected = 'reg'
						this.showbox()
					// }
				}
			}
		},
		arrangeboxchange(event){
			this.selected = 'default';
			if(event.keyCode === 13){
				// console.log(event.target.value);
				this.showlist = false;
				var result = this.openfindlist(event.target.value);
				if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length ){
					this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
					this.showfindlist = true;
					this.showstockbox = false;
				} else {
					// if(!result.res){
						alert("해당 바코드의 추가 발주내역이 없습니다.")
						this.findlists = [];
						this.showfindlist = false;
						this.showstockbox = false;
						this.selected = 'reg'
						this.showbox()
					// }
				}
			}
		},
		arrangelocation(event){
			this.selected = 'default';
			if(event.keyCode === 13){
				// console.log(event.target.value);
				this.showlist = false;
				var result = this.openfindlist(event.target.value);
				if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length ){
					this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
					this.showfindlist = true;
					this.showstockbox = false;
				} else {
					// if(!result.res){
						alert("해당 바코드의 추가 발주내역이 없습니다.")
						this.findlists = [];
						this.showfindlist = false;
						this.showstockbox = false;
						this.selected = 'reg'
						this.showbox()
					// }
				}
			}
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		maxvalue(data,key){
			return data.reduce((prev, current) => (prev[key] > current[key]) ? prev : current)[key]
		},
		changestockinfo(stocklist,stockinfo){
			if(stockinfo == "stock") {
				if(stocklist.배치필요내역[0].배치필요발주번호 == ''){
					return "재고"
				} else {
					return "배치필요"
				}
			}
			if(stockinfo == "Astock") {
			return "배치재고"
			}
			if(stockinfo == "Pstock") {
			return "구매재고"
			}
			if(stockinfo == "Rstock") {
			return "회송재고"
			}
		},
		changeboxinfo(stockinfo,재고박스번호,배치박스번호){
			if(stockinfo == "stock") {
			return 재고박스번호
			}
			if(stockinfo == "Astock") {
			return 배치박스번호
			}
			if(stockinfo == "Pstock") {
			return "구매입고전"
			}
			if(stockinfo == "Rstock") {
			return "회송입고전"
			}
		},
		changepalletinfo(stockinfo,재고파렛트번호,배치파렛트번호) {
			if(stockinfo == "stock") {
			return 재고파렛트번호
			}
			if(stockinfo == "Astock") {
			return 배치파렛트번호
			}
			if(stockinfo == "Pstock") {
			return "구매입고전"
			}
			if(stockinfo == "Rstock") {
			return "회송입고전"
			}
		},
		badgecolor(stocklist,stockinfo){
			if(stockinfo == "stock") {
				if(stocklist.배치필요내역[0].배치필요발주번호 == ''){
					return "badge badge-primary"
				} else {
					return "badge badge-secondary"
				}
			}
			if(stockinfo == "Astock") {
			return "badge badge-success"
			}
			if(stockinfo == "Pstock") {
			return "badge badge-warning"
			}
			if(stockinfo == "Rstock") {
			return "badge badge-danger"
			}
		},
		openfindlist(barcode){
			var arrangecnt = 0;
			var notarrangecnt = 0;
			var pstockcnt = 0;
			var rstockcnt = 0;
			const bar = barcode;
			if(this.productdb.find(e => e.바코드 == bar) === undefined){
				alert("존재하지 않는 바코드 입니다")
			} else {
				// if(this.selected == 'default') { //발주서선택 등록
					//바코드 찾는 함수
					var result = [];
					var temp = {};
					this.$store.state.polist.forEach((e) => {
						if(!e.발주상태.includes("거래명세서") && !e.발주상태.includes("발주취소")) {
							e.발주세부내역.some((ele) => {
								if(this.$store.state.exportlist.length > 0){
									if(this.$store.state.exportlist.filter(element => element.발주번호 == e.발주번호 && element.바코드 == ele.바코드).length > 0){
										return
									}
								}

									if(ele.바코드 == bar){
										arrangecnt = 0;
										notarrangecnt = 0;
										pstockcnt = 0;
										rstockcnt = 0;
										if (this.$store.state.stock.length != 0){
											this.$store.state.stock.forEach(element => {
												if(element.바코드 == ele.바코드){
													if(element.배치발주번호 == e.발주번호){
														arrangecnt++
													}
												}
											})
										}
										ele.발주수량 = parseInt(ele.발주수량);
										ele.입고수량 = parseInt(ele.입고수량);
										if(isNaN(ele.입고수량)){
											ele.입고수량 = 0;
										}
										ele.확정수량 = parseInt(ele.확정수량);
										ele.배치수량 = arrangecnt;
										ele.미배치재고수량 = notarrangecnt;
										ele.구매완료수량 = pstockcnt;
										ele.회송예정수량 = rstockcnt;
										ele.사입재고수량 = 0;
										if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
											this.$store.state.productdb.some(element => {
												if(ele.바코드 == element.바코드 && element.구매링크 == '사입'){
													ele.사입재고수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt;
													return (ele.바코드 == element.바코드 && element.구매링크 == '사입')
												}
											})
										}
										ele.구매필요수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt - ele.사입재고수량;
										ele.필요수량 = ele.확정수량 - ele.배치수량


										// if(ele.필요수량 > 0){
											temp = this.deepClone(e);
											temp.발주세부내역 = ele;
											result.push(temp);
											temp = {};
											return (ele.바코드 == bar && ele.필요수량 > 0)
										// }
									}
							});
						}
					})
					//발주센터로 먼저 정렬
					result.sort(function(a,b){
						if (a.발주센터 < b.발주센터){
							return -1;
						}
						if (a.발주센터 > b.발주센터){
							return 1;
						}
						return 0;
					})
					//입고예정일순으로 최종 정렬
					result.sort(function(a,b){
						return new Date(a.입고예정일) - new Date(b.입고예정일);
					})
					// console.log(result)
					// console.log(this.$store.state.stock.length)
					if(this.importstock.length > 0){
						this.신규박스번호 = this.importstock.filter(e => e.stockinfo == "Astock").reduce((acc, shot) => acc = acc > shot.배치박스번호 ? acc : shot.배치박스번호, 0) + 1;
					} else {
						this.신규박스번호 = 1;
					}
					result.forEach(a => {
						a.추가배치수량 = 0;
						if(this.importstock.filter(e => e.배치발주센터 == a.발주센터 && e.배치입고예정일 == a.입고예정일).length == 0){
							if(this.importstock.length == 0){
								a.배치박스번호 = 1;
								a.newbox = true;
								a.배치파렛트번호 = '';
							} else {
								if(this.importstock.filter(e => e.stockinfo == "Astock").length == 0){
									a.배치박스번호 = 1;
									a.newbox = true;
									a.배치파렛트번호 = '';
								} else {
									var num = this.importstock.filter(e => e.stockinfo == "Astock").reduce((acc, shot) => acc = acc > shot.배치박스번호 ? acc : shot.배치박스번호, 0) + 1;
									a.배치박스번호 = parseInt(num);
									a.newbox = true;
									a.배치파렛트번호 = '';
								}
							}
						} else {
							num = this.importstock.filter(e => e.배치발주센터 == a.발주센터 && e.배치입고예정일 == a.입고예정일).reduce((acc, shot) => acc = acc > shot.배치박스번호 ? acc : shot.배치박스번호, 0);
							a.배치박스번호 = parseInt(num);
							a.newbox = false;
							a.배치파렛트번호 = this.importstock.find(e => e.배치박스번호 == num).배치파렛트번호;
						}

					})



					if(result.length == 0){
						return {res: false}
					} else {
						// console.log(result)
						return {res: true, findlists : result}
					}

				// } else {
				// 	return {res: false}
				// }
			}

		},
		now(){
			var today = new Date();
			today.setHours(today.getHours() + 9);
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
		savearrange(){
			if(this.findlists.length > 0){
				if(this.clicksavearrange){
					return
				}
				this.clicksavearrange = true;
				var obj = {}
				var now = this.now()
				var arr = [];
				var j
				this.findlists.forEach((e,i) => {
					if(parseInt(e.추가배치수량) > 0){
						for(j=0;j<parseInt(e.추가배치수량);j++){
							obj = {
								companyid:this.업체명,
								바코드:this.findlists[i].발주세부내역.바코드,
								상품명:this.findlists[i].발주세부내역.상품명,
								SKUID:this.findlists[i].발주세부내역.SKUID,
								구매id:'',
								회송id:'',
								입고일시: now,
								입고송장번호: '',
								재고박스번호: 0,
								재고파렛트번호: '',
								배치일시: now,
								배치발주번호: this.findlists[i].발주번호,
								배치박스번호: parseInt(this.findlists[i].배치박스번호),
								배치파렛트번호: this.findlists[i].배치파렛트번호,
								배치발주센터: this.findlists[i].발주센터,
								배치입고예정일: this.findlists[i].입고예정일,
								stockinfo: 'Astock',
								선적출고일시:'',
								site:'cn'

							}
							arr.push(obj);
						}
					}
				})
				axios.post('/api/addstock',arr)
				.then((res) => {
					if(!res.data){
						alert("등록실패");
						console.log(res)
					} else {
						this.$store.commit('stockupdate',res.data.stock);
						this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
						this.showfindlist = false;
						alert(res.data.요청결과);
						var result = this.openfindlist(this.barcodevalue);
						setTimeout(() => {
							if(result.res){
								this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
								this.showfindlist = true;
								this.showstockbox = false;
							} else {
								if(!result.res){
									alert("해당 바코드의 추가 발주내역이 없습니다.")
									this.findlists = [];
									this.showfindlist = false;
									this.showstockbox = false;
								}
							}
							this.clicksavearrange = false;
						}, 5);
					}
				})
				.catch((e) => {alert("등록실패");console.log(e);})
			} else {
				alert("배치 저장할 수량이 없습니다.");
				this.clicksavearrange = false;
			}
		},
		openstocklist(){
			if(this.importstock.length == 0){
				this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
			}
			// console.log(this.$store.state.stock)
			console.log(this.importstock)
			if (this.importstock.filter(e => e.stockinfo == 'stock')?.length == 0) {
				this.stockboxnum = 1;
				this.stockpalletnum = '';
				this.stockboxlists = [];
				this.stockboxnumbers = [];
			} else {
				var max = this.importstock.filter(e => e.stockinfo == 'stock').reduce((acc, shot) => acc = acc > this.timestamp(shot.입고일시) ? acc : this.timestamp(shot.입고일시), 0);
				this.stockboxnum = this.importstock.filter(e => e.stockinfo == 'stock').find(e => this.timestamp(e.입고일시) == max).재고박스번호;
				this.stockpalletnum = this.importstock.filter(e => e.stockinfo == 'stock').find(e => e.재고박스번호 == this.stockboxnum).재고파렛트번호;
				this.stockboxnumbers = this.importstock.filter(e => e.stockinfo == 'stock').map(item => item.재고박스번호).filter((v,i,a) => a.indexOf(v) === i);
				this.stockboxnumbers.sort(function(a,b){
					if (parseInt(a) < parseInt(b)){
						return -1;
					}
					if (parseInt(a) > parseInt(b)){
						return 1;
					}
					return 0;
				})
			}
			if (this.importstock.filter(e => e.stockinfo == 'Astock')?.length == 0) {
				this.exportboxnum = 1;
				this.exportpalletnumber = '';
				this.exportboxlists = [];
				this.exportboxnumbers = [1];
			} else {
				var exportmax = this.importstock.filter(e => e.stockinfo == 'Astock').reduce((acc, shot) => acc = acc > this.timestamp(shot.입고일시) ? acc : this.timestamp(shot.입고일시), 0);
				this.exportboxnum = this.importstock.filter(e => e.stockinfo == 'Astock').find(e => this.timestamp(e.입고일시) == exportmax).배치박스번호;
				this.exportpalletnumber = this.importstock.filter(e => e.stockinfo == 'Astock').find(e => e.배치박스번호 == this.exportboxnum).배치파렛트번호;
				this.exportboxnumbers = this.importstock.filter(e => e.stockinfo == 'Astock').map(item => item.배치박스번호).filter((v,i,a) => a.indexOf(v) === i);
				this.exportboxnumbers.sort(function(a,b){
					if (parseInt(a) < parseInt(b)){
						return -1;
					}
					if (parseInt(a) > parseInt(b)){
						return 1;
					}
					return 0;
				})
			}
			console.log(this.exportboxnum, this.exportboxnumbers)
		},
		boxnumberchange(number){
			this.stockboxnum = number;
			this.boxchange();
		},
		exportboxnumberchange(number){
			this.exportboxnum = number;
			this.exportboxchange();
		},
		stocklocation(event){
			if(event.keyCode === 13){
				// console.log(event.target.value);
				this.savestock();
			}
		},
		exportlocation(event){
			if(event.keyCode === 13){
				// console.log(event.target.value);
				this.saveexport();
			}
		},
		boxchangeref(){
			if(this.stockboxnum == '' || this.stockboxnum < 1){
				var max = this.importstock.reduce((acc, shot) => acc = acc > this.timestamp(shot.입고일시) ? acc : this.timestamp(shot.입고일시), 0);
				this.stockboxnum = this.importstock.find(e => this.timestamp(e.입고일시) == max).재고박스번호;
				this.boxchange()
			} else {
				this.boxchange()
			}
		},
		exportboxchangeref(){
			if(this.exportboxnum == '' || this.exportboxnum < 1){
				var max = this.importstock.reduce((acc, shot) => acc = acc > this.timestamp(shot.입고일시) ? acc : this.timestamp(shot.입고일시), 0);
				this.exportboxnum = this.importstock.find(e => this.timestamp(e.입고일시) == max).배치박스번호;
				this.exportboxchange()
			} else {
				this.exportboxchange()
			}
		},
		exportboxchange(){
			this.exportstyleon = false;
			this.exportchange = false;
			if (this.importstock.length == 0) {
				this.showexportbox = false;
				this.exportboxnum = 1;
				this.exportpalletnumber = '';
				this.exportboxlists = [];
				this.showexportbox = true;
			} else {
				if(this.importstock.filter(e => e.stockinfo == 'Astock').length == 0){
					this.showexportbox = false;
					this.exportboxnum = 1;
					this.exportpalletnumber = '';
					this.exportboxlists = [];
					this.showexportbox = true;
				} else {
					this.showfindlist = false;
					this.showstockbox = false;
					this.showexportbox = false;
					var arr = [];
					var obj = {};
					var cnt = 0;
					this.exportboxlists = [];
					this.exportarr = [];
					if(this.importstock.filter(e => e.stockinfo == 'Astock' && e.배치박스번호 == this.exportboxnum).length > 0){
						arr = this.importstock.filter(e => e.stockinfo == 'Astock' && e.배치박스번호 == this.exportboxnum);
						this.exportarr = arr;
						arr.forEach(e => {
							cnt = 0;
							this.exportboxlists.some(ele => {
								if(ele.바코드 == e.바코드){
									ele.수량++
									cnt = 1;
									return (ele.바코드 == e.바코드)
								}
							})
							if (cnt == 0){
								obj = {
									바코드 : e.바코드,
									SKUID : e.SKUID,
									상품명 : e.상품명,
									IMGURL : e.등록이미지 ? e.등록이미지 : this.productdb.find(ele => ele.바코드 == e.바코드)?.등록이미지,
									수량 : 1
								}
								this.exportboxlists.push(obj);
							}
						})

						this.totalqty = this.exportboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
						console.log(this.exportboxnum);
						// console.log(this.stockarr)
						console.log(arr);
						console.log(this.exportboxlists);
						console.log(this.totalqty)
						this.exportpalletnumber = this.importstock.find(e => e.배치박스번호 == this.exportboxnum).배치파렛트번호;
					}
					this.showexportbox = true;
				}
			}
		},
		boxchange(){
			this.stockstyleon = false;
			this.stockchange = false;
			if (this.importstock.length == 0) {
				this.showstockbox = false;
				this.stockboxnum = 1;
				this.stockpalletnum = '';
				this.stockboxlists = [];
				this.showstockbox = true;
			} else {
				if(this.importstock.filter(e => e.stockinfo == 'stock').length == 0){
					this.showstockbox = false;
					this.stockboxnum = 1;
					this.stockpalletnum = '';
					this.stockboxlists = [];
					this.showstockbox = true;
				} else {
					this.showfindlist = false;
					this.showstockbox = false;
					this.showexportbox = false;
					var arr = [];
					var obj = {};
					var cnt = 0;
					this.stockboxlists = [];
					this.stockarr = [];
					if(this.importstock.filter(e => e.stockinfo == 'stock' && e.재고박스번호 == this.stockboxnum).length > 0){
						arr = this.importstock.filter(e => e.stockinfo == 'stock' && e.재고박스번호 == this.stockboxnum);
						this.stockarr = arr;
						arr.forEach(e => {
							cnt = 0;
							this.stockboxlists.some(ele => {
								if(ele.바코드 == e.바코드){
									ele.수량++
									cnt = 1;
									return (ele.바코드 == e.바코드)
								}
							})
							if (cnt == 0){
								obj = {
									바코드 : e.바코드,
									SKUID : e.SKUID,
									상품명 : e.상품명,
									IMGURL : this.productdb.find(ele => ele.바코드 == e.바코드).등록이미지,
									수량 : 1
								}
								this.stockboxlists.push(obj);
							}
						})

						this.totalqty = this.stockboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
						console.log(this.stockboxnum);
						// console.log(this.stockarr)
						console.log(arr);
						console.log(this.stockboxlists);
						console.log(this.totalqty)
						this.stockpalletnum = this.importstock.find(e => e.재고박스번호 == this.stockboxnum).재고파렛트번호;
					}
					this.showstockbox = true;
				}
			}
		},
		deletearr(arr,i){
			arr.splice(i,1)
			return arr
		},
		addstock(barcode){
			this.stockchange = true;
			if(this.productdb.find(e => e.바코드 == barcode)){
				// console.log(barcode)
				var arr = [];
				var obj = {};
				this.stockboxlists.forEach(e => {
					arr.push(e.바코드);
				})
				var deltarget = 0;
				if(arr.includes(barcode)){
					this.stockboxlists.some((e,i) => {
						if(e.바코드 == barcode){
							e.수량++
							obj = this.deepClone(e);
							deltarget = i
							return (e.바코드 == barcode)
						}
					})
					this.stockboxlists = this.deletearr(this.stockboxlists,deltarget);
					this.stockboxlists.unshift(obj);
					this.stockstyleon = true;

				} else {
					obj = {
						바코드 : barcode,
						SKUID : this.productdb.find(e => e.바코드 == barcode)["SKU ID"],
						상품명 : this.productdb.find(e => e.바코드 == barcode).상품명,
						IMGURL : this.productdb.find(e => e.바코드 == barcode).등록이미지,
						수량 : 1
					}
					// console.log(obj)
					this.stockboxlists.unshift(obj);
					this.stockstyleon = true;
				}
				this.totalqty = this.stockboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
				// console.log(this.stockboxlists)
			} else {
				alert('상품 데이터베이스에 없는 바코드 번호 입니다!')
			}
		},
		addexport(barcode){
			this.exportchange = true;
			if(this.productdb.find(e => e.바코드 == barcode)){
				var 상품명 = this.productdb.find(e => e.바코드 == barcode).상품명;
				// console.log(barcode)
				var arr = [];
				var obj = {};
				this.exportboxlists.forEach(e => {
					arr.push(e.상품명);
				})
				var deltarget = 0;
				if(arr.includes(상품명)){
					this.exportboxlists.some((e,i) => {
						if(e.상품명 == 상품명){
							e.수량++
							obj = this.deepClone(e);
							deltarget = i
							return (e.상품명 == 상품명)
						}
					})
					this.exportboxlists = this.deletearr(this.exportboxlists,deltarget);
					this.exportboxlists.unshift(obj);
					this.exportstyleon = true;

				} else {
					obj = {
						바코드 : barcode,
						SKUID : this.productdb.find(e => e.바코드 == barcode)["SKU ID"],
						상품명 : this.productdb.find(e => e.바코드 == barcode).상품명,
						IMGURL : this.productdb.find(e => e.바코드 == barcode).등록이미지,
						수량 : 1
					}
					// console.log(obj)
					this.exportboxlists.unshift(obj);
					this.exportstyleon = true;
				}
				this.totalqty = this.exportboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
				// console.log(this.stockboxlists)
			} else {
				alert('상품 데이터베이스에 없는 바코드 번호 입니다!')
			}
		},
		deletelist(stockboxlist){
			this.stockchange = true;
			var arr = [];
			var proceed = confirm("'" + stockboxlist.상품명 + "'" + '\n' + "위 상품을 삭제하시겠습니까?");
			if(proceed){
				this.stockboxlists.forEach(e => {
					if(e.바코드 != stockboxlist.바코드){
						arr.push(e)
					}
				})
				this.stockboxlists = arr;
			}
			this.totalqty = this.stockboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
		},
		deleteexportlist(exportboxlist){
			this.exportchange = true;
			var arr = [];
			var proceed = confirm("'" + exportboxlist.상품명 + "'" + '\n' + "위 상품을 삭제하시겠습니까?");
			if(proceed){
				this.exportboxlists.forEach(e => {
					if(e.상품명 != exportboxlist.상품명){
						arr.push(e)
					}
				})
				this.exportboxlists = arr;
			}
			this.totalqty = this.exportboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
		},
		deleteitem(){
			this.delitems = this.stockdetail.filter(e => e.checked);
			this.stockdetail = this.stockdetail.filter(e => !e.checked);
			this.showchecklist = false;
			console.log(this.delitems)
		},
		locationchange(){
			this.stockchange = true;
			this.savestock();
			this.stockchange = false;
		},
		exportlocationchange(){
			this.exportchange = true;
			this.saveexport();
			this.exportchange = false;
		},
		async saveexport(){
			if(this.exportchange){
				this.showlist = false;
				this.showstockbox = false;
				console.log(this.exportboxlists)
				console.log(this.exportarr)
				if(this.exportboxlists.length > 0 && this.exportboxlists.reduce((pv,cv) => {return pv + cv.수량},0) > 0){

					var templists = this.exportboxlists;
					var arr = [];
					var obj = {}
					var now = this.now()
					var cnt = 0;
					var j = 0;
					templists.forEach(e => {
						if(this.exportarr.filter(ele => ele.상품명 == e.상품명).length > 0){
							if(e.수량 > this.exportarr.filter(ele => ele.상품명 == e.상품명).length){
								this.exportarr.filter(ele => ele.상품명 == e.상품명).forEach(element => {
									element.배치박스번호 = parseInt(this.exportboxnum);
									element.배치파렛트번호 = this.exportpalletnumber;
									arr.push(element)
								})
								cnt = e.수량 - this.exportarr.filter(ele => ele.상품명 == e.상품명).length;
								for(j=0;j<cnt;j++){
									obj = {
										companyid:this.업체명,
										바코드:e.바코드,
										상품명:e.상품명,
										SKUID:e.SKUID,
										구매id:'',
										회송id:'',
										입고일시: now,
										입고송장번호: '',
										재고박스번호: '',
										재고파렛트번호: '',
										배치일시: now,
										배치발주번호: '',
										배치박스번호: parseInt(this.exportboxnum),
										배치파렛트번호: this.exportpalletnumber,
										배치발주센터: '',
										배치입고예정일: '',
										stockinfo: 'Astock',
										선적출고일시:'',
										site:'cn'
									}
									arr.push(obj);
								}
							} else {
								if(e.수량 <= this.exportarr.filter(ele => ele.상품명 == e.상품명).length){
									for(j=0;j<e.수량;j++){
										this.exportarr.filter(ele => ele.상품명 == e.상품명)[j].배치박스번호 = parseInt(this.exportboxnum);
										this.exportarr.filter(ele => ele.상품명 == e.상품명)[j].배치파렛트번호 = this.exportpalletnumber;
										arr.push(this.exportarr.filter(ele => ele.상품명 == e.상품명)[j])
									}
								}
							}
						} else {
							//exportarr에 존재하지 않는 상품명일 경우
							if(e.수량 > 0){
								for(j=0;j<e.수량;j++){
									obj = {
										companyid:this.업체명,
										바코드:e.바코드,
										상품명:e.상품명,
										SKUID:e.SKUID,
										구매id:'',
										회송id:'',
										입고일시: now,
										입고송장번호: '',
										재고박스번호: '',
										재고파렛트번호: '',
										배치일시: now,
										배치발주번호: '',
										배치박스번호: parseInt(this.exportboxnum),
										배치파렛트번호: this.exportpalletnumber,
										배치발주센터: '',
										배치입고예정일: '',
										stockinfo: 'Astock',
										선적출고일시:'',
										site:'cn'
									}
									// console.log(this.stockboxnum)
									// console.log(parseInt(this.stockboxnum))
									arr.push(obj);
								}
							}
						}
					})

					arr.forEach(e => {
						if(e._id){
							delete e._id
						}
					})
					// console.log(arr.filter(e => e.바코드 == 'R010584990006'))
					await axios.post('/api/addstock/updateAbox',{stock:arr,배치박스번호:parseInt(this.exportboxnum),site:'cn'})
					.then((res) => {
						alert(res.data.요청결과);
						// console.log(res.data.stock.filter(e => e.site == 'cn' && e.재고박스번호 == 1 && e.바코드 == 'R010584990006'))
						this.$store.commit('stockupdate',res.data.stock)
						this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
						this.stockboxnumbers = this.importstock.map(item => item.재고박스번호).filter((v,i,a) => a.indexOf(v) === i);
						this.stockboxnumbers.sort(function(a,b){
							if (parseInt(a) < parseInt(b)){
								return -1;
							}
							if (parseInt(a) > parseInt(b)){
								return 1;
							}
							return 0;
						})
						this.exportboxnumbers = this.importstock.map(item => item.배치박스번호).filter((v,i,a) => a.indexOf(v) === i);
						this.exportboxnumbers.sort(function(a,b){
							if (parseInt(a) < parseInt(b)){
								return -1;
							}
							if (parseInt(a) > parseInt(b)){
								return 1;
							}
							return 0;
						})
						if(!res.data){
							alert("등록실패(2110)");
							console.log(res)
						}
						this.exportboxchange()
					})
					.catch((e) => {alert("등록실패(2115)");console.log(e)})
				} else {
					var proceed = confirm("저장할 수량이 없습니다. 박스번호를 삭제하시겠습니까?");
					if(proceed){
						arr = [];
						axios.post('/api/addstock/updatebox',{stock:arr,재고박스번호:parseInt(this.stockboxnum),companyid:this.업체명,site:'cn'})
						.then((res) => {
							alert(res.data.요청결과);
							this.$store.commit('stockupdate',res.data.stock)
							this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
							this.stockboxnumbers = this.importstock.map(item => item.재고박스번호).filter((v,i,a) => a.indexOf(v) === i);
							this.stockboxnumbers.sort(function(a,b){
								if (parseInt(a) < parseInt(b)){
									return -1;
								}
								if (parseInt(a) > parseInt(b)){
									return 1;
								}
								return 0;
							})
							this.boxchange()
							if(!res.data){
								alert("등록실패");
								console.log(res)
							}
						})
						.catch((e) => {alert("등록실패");console.log(e)})
					}
				}
			}

		},
		async savestock(){
			if(this.stockchange){
				this.showlist = false;
				console.log(this.stockboxlists)
				console.log(this.stockarr)
				if(this.stockboxlists.length > 0 && this.stockboxlists.reduce((pv,cv) => {return pv + cv.수량},0) > 0){

					var templists = this.stockboxlists;
					var arr = [];
					var obj = {}
					var now = this.now()
					var cnt = 0;
					var j = 0;
					templists.forEach(e => {
						if(this.stockarr.filter(ele => ele.바코드 == e.바코드).length > 0){
							if(e.수량 > this.stockarr.filter(ele => ele.바코드 == e.바코드).length){
								this.stockarr.filter(ele => ele.바코드 == e.바코드).forEach(element => {
									element.재고박스번호 = parseInt(this.stockboxnum);
									element.재고파렛트번호 = this.stockpalletnum;
									arr.push(element)
								})
								cnt = e.수량 - this.stockarr.filter(ele => ele.바코드 == e.바코드).length;
								for(j=0;j<cnt;j++){
									obj = {
										companyid:this.업체명,
										바코드:e.바코드,
										상품명:e.상품명,
										SKUID:e.SKUID,
										구매id:'',
										회송id:'',
										입고일시: now,
										입고송장번호: '',
										재고박스번호: parseInt(this.stockboxnum),
										재고파렛트번호: this.stockpalletnum,
										배치일시: '',
										배치발주번호: '',
										배치박스번호: 0,
										배치파렛트번호: '',
										배치발주센터: '',
										배치입고예정일: '',
										stockinfo: 'stock',
										선적출고일시:'',
										site:'cn'
									}
									arr.push(obj);
								}
							} else {
								if(e.수량 <= this.stockarr.filter(ele => ele.바코드 == e.바코드).length){
									for(j=0;j<e.수량;j++){
										this.stockarr.filter(ele => ele.바코드 == e.바코드)[j].재고박스번호 = parseInt(this.stockboxnum);
										this.stockarr.filter(ele => ele.바코드 == e.바코드)[j].재고파렛트번호 = this.stockpalletnum;
										arr.push(this.stockarr.filter(ele => ele.바코드 == e.바코드)[j])
									}
								}
							}
						} else {
							//stockarr에 존재하지 않는 바코드일 경우
							if(e.수량 > 0){
								for(j=0;j<e.수량;j++){
									obj = {
										companyid:this.업체명,
										바코드:e.바코드,
										상품명:e.상품명,
										SKUID:e.SKUID,
										구매id:'',
										회송id:'',
										입고일시: now,
										입고송장번호: '',
										재고박스번호: parseInt(this.stockboxnum),
										재고파렛트번호: this.stockpalletnum,
										배치일시: '',
										배치발주번호: '',
										배치박스번호: 0,
										배치파렛트번호: '',
										배치발주센터: '',
										배치입고예정일: '',
										stockinfo: 'stock',
										선적출고일시:'',
										site:'cn'
									}
									// console.log(this.stockboxnum)
									// console.log(parseInt(this.stockboxnum))
									arr.push(obj);
								}
							}
						}
					})

					arr.forEach(e => {
						if(e._id){
							delete e._id
						}
					})
					// console.log(arr.filter(e => e.바코드 == 'R010584990006'))
					await axios.post('/api/addstock/updatebox',{stock:arr,재고박스번호:parseInt(this.stockboxnum),site:"cn"})
					.then((res) => {
						alert(res.data.요청결과);
						// console.log(res.data.stock.filter(e => e.site == 'cn' && e.재고박스번호 == 1 && e.바코드 == 'R010584990006'))
						this.$store.commit('stockupdate',res.data.stock)
						this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
						this.stockboxnumbers = this.importstock.map(item => item.재고박스번호).filter((v,i,a) => a.indexOf(v) === i);
						this.stockboxnumbers.sort(function(a,b){
							if (parseInt(a) < parseInt(b)){
								return -1;
							}
							if (parseInt(a) > parseInt(b)){
								return 1;
							}
							return 0;
						})
						if(!res.data){
							alert("등록실패(2110)");
							console.log(res)
						}
						this.boxchange()
					})
					.catch((e) => {alert("등록실패(2115)");console.log(e)})
				} else {
					var proceed = confirm("저장할 수량이 없습니다. 박스번호를 삭제하시겠습니까?");
					if(proceed){
						arr = [];
						axios.post('/api/addstock/updatebox',{stock:arr,재고박스번호:parseInt(this.stockboxnum),companyid:this.업체명,site:'cn'})
						.then((res) => {
							alert(res.data.요청결과);
							this.$store.commit('stockupdate',res.data.stock)
							this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
							this.stockboxnumbers = this.importstock.map(item => item.재고박스번호).filter((v,i,a) => a.indexOf(v) === i);
							this.stockboxnumbers.sort(function(a,b){
								if (parseInt(a) < parseInt(b)){
									return -1;
								}
								if (parseInt(a) > parseInt(b)){
									return 1;
								}
								return 0;
							})
							this.boxchange()
							if(!res.data){
								alert("등록실패");
								console.log(res)
							}
						})
						.catch((e) => {alert("등록실패");console.log(e)})
					}
				}
			}

		},
		async barcoderegstock(event){ //재고등록
			if(event.keyCode === 13){
				// console.log(event.target.value);
				if(this.checkpo){
					this.showlist = false;
					var result = this.openfindlist(event.target.value);
					console.log(result)
					if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length){
						alert('발주내역이 확인되어 저장 후 발주배치로 이동됩니다.')
						await this.savestock();
						this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
						this.showfindlist = true;
						this.showstockbox = false;
						this.selected = 'default';
					} else {
						this.addstock(event.target.value);
						this.regstock = '';
					}
				} else {
					this.addstock(event.target.value);
					this.regstock = '';
				}
			}
		},
		async barcoderegexport(event){ //재고등록
			if(event.keyCode === 13){
				this.addexport(event.target.value);
				this.regexport = '';
			}
		},
		async selectreqpurchase(purchase){
			this.exportchange = true;
			var 상품명 = purchase.상품명;
			var arr = [];
			var obj = {};
			this.exportboxlists.forEach(e => {
				arr.push(e.상품명);
			})
			var deltarget = 0;
			if(arr.includes(상품명)){
				this.exportboxlists.some((e,i) => {
					if(e.상품명 == 상품명){
						e.수량 += parseInt(purchase.구매요청수량)
						obj = this.deepClone(e);
						deltarget = i
						return (e.상품명 == 상품명)
					}
				})
				this.exportboxlists = this.deletearr(this.exportboxlists,deltarget);
				this.exportboxlists.unshift(obj);
				this.exportstyleon = true;

			} else {
				obj = {
					바코드 : purchase.바코드.trim(),
					SKUID : purchase.SKUID,
					상품명 : purchase.상품명,
					IMGURL : purchase.등록이미지,
					수량 : parseInt(purchase.구매요청수량),
				}
				// console.log(obj)
				this.exportboxlists.unshift(obj);
				this.exportstyleon = true;
			}
			this.totalqty = this.exportboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
		},
		excelDownFunc(){
            // var arr = JSON.parse(JSON.stringify(this.importstock))
            // console.log(arr)
            // const myHeader = ["바코드","상품명","SKUID","구매id","회송id","입고일시","입고송장번호","재고박스번호","재고파렛트번호","배치일시","배치발주번호","배치박스번호","배치파렛트번호","배치발주센터","배치입고예정일","stockinfo"];
            // var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            // const range = XLSX.utils.decode_range(dataWS['!ref']);
            // range.e['c'] = myHeader.length - 1;
            // dataWS['!ref'] = XLSX.utils.encode_range(range);

            // var wb = XLSX.utils.book_new();

            // XLSX.utils.book_append_sheet(wb, dataWS, 'productDB');
            // // 엑셀 파일을 내보낸다.
            // XLSX.writeFile(wb, '대량재고업로드양식.xlsx');

            var arr = [];
            const myHeader = ["바코드","수량","재고박스번호","박스위치"];
            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, '재고업로드');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '대량재고업로드양식.xlsx');


        },
		timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime() / 1000
        },
		getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
		download() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = []
			if(this.checkboxlist.filter(e => e == true).length == 0 || this.checkboxlist.filter(e => e == false).length == 0){
				arr = this.stocklists;
			} else {
				this.checkboxlist.forEach((e,i) => {
					if(e){
						arr.push(this.stocklists[i])
					}
				})
			}

			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '재고현황')
			XLSX.writeFile(workBook, '재고현황' + '_' + getToday() + '.xlsx')
		},
		readstockfile(event) {
			// console.log("read")
			var obj = {};
			var arr = [];
			var j
			var productname
			var sku
			var now = this.now()
            const file = event.target.files[0];
            var filename = file.name;
            let reader = new FileReader();
            this.uploadstatus = "업로드 중 ...... (업로드가 완료될 때까지 기다려 주세요)";
            reader.onload = (e) => {
                // let data = reader.result;
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                workbook.SheetNames.forEach(sheetName => {
                    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    // console.log(roa)
					roa.forEach(e => {
						this.$store.state.productdb.some(ele => {
							if(ele.바코드 == e.바코드){
								productname = ele.상품명;
								sku = ele["SKU ID"];
								return (ele.바코드 == e.바코드)
							}
						})
						for(j=0;j<e.수량;j++){
							obj = {
								companyid:this.업체명,
								바코드:e.바코드,
								상품명:productname,
								SKUID:sku,
								구매id:'',
								회송id:'',
								입고일시: now,
								입고송장번호: '',
								재고박스번호: parseInt(e.재고박스번호),
								재고파렛트번호: e.재고파렛트번호,
								배치일시: '',
								배치발주번호: '',
								배치박스번호: 0,
								배치파렛트번호: '',
								배치발주센터: '',
								배치입고예정일: '',
								stockinfo: 'stock',
								선적출고일시:'',
								site:'cn'
							}
							this.$store.commit('stockinsert',obj);
							this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
							arr.push(obj);
						}
					})
					axios.post('/api/addstock',arr)
					.then((res) => {
						alert(res.data.요청결과);
						this.$store.commit('stockupdate',res.data.stock)
						this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
						if(!res.data){
							alert("등록실패");
							console.log(res)
						}
					})
					.catch((e) => {alert("등록실패");console.log(e)})

                });
            };
            reader.readAsBinaryString(file);
            this.$refs.upload.value = '';
            this.uploadstatus = filename + " 등록완료 (" + arr.length + "개)";
        },
		astockupdate(){
			// ---------------------------this.boxlists를 astock state와 axios.post(stock)에 업데이트
			var arr = [];
			var payload = {};
			var now = this.now()
			var i;
			// console.log(this.boxlists);
			this.boxlists.forEach(element => {
				// console.log(element)
				var bar = element.바코드;
				var 등록이미지 = this.productdb.find(e => e.바코드 == element.바코드).등록이미지;
				var sku = this.productdb.find(e => e.바코드 == element.바코드)["SKU ID"];
				var 상품명 = this.productdb.find(e => e.바코드 == element.바코드).상품명;
				for(i=0;i<element.배치수량;i++){
					payload = {
						companyid : this.업체명,
						바코드 : bar,
						상품명 : 상품명,
						SKUID : sku,
						등록이미지 : 등록이미지,
						구매id : '',
						회송id : '',
						입고일시 : now,
						입고송장번호 : '',
						재고박스번호 : 0,
						재고파렛트번호 : '',
						배치일시 : now,
						배치발주번호 : element.발주번호,
						배치박스번호 : parseInt(element.박스번호),
						배치파렛트번호 : element.파렛트번호,
						배치발주센터 : element.발주센터,
						배치입고예정일 : element.입고예정일,
						stockinfo : 'Astock',
						선적출고일시:'',
						site:'cn'
					};
					arr.push(payload)
				}
			})
			// console.log(arr)
			axios.post('/api/addstock',arr)
			.then((res) => {
				// console.log(arr,res);
				if(!res.data){
					alert("등록실패");
					console.log(res)
				}
			})
			.catch((e) => {alert("등록실패");console.log(e)})
		},
		stockupdate(){
			//----------------------------this.stocklists를 stock state와 axios.post(stock)에 업데이트
			var arr = [];
			this.stockboxlists.forEach(element => {
				var bar = element.바코드;
				var sku = this.productdb.find(e => e.바코드 == element.바코드)["SKU ID"];
				var 상품명 = this.productdb.find(e => e.바코드 == element.바코드).상품명;
				var now = this.now()
				for(var i=0;i<element.수량;i++){
					var payload = {
						companyid : this.업체명,
						바코드 : bar,
						상품명 : 상품명,
						SKUID : sku,
						구매id : '',
						회송id : '',
						입고일시 : now,
						입고송장번호 : '',
						재고박스번호 : parseInt(element.박스번호),
						재고파렛트번호 : element.파렛트번호,
						배치일시 : '',
						배치발주번호 : '',
						배치박스번호 : 0,
						배치파렛트번호 : '',
						배치발주센터 : '',
						배치입고예정일 : '',
						stockinfo : 'stock',
						선적출고일시:'',
						site:'cn'
					};
					arr.push(payload)
				}
			})
			axios.post('/api/addstock',arr)
			.then((res) => {if(!res.data){alert("등록실패");console.log(res)}})
			.catch((e) => {alert("등록실패");console.log(e)})

		},
        handleCollapseShown() {
            if (this.openstatus) {
                this.openstatus = false
            }
        },
		opencollapse(purchase){
			this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
				this.openstatus = isJustShown;
			})
			this.수정출력내용 =
			'· 상품명 : ' + purchase.상품명 + '\n' +
			'· 수입원/판매원 : ' + purchase.한글표시사항['수입원/판매원'] + '\n' +
			'· 제조원 : ' + purchase.한글표시사항.제조원 + '\n' +
			'· 제조국 : ' + purchase.한글표시사항.제조국 + '\n' +
			'· 내용량 : ' + purchase.한글표시사항.내용량 + '\n' +
			'· 원료명및성분명(재질) : ' + purchase.한글표시사항['원료명및성분명(재질)'] + '\n' +
			'· 상품유형 : ' + purchase.한글표시사항.상품유형 + '\n' +
			'· 사용시주의사항 : ' + purchase.한글표시사항.사용시주의사항 + '\n' +
			'· 사용기준 : ' + purchase.한글표시사항.사용기준
		},
		editprint(id, data){
            if (data.바코드) {
                const middlePart = data.바코드.slice(1);
                const regex = /[a-zA-Z]/;
                if (data.바코드 > 15 || regex.test(middlePart)) {
                    if(!confirm('바코드 길이가 길어 바코드가 비정상 노출 될 수 있습니다.')){
                        return
                    }
                }
                this.editliststart = [];
                this.editlistend = [];
                this.수정출력내용.split('\n').forEach((e,i) => {
                    if(i < 4){
                        this.editliststart.push(e.trim());
                    } else {
                        this.editlistend.push(e.trim());
                    }
                })
                setTimeout(()=>{
                    this.print(id)
                },1000)
            }
		},
        editprint2(id) {
            this.editliststart = [];
            this.editlistend = [];
            this.barcodeRevisedContent.split('\n').forEach((e,i) => {
                if(i < 4){
                    this.editliststart.push(e.trim());
                } else {
                    this.editlistend.push(e.trim());
                }
            })
            setTimeout(()=>{
                this.print(id)
            },1000)
        },
        editprint3(id) {
            this.editliststart = [];
            this.editlistend = [];
            this.barcodeManualContent.split('\n').forEach((e,i) => {
                if(i < 4){
                    this.editliststart.push(e.trim());
                } else {
                    this.editlistend.push(e.trim());
                }
            })

            if (this.editliststart[0]) {
                this.barcode = this.editliststart[0].replace(/바코드명 : /, '').trim()
            }
            setTimeout(()=>{
                this.print(id)
            },1000)
        },
        submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
             this.submitStatus = true
             window.scrollTo({ top: 0, behavior: 'smooth' });
            }else{
            this.submitStatus = false
             this.$router.push({name: 'sale.listsale'})
            }
    }},
	computed : {
		...mapState({polists : 'polist', productdb : 'productdb'}),
		enablekr(){
			if(this.enabledetail){
				return 'font-size:80%;color:black;line-height:110%;display:block;margin-bottom:0px;'
			} else {
				return 'font-size:80%;color:black;line-height:110%;display:none'
			}
		},
		disablekr(){
			if(this.enabledetail){
				return 'font-size:80%;color:black;line-height:110%;display:none'
			} else {
				return 'font-size:80%;color:black;line-height:110%;display:block'
			}
		},
		totalqty1(){
			return this.exportboxlists?.reduce((pv,cv)=>{return pv + cv.수량},0) ? this.exportboxlists?.reduce((pv,cv)=>{return pv + cv.수량},0) : 0;
		},
        printsku() {
            if(this.printskuenable2){
                return 'font-size:90%;color:black;'
            } else {
                return 'display:none;font-size:90%;color:black;'
            }
        },
        printkc(){
            if(this.printkcenable2){
                return 'font-size:90%;color:black;margin-left:20px;margin-top:10px'
            } else {
                return 'display:none;font-size:90%;color:black;'
            }
        },
        printFood() {
            if(this.printfoodenable2){
                return 'font-size:90%;color:black;margin-left:20px;margin-top:10px'
            } else {
                return 'display:none;font-size:90%;color:black;'
            }
        }
	}
}
</script>
<style>
#quill-container{
    height: 50px;
}
#quill-container .ql-editor{
    min-height: unset;
}
.my-class .dropdown-menu {

max-height: 300px;
overflow-y: auto;
}
.modal-dialog {
max-width: 90% !important;
position: absolute;

margin: auto;
top: 0;
bottom: 0;
left: 0;
right: 0;
}
</style>
